import { customAPIConfig } from "../AppConfig";

export const GetRecordingException = async (accessToken, lastDays) => {
  const options = {
    method: "GET",
    headers: { Authorization: `bearer ${accessToken}` },
  };
  const resp = await fetch(
    `${customAPIConfig.customAPIBaseEndpoint}/RecordingException?days=${lastDays}`,
    options
  );
  const respData = await resp.json();
  return respData;
};

export const PostRecordingException = async (accessToken, formObj) => {
  try {
    const options = {
      method: "POST",
      body: formObj,
      headers: { Authorization: `bearer ${accessToken}` },
    };
    const resp = await fetch(
      `${customAPIConfig.customAPIBaseEndpoint}/RecordingException`,
      options
    );
    const respData = await resp.json();
    if (resp.ok) {
      return { status: "success", entry: respData["GUID"] };
    } else {
      return {
        status: "failed",
        error: resp.statusText,
        message: respData.message,
      };
    }
  } catch {
    return {
      status: "failed",
      error: "Internal Server Error",
      message: "If the problem persists, reach an administrator of Zoom Portal",
    };
  }
};
