import ApproveRejectPhoneRequestButton from "../PhoneRequestApproval/ApproveRejectPhoneRequest";
import LoadingScreen_V2 from "../../Components/LoadingScreen_V2";
import "./TableUnity.css";
import { useEffect, useState } from "react";
import {
  LeftAngle,
  PlaceholderArrow,
  RightAngle,
  DownAngle,
  TopAngle,
} from "../../Assets/SVGArrows";

const TableUnity = ({ 
  data, 
  webApiToken, 
  currentUser, 
  callBackApproveError,
  callBackRejectError,
  callbackApproveSuccess,
  callbackRejectSuccess,
  isDual,
}) => {

  let tableKeys = Object.keys(data[0]);

  const [filter, setFilter] = useState("");
  const [filterBy, setFilterBy] = useState(tableKeys[0]);
  const [orderField, setOrderField] = useState(tableKeys[0]);
  const [orderAsc, setOrderAsc] = useState(false);
  const [page, setPage] = useState(0);

    // BLOCK CODE THAT FILTER THE DATA IF THE USER ENTERED A FILTER STRING
    if (filter.length > 0) {
      data = data.filter((e) =>
        e[`${filterBy}`].toString().toUpperCase().includes(filter.toUpperCase())
      );
    }

      // SORT DATA IMPLEMENTATION ON BASIS OF orderField AND orderField
  data.sort((a, b) => {
    if (orderAsc) {
      if(typeof(a[`${orderField}`]) === "number"){
        return a[`${orderField}`]-b[`${orderField}`];
      }
      else{
        if (
          a[`${orderField}`].toString().toUpperCase() <=
          b[`${orderField}`].toString().toUpperCase()
        ) {
          return -1;
        } else {
          return 1;
        }
      }
    } else {
      if(typeof(a[`${orderField}`]) === "number"){
        return b[`${orderField}`]-a[`${orderField}`];
      }
      else{
        if (
          a[`${orderField}`].toString().toUpperCase() <=
          b[`${orderField}`].toString().toUpperCase()
        ) {
          return 1;
        } else {
          return -1;
        }
      }
    }
  });

  // FUNCTION THAT HANDLE THE filter STATE
  const filterHandler = (e) => {
    setFilter(e.target.value);
    setPage(0);
  };

  // FUNCTION THAT HANDLE THE filterBy STATE
  const changeFilterHandler = (e) => {
    setFilterBy(e.target.value);
    setPage(0);
  };

    // FUNCTION THAT HANDLE THE orderField STATE
    const changeOrder = (e) => {
      setOrderField(e.target.textContent);
      setOrderAsc((est) => !est);
    };

  const showButton = (log) => {
    if (log.Status === "Pending supervisor approval" && log["Approved by Supervisor"] === currentUser.username){
      return true
    }

    else if (log.Status === "Pending Zoom PO approval" && currentUser.idTokenClaims.roles.some(role => role.includes("AdminForeignRequest"))){
      return true
    }
    else {
      return false
    } 
  }
  return (
    <div className="em-c-table-object">
        <div className="table-actions">
          <input
            value={filter}
            onChange={filterHandler}
            placeholder={`Filter by ...`}
          />
          <select value={filterBy} onChange={changeFilterHandler}>
            {tableKeys.map((e) => (
              <option key={e} value={e}>
                {e}
              </option>
            ))}
          </select>
        <div className="em-c-table-object__body-inner">
          <table className="em-c-table">
          <thead className="em-c-table__header">
              <tr className="em-c-table__header-row">
              <th scope="col" className="em-c-table__header-cell"></th>

                {tableKeys.map((e, index) => 
                  index + 1 === tableKeys.length ? (
                    <th scope="col" className="em-c-table__header-cell" key={Math.random()}>
                      <div>
                        {orderField === e && orderAsc && <DownAngle />}
                        {orderField === e && !orderAsc && <TopAngle />}
                        {e}
                      </div>
                    </th>
                  ) : (
                    <th className="em-c-table__header-cell" key={Math.random()} onClick={changeOrder}>
                      <div>
                        {orderField === e && orderAsc && <DownAngle />}
                        {orderField === e && !orderAsc && <TopAngle />}
                        {e}
                      </div>
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody className="em-c-table__body">
              {data && data.map( (e, index) => (
                <tr key={index}>
                  <td key={Math.random()}>
                    {showButton(e) 
                      && <ApproveRejectPhoneRequestButton 
                            key={index}
                            webApiToken={webApiToken} 
                            request={e} 
                            callBackApproveError={callBackApproveError}
                            callBackRejectError={callBackRejectError}
                            callbackApproveSuccess={callbackApproveSuccess}
                            callbackRejectSuccess={callbackRejectSuccess}
                            isDual={isDual}
                          />
                    }
                  </td>
                  {Object.values(e).map((e2) => (
                    <td key={Math.random()} className="em-c-table__cell em-js-cell em-js-cell-editable" colSpan="">
                      { e2 }
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot className="em-c-table__footer">
              <tr className="em-c-table__footer-row">
              </tr>
            </tfoot>
          </table>
        </div>
        </div>        
    </div>
  );
};

export default TableUnity;
