export const ZoomCountriesAbbreviationListWithIAMfixes =
[
  {
    "ID": "AE",
    "Name": "United Arab Emirates"
  },
  {
    "ID": "AD",
    "Name": "Andorra"
  },
  {
    "ID": "AF",
    "Name": "Afghanistan"
  },
  {
    "ID": "AG",
    "Name": "Antigua and Barbuda"
  },
  {
    "ID": "AI",
    "Name": "Anguilla"
  },
  {
    "ID": "AL",
    "Name": "Albania"
  },
  {
    "ID": "AM",
    "Name": "Armenia"
  },
  {
    "ID": "AN",
    "Name": "Netherlands Antilles"
  },
  {
    "ID": "AO",
    "Name": "Angola"
  },
  {
    "ID": "AQ",
    "Name": "Antarctica"
  },
  {
    "ID": "AR",
    "Name": "Argentina"
  },
  {
    "ID": "AS",
    "Name": "American Samoa"
  },
  {
    "ID": "AT",
    "Name": "Austria"
  },
  {
    "ID": "AU",
    "Name": "Australia"
  },
  {
    "ID": "AW",
    "Name": "Aruba"
  },
  {
    "ID": "AX",
    "Name": "Aland Islands"
  },
  {
    "ID": "AZ",
    "Name": "Azerbaijan"
  },
  {
    "ID": "BA",
    "Name": "Bosnia and Herzegovina"
  },
  {
    "ID": "BB",
    "Name": "Barbados"
  },
  {
    "ID": "BD",
    "Name": "Bangladesh"
  },
  {
    "ID": "BE",
    "Name": "Belgium"
  },
  {
    "ID": "BF",
    "Name": "Burkina Faso"
  },
  {
    "ID": "BG",
    "Name": "Bulgaria"
  },
  {
    "ID": "BH",
    "Name": "Bahrain"
  },
  {
    "ID": "BI",
    "Name": "Burundi"
  },
  {
    "ID": "BJ",
    "Name": "Benin"
  },
  {
    "ID": "BM",
    "Name": "Bermuda"
  },
  {
    "ID": "BN",
    "Name": "Brunei Darussalam"
  },
  {
    "ID": "BO",
    "Name": "Bolivia"
  },
  {
    "ID": "BR",
    "Name": "Brazil"
  },
  {
    "ID": "BS",
    "Name": "Bahamas"
  },
  {
    "ID": "BT",
    "Name": "Bhutan"
  },
  {
    "ID": "BV",
    "Name": "Bouvet Island"
  },
  {
    "ID": "BW",
    "Name": "Botswana"
  },
  {
    "ID": "BY",
    "Name": "Belarus"
  },
  {
    "ID": "BZ",
    "Name": "Belize"
  },
  {
    "ID": "CA",
    "Name": "Canada"
  },
  {
    "ID": "CD",
    "Name": "The Democratic Republic of The Congo"
  },
  {
    "ID": "CF",
    "Name": "Central African Republic"
  },
  {
    "ID": "CG",
    "Name": "Congo"
  },
  {
    "ID": "CH",
    "Name": "Switzerland"
  },
  {
    "ID": "CI",
    "Name": "Cote D'Ivoire"
  },
  {
    "ID": "CK",
    "Name": "Cook Islands"
  },
  {
    "ID": "CL",
    "Name": "Chile"
  },
  {
    "ID": "CM",
    "Name": "Cameroon"
  },
  {
    "ID": "CN",
    "Name": "China"
  },
  {
    "ID": "CO",
    "Name": "Colombia"
  },
  {
    "ID": "CR",
    "Name": "Costa Rica"
  },
  {
    "ID": "CS",
    "Name": "Serbia and Montenegro"
  },
  {
    "ID": "CV",
    "Name": "Cape Verde"
  },
  {
    "ID": "CY",
    "Name": "Cyprus"
  },
  {
    "ID": "CZ",
    "Name": "Czech Republic"
  },
  {
    "ID": "CZ",
    "Name": "Czechia"
  },
  {
    "ID": "DE",
    "Name": "Germany"
  },
  {
    "ID": "DJ",
    "Name": "Djibouti"
  },
  {
    "ID": "DK",
    "Name": "Denmark"
  },
  {
    "ID": "DM",
    "Name": "Dominica"
  },
  {
    "ID": "DO",
    "Name": "Dominican Republic"
  },
  {
    "ID": "DZ",
    "Name": "Algeria"
  },
  {
    "ID": "EC",
    "Name": "Ecuador"
  },
  {
    "ID": "EE",
    "Name": "Estonia"
  },
  {
    "ID": "EG",
    "Name": "Egypt"
  },
  {
    "ID": "ER",
    "Name": "Eritrea"
  },
  {
    "ID": "ES",
    "Name": "Spain"
  },
  {
    "ID": "ET",
    "Name": "Ethiopia"
  },
  {
    "ID": "FI",
    "Name": "Finland"
  },
  {
    "ID": "FJ",
    "Name": "Fiji"
  },
  {
    "ID": "FK",
    "Name": "Falkland Islands (Malvinas)"
  },
  {
    "ID": "FM",
    "Name": "Federated States of Micronesia"
  },
  {
    "ID": "FO",
    "Name": "Faroe Islands"
  },
  {
    "ID": "FR",
    "Name": "France"
  },
  {
    "ID": "GA",
    "Name": "Gabon"
  },
  {
    "ID": "GB",
    "Name": "United Kingdom of Great Britain and Northern Ireland"
  },
  {
    "ID": "GB",
    "Name": "United Kingdom"
  },
  {
    "ID": "GD",
    "Name": "Grenada"
  },
  {
    "ID": "GE",
    "Name": "Georgia"
  },
  {
    "ID": "GF",
    "Name": "French Guiana"
  },
  {
    "ID": "GG",
    "Name": "Guernsey"
  },
  {
    "ID": "GH",
    "Name": "Ghana"
  },
  {
    "ID": "GI",
    "Name": "Gibraltar"
  },
  {
    "ID": "GL",
    "Name": "Greenland"
  },
  {
    "ID": "GM",
    "Name": "Gambia"
  },
  {
    "ID": "GN",
    "Name": "Guinea"
  },
  {
    "ID": "GP",
    "Name": "Guadeloupe"
  },
  {
    "ID": "GQ",
    "Name": "Equatorial Guinea"
  },
  {
    "ID": "GR",
    "Name": "Greece"
  },
  {
    "ID": "GS",
    "Name": "South Georgia and The South Sandwich Islands"
  },
  {
    "ID": "GT",
    "Name": "Guatemala"
  },
  {
    "ID": "GU",
    "Name": "Guam"
  },
  {
    "ID": "GW",
    "Name": "Guinea-Bissau"
  },
  {
    "ID": "GY",
    "Name": "Guyana"
  },
  {
    "ID": "HK",
    "Name": "Hong Kong"
  },
  {
    "ID": "HN",
    "Name": "Honduras"
  },
  {
    "ID": "HR",
    "Name": "Croatia"
  },
  {
    "ID": "HT",
    "Name": "Haiti"
  },
  {
    "ID": "HU",
    "Name": "Hungary"
  },
  {
    "ID": "ID",
    "Name": "Indonesia"
  },
  {
    "ID": "IE",
    "Name": "Ireland"
  },
  {
    "ID": "IL",
    "Name": "Israel"
  },
  {
    "ID": "IM",
    "Name": "Isle of Man"
  },
  {
    "ID": "IN",
    "Name": "India"
  },
  {
    "ID": "IO",
    "Name": "British Indian Ocean Territory"
  },
  {
    "ID": "IQ",
    "Name": "Iraq"
  },
  {
    "ID": "IS",
    "Name": "Iceland"
  },
  {
    "ID": "IT",
    "Name": "Italy"
  },
  {
    "ID": "JE",
    "Name": "Jersey"
  },
  {
    "ID": "JM",
    "Name": "Jamaica"
  },
  {
    "ID": "JO",
    "Name": "Jordan"
  },
  {
    "ID": "JP",
    "Name": "Japan"
  },
  {
    "ID": "KE",
    "Name": "Kenya"
  },
  {
    "ID": "KG",
    "Name": "Kyrgyzstan"
  },
  {
    "ID": "KH",
    "Name": "Cambodia"
  },
  {
    "ID": "KI",
    "Name": "Kiribati"
  },
  {
    "ID": "KM",
    "Name": "Comoros"
  },
  {
    "ID": "KN",
    "Name": "Saint Kitts and Nevis"
  },
  {
    "ID": "KR",
    "Name": "Republic of Korea"
  },
  {
    "ID": "KR",
    "Name": "South Korea"
  },
  {
    "ID": "KW",
    "Name": "Kuwait"
  },
  {
    "ID": "KY",
    "Name": "Cayman Islands"
  },
  {
    "ID": "KZ",
    "Name": "Kazakhstan"
  },
  {
    "ID": "LA",
    "Name": "Lao People's Democratic Republic"
  },
  {
    "ID": "LB",
    "Name": "Lebanon"
  },
  {
    "ID": "LC",
    "Name": "Saint Lucia"
  },
  {
    "ID": "LI",
    "Name": "Liechtenstein"
  },
  {
    "ID": "LK",
    "Name": "Sri Lanka"
  },
  {
    "ID": "LR",
    "Name": "Liberia"
  },
  {
    "ID": "LS",
    "Name": "Lesotho"
  },
  {
    "ID": "LT",
    "Name": "Lithuania"
  },
  {
    "ID": "LU",
    "Name": "Luxembourg"
  },
  {
    "ID": "LV",
    "Name": "Latvia"
  },
  {
    "ID": "LY",
    "Name": "Libyan Arab Jamahiriya"
  },
  {
    "ID": "MA",
    "Name": "Morocco"
  },
  {
    "ID": "MC",
    "Name": "Monaco"
  },
  {
    "ID": "MD",
    "Name": "Republic of Moldova"
  },
  {
    "ID": "ME",
    "Name": "Montenegro"
  },
  {
    "ID": "MF",
    "Name": "Saint Martin"
  },
  {
    "ID": "MG",
    "Name": "Madagascar"
  },
  {
    "ID": "MH",
    "Name": "Marshall Islands"
  },
  {
    "ID": "MK",
    "Name": "The Former Yugoslav Republic of Macedonia"
  },
  {
    "ID": "ML",
    "Name": "Mali"
  },
  {
    "ID": "MM",
    "Name": "Myanmar"
  },
  {
    "ID": "MN",
    "Name": "Mongolia"
  },
  {
    "ID": "MO",
    "Name": "Macau SAR/ Macao"
  },
  {
    "ID": "MP",
    "Name": "Northern Mariana Islands"
  },
  {
    "ID": "MQ",
    "Name": "Martinique"
  },
  {
    "ID": "MR",
    "Name": "Mauritania"
  },
  {
    "ID": "MS",
    "Name": "Montserrat"
  },
  {
    "ID": "MT",
    "Name": "Malta"
  },
  {
    "ID": "MU",
    "Name": "Mauritius"
  },
  {
    "ID": "MV",
    "Name": "Maldives"
  },
  {
    "ID": "MW",
    "Name": "Malawi"
  },
  {
    "ID": "MX",
    "Name": "Mexico"
  },
  {
    "ID": "MY",
    "Name": "Malaysia"
  },
  {
    "ID": "MZ",
    "Name": "Mozambique"
  },
  {
    "ID": "NA",
    "Name": "Namibia"
  },
  {
    "ID": "NC",
    "Name": "New Caledonia"
  },
  {
    "ID": "NE",
    "Name": "Niger"
  },
  {
    "ID": "NF",
    "Name": "Norfolk Island"
  },
  {
    "ID": "NG",
    "Name": "Nigeria"
  },
  {
    "ID": "NI",
    "Name": "Nicaragua"
  },
  {
    "ID": "NL",
    "Name": "Netherlands"
  },
  {
    "ID": "NO",
    "Name": "Norway"
  },
  {
    "ID": "NP",
    "Name": "Nepal"
  },
  {
    "ID": "NR",
    "Name": "Nauru"
  },
  {
    "ID": "NU",
    "Name": "Niue"
  },
  {
    "ID": "NZ",
    "Name": "New Zealand"
  },
  {
    "ID": "OM",
    "Name": "Oman"
  },
  {
    "ID": "PA",
    "Name": "Panama"
  },
  {
    "ID": "PE",
    "Name": "Peru"
  },
  {
    "ID": "PF",
    "Name": "French Polynesia"
  },
  {
    "ID": "PG",
    "Name": "Papua New Guinea"
  },
  {
    "ID": "PH",
    "Name": "Philippines"
  },
  {
    "ID": "PK",
    "Name": "Pakistan"
  },
  {
    "ID": "PL",
    "Name": "Poland"
  },
  {
    "ID": "PM",
    "Name": "Saint Pierre and Miquelon"
  },
  {
    "ID": "PR",
    "Name": "Puerto Rico"
  },
  {
    "ID": "PS",
    "Name": "Palestinian Territory, Occupied"
  },
  {
    "ID": "PT",
    "Name": "Portugal"
  },
  {
    "ID": "PW",
    "Name": "Palau"
  },
  {
    "ID": "PY",
    "Name": "Paraguay"
  },
  {
    "ID": "QA",
    "Name": "Qatar"
  },
  {
    "ID": "RE",
    "Name": "Reunion"
  },
  {
    "ID": "RO",
    "Name": "Romania"
  },
  {
    "ID": "RS",
    "Name": "Serbia"
  },
  {
    "ID": "RU",
    "Name": "Russian Federation"
  },
  {
    "ID": "RW",
    "Name": "Rwanda"
  },
  {
    "ID": "SA",
    "Name": "Saudi Arabia"
  },
  {
    "ID": "SB",
    "Name": "Solomon Islands"
  },
  {
    "ID": "SC",
    "Name": "Seychelles"
  },
  {
    "ID": "SE",
    "Name": "Sweden"
  },
  {
    "ID": "SG",
    "Name": "Singapore"
  },
  {
    "ID": "SI",
    "Name": "Slovenia"
  },
  {
    "ID": "SK",
    "Name": "Slovakia"
  },
  {
    "ID": "SL",
    "Name": "Sierra Leone"
  },
  {
    "ID": "SM",
    "Name": "San Marino"
  },
  {
    "ID": "SN",
    "Name": "Senegal"
  },
  {
    "ID": "SO",
    "Name": "Somalia"
  },
  {
    "ID": "SR",
    "Name": "Suriname"
  },
  {
    "ID": "SS",
    "Name": "South Sudan"
  },
  {
    "ID": "ST",
    "Name": "Sao Tome and Principe"
  },
  {
    "ID": "SV",
    "Name": "El Salvador"
  },
  {
    "ID": "SZ",
    "Name": "Swaziland"
  },
  {
    "ID": "TC",
    "Name": "Turks and Caicos Islands"
  },
  {
    "ID": "TD",
    "Name": "Chad"
  },
  {
    "ID": "TF",
    "Name": "French Southern Territories"
  },
  {
    "ID": "TG",
    "Name": "Togo"
  },
  {
    "ID": "TH",
    "Name": "Thailand"
  },
  {
    "ID": "TJ",
    "Name": "Tajikistan"
  },
  {
    "ID": "TK",
    "Name": "Tokelau"
  },
  {
    "ID": "TL",
    "Name": "Timor-Leste"
  },
  {
    "ID": "TM",
    "Name": "Turkmenistan"
  },
  {
    "ID": "TN",
    "Name": "Tunisia"
  },
  {
    "ID": "TO",
    "Name": "Tonga"
  },
  {
    "ID": "TR",
    "Name": "Turkey"
  },
  {
    "ID": "TT",
    "Name": "Trinidad and Tobago"
  },
  {
    "ID": "TV",
    "Name": "Tuvalu"
  },
  {
    "ID": "TW",
    "Name": "Taiwan"
  },
  {
    "ID": "TZ",
    "Name": "United Republic of Tanzania"
  },
  {
    "ID": "TZ",
    "Name": "Tanzania"
  },
  {
    "ID": "UA",
    "Name": "Ukraine"
  },
  {
    "ID": "UG",
    "Name": "Uganda"
  },
  {
    "ID": "UK",
    "Name": "(See \"GB\")"
  },
  {
    "ID": "UM",
    "Name": "United States Minor Outlying Islands"
  },
  {
    "ID": "US",
    "Name": "United States of America"
  },
  {
    "ID": "US",
    "Name": "United States"
  },
  {
    "ID": "UY",
    "Name": "Uruguay"
  },
  {
    "ID": "UZ",
    "Name": "Uzbekistan"
  },
  {
    "ID": "VA",
    "Name": "Holy See (Vatican City State)"
  },
  {
    "ID": "VC",
    "Name": "Saint Vincent and The Grenadines"
  },
  {
    "ID": "VE",
    "Name": "Venezuela"
  },
  {
    "ID": "VG",
    "Name": "Virgin Islands, British"
  },
  {
    "ID": "VI",
    "Name": "Virgin Islands, U.S."
  },
  {
    "ID": "VN",
    "Name": "Vietnam"
  },
  {
    "ID": "VN",
    "Name": "Viet Nam"
  },
  {
    "ID": "VU",
    "Name": "Vanuatu"
  },
  {
    "ID": "WF",
    "Name": "Wallis and Futuna"
  },
  {
    "ID": "WS",
    "Name": "Samoa"
  },
  {
    "ID": "YE",
    "Name": "Yemen"
  },
  {
    "ID": "YT",
    "Name": "Mayotte"
  },
  {
    "ID": "ZA",
    "Name": "South Africa"
  },
  {
    "ID": "ZM",
    "Name": "Zambia"
  },
  {
    "ID": "ZW",
    "Name": "Zimbabwe"
  }
]