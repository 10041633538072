import { useEffect, useState } from "react";
import styled from "styled-components";
import Table from "../../Components/Table";
import GetSitePhoneNumberMetrics from "../../Hooks/funcGetSitePhoneNumberMetrics";
import useWebAPIToken from "../../Hooks/useWebAPIToken";
import CircleLoader from "react-spinners/CircleLoader";

/////////////////////////COMPONENT STYLES/////////////////////////////////
const Wrapper = styled.div`
  color: var(--black);
  max-width: 100%;
  background-color: var(--primary-white-light);
  padding: var(--content-padding);
  border-radius: var(--content-border-radius);
  margin: var(--content-margin);
  box-shadow: var(--box-shadow-main);

  .PhoneNumberMetrics-marginSubTitle {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .PhoneNumberMetrics-totalrequests {
    margin: 8px 15px;
    margin-bottom: 30px;
  }

  .loader {
    margin: 40px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }

  .loader > p {
    margin-top: 10px;
    font-size: 16px;
  }

  Table {
    background-color: var(--white);
  }

  .justify-flex {
    justify-content: flex-start;
  }
  .outer-container {
    margin: 0 5px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
    border: var(--black) solid 2px;
  }
  .last-days-picker {
    margin: 0;
    background-color: var(--black);
    font-size: 16px;
    padding: 6px 12px;
    border-right: solid 1px var(--black);
    color: var(--white);
  }

  .picker {
    border: none;
    border-left: solid 1px var(--black);
    font-size: 16px;
    padding: 6px;
    color: var(--black);
  }
  .picker:focus {
    background-color: var(--primary-blue-light);
  }
`;

/////////////////////////MAIN COMPONENT/////////////////////////////////
const PhoneNumberMetrics = () => {
  const [data, setData] = useState([]);
  const [zoomPhoneMetricList, setZoomPhoneMetricList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showLastPhone, setShowLastPhone] = useState(0);
  const webApiToken = useWebAPIToken();

  // const [filterDate, setFilterDate] = useState("");
  const [startDate, onChangeStartDate] = useState(new Date());
  const [endDate, onChangeEndDate] = useState(new Date());

  // const changeHandler = (startDate, endDate) => {
  //   let Diff_time = endDate.getTime() - startDate.getTime();

  //   let Diff_day = Diff_time / (1000 * 3600 * 24);
  //   console.log('diff_day',Diff_day)
  //   console.log("math",Math.floor(Diff_day))
  //       setShowLastPhone(Math.floor(Diff_day));
  //   //setShowLastPhone(e.target.value);
  // };

  const changeHandler = (e) => {
    setShowLastPhone(e.target.value);
  };

  const onStartDateChange = (e) => {
    onChangeStartDate(new Date(e));
    // changeHandler(e, endDate);
  };
  const onEndDateChange = (e) => {
    onChangeEndDate(new Date(e));
    //changeHandler(startDate, e);
  };

  // const onChangeDate = (e) => {
  //   setFilterDate(e.target.value);
  // };

  // SETTING LOCAL FUNCTIONS FOR THE COMPONENT
  // FUNCTION TO DISABLE PAST DATE TO RECORDING START DATE INPUT
  // CREATE A LOCAL VARIABLE WITH THE CURRENT DATE
  // RETURNING THIS DATE FORMATED AS yyyy-mm-dd
  const disableDate = () => {
    const today = new Date();
    const prior = new Date();

    prior.setDate(today.getDate() - 30);

    const dd = String(prior.getDate()).padStart(2, "0");
    const mm = String(prior.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = prior.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  // SETTING LOCAL FUNCTIONS FOR THE COMPONENT
  // FUNCTION TO DISABLE PAST DATE TO RECORDING START DATE INPUT
  // CREATE A LOCAL VARIABLE WITH THE CURRENT DATE
  // RETURNING THIS DATE FORMATED AS yyyy-mm-dd
  const currentDate = () => {
    const today = new Date();

    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  useEffect(() => {
    setIsLoading(true);
    let timeout;
    if (webApiToken) {
      timeout = setTimeout(() => {
        fetchSitePhoneNumberMetrics();
      }, 100);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [webApiToken, showLastPhone]);

  // const removeItem=['00_NA_EMC'];
  // useEffect(()=>{
  //   if(data?.zoomPhoneMetricList &&  zoomPhoneMetricList.length === 0) {
  //         let filterdata=data.zoomPhoneMetricList.filter(
  //           function(item){
  //             return removeItem.indexOf(item.siteName) === -1
  //           }
  //         )
  //         console.log('filterdata',filterdata)
  //         setZoomPhoneMetricList(filterdata);
  //       }

  // },[data, zoomPhoneMetricList])
  const fetchSitePhoneNumberMetrics = async () => {
    if (webApiToken) {
      try {
        setIsLoading(true);
        const [sitePhoneNumberMetricsResponse] = await Promise.all([
          GetSitePhoneNumberMetrics(webApiToken, showLastPhone),
        ]);

        // console.log("new_date",sitePhoneNumberMetricsResponse)
        let filterdata = sitePhoneNumberMetricsResponse.data.zoomPhoneMetricList
          .filter((site) => !site.siteName.toLowerCase().includes("test"))
          .filter((site) => !site.siteName.includes("00_"));
        // let filterdata=sitePhoneNumberMetricsResponse.data.zoomPhoneMetricList.filter(
        //   function(item="00_"){
        //     return removeItem.indexOf(item.siteName) === -1
        //   }
        // )
        // console.log("filterdata",filterdata)
        setZoomPhoneMetricList(filterdata);
        setData(sitePhoneNumberMetricsResponse.data);
      } catch (err) {
        console.log("err.message", err.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Wrapper>
      <h1>Phone Number Metrics</h1>
      <div className="App-horizontal-line" />
      <br />
      {!isLoading && (
        <div>
          <p>Please select interval below</p>
          <div className="flex-row justify-flex">
            <div className="flex-row outer-container">
              <label className="last-days-picker">Loading data for last:</label>
              {
                <select
                  className="picker"
                  value={showLastPhone}
                  onChange={changeHandler}
                >
                  <option value={0}>current day</option>
                  <option value={1}>1 day</option>
                  <option value={7}>7 days</option>
                  <option value={14}>14 days</option>
                  <option value={21}>21 days</option>
                  <option value={30}>30 days</option>
                  <option value={90}>90 days</option>
                  <option value={365}>365 days</option>
                </select>
              }
            </div>
            {/* <DatePicker nativeInputAriaLabel="Start Date" onChange={(e)=> onStartDateChange(e)} value={startDate}  maxDate={new Date()}/> 
            <DatePicker onChange={(e)=> onEndDateChange(e)} value={endDate} maxDate={new Date()}/>
            <button className="btn btn-primary" onClick={()=>changeHandler(startDate, endDate)} >Search</button> */}
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="flex-column loader">
          <CircleLoader size="100px" color="#4c4c4c" />
          <p>Loading data...</p>
        </div>
      ) : (
        <div>
          {/* <div className="flex-row justify-flex">
              <div className="flex-row outer-container">
                <label className="last-days-picker">Loading data for last:</label>
                
                <input
                  onBlur={onChangeDate}
                  value={filterDate}
                  type={"date"}
                  min={disableDate()}
                  max={currentDate()}
                />

              </div>
            </div> */}

          {/* <div className="flex-row justify-flex">
              <div className="flex-row outer-container">
                <label className="last-days-picker">Loading data for last:</label>
                <select className="picker" value={showLastPhone} onChange={changeHandler}>
                  <option value={1}>1 day</option>
                  <option value={7}>7 days</option>
                  <option value={14}>14 days</option>
                  <option value={21}>21 days</option>
                  <option value={30}>30 days</option>
                </select>
              </div>
            </div> */}
          {!isLoading && (
            <div>
              <h4 className="PhoneNumberMetrics-marginSubTitle">
                Zoom Metrics
              </h4>
              {zoomPhoneMetricList.length > 0 ? (
                <Table
                  data={zoomPhoneMetricList?.map((item) => {
                    return {
                      ID: item.id,
                      "Site Name": item.siteName,
                      "Assigned To Users": item.assignedCount,
                      "Assigned To Non Users": item.assignedNonUserCount,
                      "Unassigned Numbers": item.unassignedCount,
                      "Total Numbers": item.totalCount,
                      "Assigned Percent (%)": Math.round(
                        item.assignedPercent * 100
                      ),
                      Date: item.createDate?.substring(0, 10),
                      
                    };
                  })}
                />
              ) : (
                <p>No data returned for {showLastPhone} day(s)</p>
              )}

              <h4 className="PhoneNumberMetrics-marginSubTitle">
                Phone request Metrics
              </h4>
              {data.portalPhoneMetricList.length > 0 ? (
                <div>
                  <div className="PhoneNumberMetrics-totalrequests">
                    <p style={{ fontWeight: "bold" }}>Summary</p>
                    <p>Phone numbers: {data.numberNew}</p>
                    <p>Updated site location: {data.numberMoved}</p>
                    <p>Released phone number: {data.numberReleased}</p>
                  </div>
                  <Table
                    data={data.portalPhoneMetricList?.map((item) => {
                      return {
                        "Site Name": item.siteName,
                        "Number requests": item.numberNew,
                        "Update location requests": item.numberMoved,
                        "Release requests": item.numberReleased,
                        Date: item.createDate?.substring(0, 10),
                      };
                    })}
                  />
                </div>
              ) : (
                <p>No data returned for {showLastPhone} day(s)</p>
              )}

              <h4 className="PhoneNumberMetrics-marginSubTitle">
                Total Submitted Requests
              </h4>
              <div className="PhoneNumberMetrics-totalrequests">
                <p>Phone numbers: {data.totalNumberNew}</p>
                <p>Updated site location: {data.totalNumberMoved}</p>
                <p>Released phone numbers: {data.totalNumberReleased}</p>
                <p>Page counter views: {data.pageCounter}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default PhoneNumberMetrics;
