import styled from "styled-components";
import { useHistory } from "react-router";

const StyledTileWrapper = styled.div`
  display: flex;
  flex: 0 1 22rem;
`;

const StyledTile = styled.div`
  width: 22rem;
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--primary-white-light);

  box-shadow: var(--box-shadow-main);
  border: 1px solid var(--primary-grey-light);

  &:hover {
    cursor: pointer;
    border: 1px solid var(--primary-blue);
  }

  ${
    "" /* @media only screen and (min-width: 600px) {
    For tablets: 
    width: 12rem;
  }
  
  @media only screen and (min-width: 768px) {
    For desktop:
    width: 22rem;
  } */
  }

  h5 {
    font-size: 18px;
    margin: 0;
    margin: 10px 0px 20px 0px;
  }

  p {
    font-size: 15px;
    text-align: justify;
    margin: 0 5px;
  }

  svg {
    color: var(--primary-blue);
    width: 60px;
    margin: 10px 0px 20px 0px;
  }

  & > .horizontal-line {
    background-color: var(--black);
    height: 1px;
    margin: 10px 0;
    width: 100%;
  }
`;
export const HomepageTile = (props) => {
  const history = useHistory();
  const SVGReceived = props.svg;

  const clickHandler = () =>
    // props.external
    //   ? window.location.replace(`${props.redirectLink}`)
    //   : history.push(`${props.redirectLink}`);
    history.push(`${props.redirectLink}`);

  return (
    <StyledTileWrapper onClick={clickHandler}>
      <StyledTile className="flex-column">
        <h5>{props.title}</h5>
        <SVGReceived specificSize={props.SVGSize} />
        {props.children}
      </StyledTile>
    </StyledTileWrapper>
  );
};
