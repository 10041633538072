import { useEffect, useState } from "react";
import Table from "../../Components/Table";
import GetPhoneRequestLogs from "../../Hooks/funcPhoneRequestLogs";
import useWebAPIToken from "../../Hooks/useWebAPIToken";
import CircleLoader from "react-spinners/CircleLoader";
import PhoneRequestLogsWrapper from "./PhoneRequestLogs.css";

/////////////////////////MAIN COMPONENT/////////////////////////////////
const PhoneRequestLogs = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showLast, setShowLast] = useState(15);
  const webApiToken = useWebAPIToken();

  useEffect(() => {
    setIsLoading(true);
    let timeout;

    const fetchPhoneRequestLogs = async () => {
      if (webApiToken) {
        try {
          setIsLoading(true);
          const [phoneRequestLogsResponse] = await Promise.all([
            GetPhoneRequestLogs(webApiToken, showLast),
          ]);
  
          setData(phoneRequestLogsResponse.data);
        } catch (err) {
          console.log("err.message", err.message);
        } finally {
          setIsLoading(false);
        }
      }
    };

    if (webApiToken) {
      timeout = setTimeout(() => {
        fetchPhoneRequestLogs();
      }, 100);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [webApiToken, showLast]);

  return (
    <PhoneRequestLogsWrapper>
      <h1>Submitted Zoom Phone Requests</h1>
      <div className="App-horizontal-line" />
      <br />
      
      <div className="upper-text">
        <p>
          This page shows all of the Zoom Phone requests made through the Request
          Form. This form is open to all users, but you can only view requests
          where you are the <b>Requested by</b> or <b>Requested for</b> user.
        </p>
      </div>
      
      <div className="flex-row justify-flex">
        <div className="flex-row outer-container">
        <span className="last-days-picker">Loading data for last:</span>
        <select className="picker" value={showLast} onChange={setShowLast}>
          <option value={1}>1 day</option>
          <option value={15}>15 days</option>
          <option value={30}>30 days</option>
          <option value={60}>60 days</option>
          <option value={180}>180 days</option>
          <option value={365}>365 days</option>
        </select>
        </div>
      </div>
      {isLoading && (
        <div className="flex-column loader">
          <CircleLoader size="100px" color="#007bff" />
          <p>Loading data...</p>
        </div>
      )}
      {!isLoading && data && data.length > 0 && <Table data={data} />}
      {!isLoading && data && data.length === 0 && (
        <div className="flex-column loader">
          <p>No data returned for this user!</p>
        </div>
      )}
    </PhoneRequestLogsWrapper>
  );
};

export default PhoneRequestLogs;
