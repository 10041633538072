import styled from "styled-components"
/* COMPONENT STYLES */
const ToolTipWrapper = styled.div`
  .ToolTip-icon {
    width: ${props => `${props.specificSize}px;`}; 
    height: ${props => `${props.specificSize}px;`}; 
    color: var(--primary-blue);

  }

  .ToolTip-icon:hover{
    transform: scale(1.3);
  }
`

export default ToolTipWrapper