import styled from "styled-components"

/* COMPONENT STYLES */
export const AutoCompleteWrapper = styled.div`
    display: flex;
    margin: 8px 15px;
    height: 110px ;

  .AutoComplete-label {
    font-size: 17px;
    font-weight: 600;
    margin : 5px 0px;
  }

  input {
    font-size: 15px;
    padding: 0px 15px;
    height: 35px;
    border: 1px solid var(--lightgrey);
    border-radius: 10px;
    width: ${props => props.specificSize? `${props.specificSize}px;`: "400px;"}; 
    margin: 0 5px;
  }

  input:focus-visible {
    outline: none;
    background-color: var(--lightgrey);
  }

  ul {
    position: absolute;
    height: 250px;
    width: 100%;
    width: ${props => props.specificSize? `${props.specificSize}px;`: "400px;"};  
    overflow: auto;
    list-style-type: none;
    padding: 0;
    border-style: solid;
    border: 0px;
    border-radius: 10px;
    background-color: var(--white);
    margin: 0;
    cursor: pointer;
    z-index: 3;

  }
  li {
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;
    
  }

  li:hover{
    opacity: 0.8;
    background-color: var(--primary-blue-light);
    border-radius: 6px;
    padding: 1rem 1rem;

  }

  .AutoComplete-note{
    font-size: .80rem;
    color: #112;
    font-weight: normal;
    margin: 0px 15px;
    width: 400px; 
  }

  .AutoComplete-box-list{
    margin-top: 5px;
    margin-left: 5px;
  }

  .AutoComplete-tool-tip{
    position: relative;
    float: right;
    margin-top: 4px;
    margin-right: 30px;
    margin-left: -30px;
    z-index: 2;
  }

  input.AutoComplete-invalid {
    border: 1px solid var(--red);
  }

  .AutoComplete-warning-message{
    color: var(--red) !important;
    font-size: 14px;
    margin-left: 15px;
    margin-bottom: 0;    
  }
    
`