import { useEffect, useState } from "react";
import styled from "styled-components";

/////////////////////////COMPONENT STYLES/////////////////////////////////
const Wrapper = styled.div`
  margin: 1rem;

  .group-input-error-message {
    display: flex;
    width: 50rem;
    align-items: center;
  }

  .error-message {
  }

  label {
    font-size: 1rem;
    margin-left: 0.5rem;
    text-align: justify;
    font-weight: normal;
  }

  input {
    height: 1.4rem;
    border: 1px solid var(--lightgrey);
    border-radius: 10px;
    width: 1.4rem;
    margin: 0 5px;
  }

  input:focus-visible {
    outline: none;
    background-color: var(--lightgrey);
  }
  p {
    color: var(--red) !important;
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 0.3rem;
    font-weight: 600;
    margin-left: 15px;
  }
`;

/////////////////////////MAIN COMPONENT/////////////////////////////////
const CheckBoxAgreement = (props) => {
  // 2. STATE THAT DEFINES IF THE USER ALREADY TRIED TO WORK WITH THIS FIELD
  // 3. STATE THAT HOLDS THE ERROR MESSAGE THAT WILL BE DISPLAYED
  const [validInput, setValidInput] = useState(true);
  const [firstTry, setFirstTry] = useState(true);
  const [errorMessage, setErrorMessage] = useState("Validation error");

  // BLOCK THAT MONITORS THE TOUCH ALL VARIABLE OF PARENT COMPONENT, IF IT IS trigger
  // SET firstTry TO FALSE IF IT IS submitted SET firstTry TO TRUE
  if (props.touchAll === "trigger" && firstTry) {
    setFirstTry(false);
  } else if (props.touchAll === "submitted" && !firstTry) {
    setFirstTry(true);
  }

  // FUNCTION THAT VALIDATED THE USER INPUT
  const InputValidator = () => {
    // 1. TESTING IF THE CHECK IS FALSE
    if (!props.value) {
      setErrorMessage(props.uncheckedMessage);
      setValidInput(false);
      return;
    } else {
      setValidInput(true);
    }
  };

  // FUNCTION THAT CHANGES THE VALUE FOR THE INPUT EVERY TIME THE USER HITS THE CHECKBOX
  const changeHandler = (e) => {
    if (props.setValue) {
      props.setValue(e.target.checked);
    }
  };

  useEffect(() => {
    InputValidator();
    if (props.GetState) {
      props.GetState({ [props.label]: validInput });
    }
  }, [props.value, validInput, props.label]);

  // COMPONENT JSX DEFINITION
  return (
    <Wrapper>
      <div className="group-input-error-message">
        <div className="">
          <input value={props.value} type="checkbox" onChange={changeHandler} />
        </div>
        <div>
          <label>{props.label}</label>
        </div>
      </div>
      <div className="error-message">
        {!validInput && !firstTry && <p>{errorMessage}</p>}
      </div>
    </Wrapper>
  );
};

export default CheckBoxAgreement;
