import API, { httpStatus, getError } from "./api";
import InternalServerError from "./Errors/InternalServer";

const GetPhoneRequestLogs = async (accessToken, lastDays) => {
  try {
    const api = await API(accessToken);
    return await api.get(`/PhoneRequest/GetLocalPhoneRequestLogs?days=${lastDays}`);
  } catch (err) {
    if (err.response) {
      if (err.response.status === httpStatus.InternalServerError) {
        throw new InternalServerError();
      }
    }
    throw getError(err);
  }
}

export default GetPhoneRequestLogs;