
import { useMsal } from "@azure/msal-react";


export function IsAuthorizedUser(role) {
    const { instance } = useMsal();
    const currentAccount = instance.getAllAccounts()[0];
    const LoadedRoles = currentAccount.idTokenClaims.roles;
  
    return LoadedRoles.some(value => role.includes(value));
}
