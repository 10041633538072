import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import LoadingScreen_V2 from "../../Components/LoadingScreen_V2";
import AutoComplete from "../../Components/AutoComplete/AutoComplete";
import Input from "../../Components/Input";
import PopUp from "../../Components/Popup";
import PhoneUserCard from "../../Components/PhoneUserCard/PhoneUserCard";

//api calls
// import GetZoomPhoneSites from "../../Hooks/funcGetZoomPhoneSites";
import GetEmergencyAddressList from "../../Hooks/funcGetEmergencyAddressList";
import FetchGraphData from "../../Hooks/funcFetchGraphData";
import useAccessToken from "../../Hooks/useAccessToken";
import useWebAPIToken from "../../Hooks/useWebAPIToken";
import getGraphToken from "../../Hooks/funcGetGraphToken";
import GetPhoneSitesOnboarded from "../../Hooks/funcGetPhoneSitesOnboarded";

import PhoneRequestFormWrapper from "./PhoneRequestForm.css";

/////////////////////////MAIN COMPONENT/////////////////////////////////

// #Region vars and setters
const PhoneRequestForm = () => {
  // SETTING LOCAL STATE FOR THE COMPONENT
  // 1. requestedByEmail EMAIL
  const [requestedByEmail, setRequestedByEmail] = useState("");

  // Trigger to fetch zoom sites

  // Get zoom phone sites location address
  const [zoomSitesEmergencyAddressResultData, setZoomSitesEmergencyAddressResultData] =
    useState([]);
  const [zoomSitesLocationsError, setZoomSitesLocationsError] = useState(null);
  const [zoomSitesEmergencyAddressLoading, setZoomSitesEmergencyAddressLoading] =
    useState(false);
  const [zoomSitesEmergencyAddressFail, setZoomSitesEmergencyAddressFail] = useState(false);

  // zoom phone number bulk request
  const [requestedForEmailBulkSearch, setRequestedForEmailBulkSearch] = useState("");
  const [requestedForSearchBulkResult, setRequestedForSearchBulkResult] = useState([]);
  const [requestedForBulkObj, setRequestedForBulkObj] = useState({});
  const [bulkUsersGetNumber, setBulkUsersGetNumber] = useState([]);
  const [userAlreadyHaveAPhone, setUserAlreadyHaveAPhone] = useState(false);
  const [showErrorPhoneAccountNotFound, setShowErrorPhoneAccountNotFound] = useState(false);
  const [errorPhoneAccount, setErrorPhoneAccount] = useState(false);
  const [showCouldNotFoundOnAzureAD, setShowCouldNotFoundOnAzureAD] = useState(false);

  //Phone number request
  const [phoneNumberRequestConfirmation, setPhoneNumberRequestConfirmation] =
    useState(false);
  const [triggerSubmitPhoneRequest, setTriggerSubmitPhoneRequest] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingPopUp, setIsShowingPopUp] = useState(false);

  const [showVerifyLocation, setShowVerifyLocation] = useState(true);

  const [graphAccessToken, setGraphAccessToken] = useState({});
  const [graphAccessTokenError, setGraphAccessTokenError] = useState("");
  const [graphAccessTokenFail, setGraphAccessTokenFail] = useState(false);
  const [graphAccessTokenLoading, setGraphAccessTokenLoading] = useState(false);

  const [popUpStatesForMoveSuccess, setPopUpStatesForMoveSuccess] = useState([]);

  const [usersInNotAllowSite, setUsersInNotAllowSite] = useState([]);
  
  // State used to fetch zoom account info by PhoneUserCard component
  const [onboardedSitesLoading, setOnboardedSitesLoading] = useState(false);
  const [onboardedSitesError, setOnboardedSitesError] = useState(null);
  const [onboardedSitesResponse, setOnboardedSitesResponse] = useState(null);



  // SETTING LOCAL CONSTANT FOR THE COMPONENT
  
  // 1. HISTORY TO REDIRECT PAGE
  // const history = useHistory();
  // 2. GENERATES TOKEN FOR THE GRAPH API
  const accessToken = useAccessToken();
  
  // 3. TOKEN FOR CUSTOM WEB API
  const webApiToken = useWebAPIToken();

  // #End region vars and setters

  // #Region Functions

  // Fetch zoom phone sites location address
  const FetchZoomPhoneLocationsAddress = async () => {
    if (webApiToken) {
      try {
        setZoomSitesEmergencyAddressLoading(true);
        const [zoomSitesEmergencyAddressResponse] = await Promise.all([
          GetEmergencyAddressList(webApiToken),
        ]);
        setZoomSitesEmergencyAddressResultData(zoomSitesEmergencyAddressResponse.data);
      } catch (err) {
        console.log("err.message: ", err.message);
        setZoomSitesLocationsError(err);
        setZoomSitesEmergencyAddressFail(true);
      } finally {
        setZoomSitesEmergencyAddressLoading(false);
      }
    }
  };

  const FetchPhoneSitesOnboarded = async () => {
    if (webApiToken) {
      setOnboardedSitesLoading(true);
      try {
        const [phoneSitesOnboardedResponse] = await Promise.all([
          GetPhoneSitesOnboarded(webApiToken),
        ]);
        setOnboardedSitesResponse(phoneSitesOnboardedResponse.data);
        setOnboardedSitesLoading(false);
      } catch (err) {
        setOnboardedSitesError(err);
      } finally {
        setOnboardedSitesLoading(false);
      }
    }
  };

  // Handle the click on the button "Clear List". Remove all the elements from the state bulkUsersGetNumber
  const clearBulkGetNumber = () => {
    setBulkUsersGetNumber([]);
  };

  // Handle the click on the icon "X" from a user into the bulk list get number. Remove a specific
  // user from bulkUsersGetNumber by userEmail
  const removeUserFromBulkGetNumber = (userEmail) => {
    setBulkUsersGetNumber(
      bulkUsersGetNumber.filter((user) => user !== userEmail)
    );
    setPopUpStatesForMoveSuccess(
      popUpStatesForMoveSuccess.filter((user) => user.userEmail !== userEmail)
    );
  };

  // Handle when could not found the zoom phone account.
  // set the state showErrorPhoneAccountNotFound as true.
  // remove the user from bulkUsersGetNumber by userEmail.
  const HandleErrorPhoneAccountNotFound = (error) => {
    setShowErrorPhoneAccountNotFound(error.showErrorUserPhoneAccountInfo);
    setErrorPhoneAccount(error.errorUserPhoneAccountInfo);
    removeUserFromBulkGetNumber(error.userEmail);
  };

  // Handle when the user is from a site that is not enable zoom phone.
  // set the state showErrorZoomPhoneNotEnabled as true.
  // remove the user from bulkUsersGetNumber by userEmail.
  const HandleErrorZoomPhoneNotEnabled = (error) => {
    if (
      !usersInNotAllowSite.some((value) => value.userEmail === error.userEmail)
    ) {
      setUsersInNotAllowSite((oldState) => {
        return [
          ...oldState,
          {
            showErrorZoomPhoneNotEnabled: error.showErrorZoomPhoneNotEnabled,
            userSiteErrorNotAllow: error.siteName,
            userEmail: error.userEmail,
          },
        ];
      });
    }

    removeUserFromBulkGetNumber(error.userEmail);
  };

  const removeUserFromUsersInNotAllowSite = (user) => {
    setUsersInNotAllowSite(
      usersInNotAllowSite.filter(
        (userItem) => userItem.userEmail !== user.userEmail
      )
    );
  };

  const HandleUserAlreadyHaveAPhone = (param) => {
    setUserAlreadyHaveAPhone(param.userAlreadyHaveAPhone);
    removeUserFromBulkGetNumber(param.userEmail);
  };

  const handleNewRequest = () => {
    setTriggerSubmitPhoneRequest(false);
    clearBulkGetNumber();
    setShowVerifyLocation(true);
  };

  // FUNCTION TO UPDATE THE INPUT STATES
  // USED TO DEFINE IF THE GLOBAL FORM STATE IS VALID OR INVALID
  const getStateMoveSuccessPopUp = (state) => {
    if (
      !popUpStatesForMoveSuccess.some(
        (value) => value.userEmail === state.userEmail
      )
    )
      setPopUpStatesForMoveSuccess((oldState) => {
        return [...oldState, state];
      });
    else {
      setPopUpStatesForMoveSuccess((prevState) => {
        const newState = prevState.map((obj) => {
          if (obj.userEmail === state.userEmail) {
            return {
              ...obj,
              isShowingPopUpMoveSuccess: state.isShowingPopUpMoveSuccess,
            };
          }

          return obj;
        });

        return newState;
      });
    }
  };

  // FUNCTION TO UPDATE THE INPUT STATES
  // USED TO DEFINE IF THE GLOBAL FORM STATE IS VALID OR INVALID
  const checkIFThereIsAnyMoveSuccessPopUpOpen = () => {
    if (popUpStatesForMoveSuccess.length > 0) {
      return popUpStatesForMoveSuccess.some(
        (popUp) => popUp.isShowingPopUpMoveSuccess === true
      );
    } else {
      return false;
    }
  };
  
  const handleClearRequestedForField = () => {
    setRequestedForBulkObj({});
    setRequestedForSearchBulkResult([]);
  };

  // #End region Functions

  // #Region useEffect

  useEffect(() => {
    checkIFThereIsAnyMoveSuccessPopUpOpen();
  }, [popUpStatesForMoveSuccess]);

  useEffect(() => {
    handleGetGraphToken();
  }, [webApiToken]);

  const handleGetGraphToken = async () => {
    if (webApiToken) {
      setGraphAccessTokenLoading(true);
      try {
        const [getGraphTokenResponse] = await Promise.all([
          getGraphToken(webApiToken),
        ]);
        setGraphAccessToken(getGraphTokenResponse.data);
      } catch (err) {
        console.log("err.message: ", err.message);
        setGraphAccessTokenError(err);
        setGraphAccessTokenFail(true);
      } finally {
        setGraphAccessTokenLoading(false);
      }
    }
  };

  // CUSTOM FUNCTION TO FETCH DATA FROM /me ENDPOINT FROM GRAPH API
  // WHEN THE HTTP REQUEST IS DONE, the state requestedByEmail IS UPDATED WITH
  // USER DATA RECEIVED FROM GRAPH
  useEffect(() => {
    if (accessToken) {
      FetchGraphData({
        endpoint: "/me",
        accessToken: accessToken,
      }).then((data) => {
        setRequestedByEmail(data.mail);
      });
    }
  }, [accessToken]);

  // CUSTOM FUNCTION TO FETCH DATA FOR requestedForEmailBulkSearch FROM GRAPH API
  // WHEN THE HTTP REQUEST IS DONE, the state RequestedForSearchBulkResult IS UPDATED WITH
  // USER DATA RECEIVED FROM GRAPH
  useEffect(() => {
    let timeout;
    if (graphAccessToken && requestedForEmailBulkSearch.length > 0) {
      timeout = setTimeout(async () => {
        let data = await FetchGraphData({
          endpoint:
            `/users?` +
            `$search="displayName:${requestedForEmailBulkSearch}" OR "mail:${requestedForEmailBulkSearch}"&` +
            `$filter=mail ne null&` +
            `$select=displayName,mail&` +
            `$orderby=displayName asc`,
          accessToken: graphAccessToken,
        });
        if (data.value && data.value.length > 0) {
          setShowCouldNotFoundOnAzureAD(false);
          setRequestedForSearchBulkResult(
            data.value.map((entry) => {
              return { mail: entry.mail, displayName: entry.displayName };
            })
          );
        } else {
          console.log("could not find this user please ask for an exception");
          setShowCouldNotFoundOnAzureAD(true);
        }
      }, 300);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [requestedForEmailBulkSearch, graphAccessToken]);

  useEffect(() => {
    FetchZoomPhoneLocationsAddress();
    FetchPhoneSitesOnboarded();
  }, [webApiToken]);

  // CUSTOM FUNCTION TO ADD USERS INTO bulkUsersGetNumber LIST
  useEffect(() => {
    if (requestedForBulkObj && requestedForBulkObj.mail) {
      //Avoid add duplicate users
      if (
        !bulkUsersGetNumber.find((user) => user === requestedForBulkObj.mail)
      ) {
        setBulkUsersGetNumber((old) => [...old, requestedForBulkObj.mail]);
        handleClearRequestedForField();
        setRequestedForEmailBulkSearch("");
      }
    }
  }, [requestedForBulkObj]);

  useEffect(() => {
    if (requestedForEmailBulkSearch.length === 0) {
      handleClearRequestedForField();
    }
  }, [requestedForEmailBulkSearch]);

  // se adicioanr alguem zera o campo
  // se cliclar fora zera tb

  // CUSTOM FUNCTION TO UPDATE isLoading
  // isLoading IS A STATE THAT REPRESENTS IF THERE IS ANY REQUESTION LOADING
  useEffect(() => {
    setIsLoading(zoomSitesEmergencyAddressLoading || graphAccessTokenLoading || onboardedSitesLoading);
  }, [zoomSitesEmergencyAddressLoading, graphAccessTokenLoading, onboardedSitesLoading]);

  // CUSTOM FUNCTION TO UPDATE isShowingPopUp
  // isShowingPopUp IS A STATE THAT REPRESENTS IF THERE IS SHOWING ANY POPUP
  useEffect(() => {
    setIsShowingPopUp(phoneNumberRequestConfirmation);
  }, [phoneNumberRequestConfirmation]);

  //#endregion useEffect


  // JSX CODE FOR MAIN COMPONENT
  return (
    <PhoneRequestFormWrapper>
      <h1>Zoom Phone Request Form</h1>
      <div className="App-horizontal-line" />
      <br />
      <p>
        This form is used to request a Zoom Phone number for external calling.
        Only those at{" "}
        <a
          href="https://ishareteam2.na.xom.com/sites/ModWkplc/moc/Shared%20Documents/Zoom%20External%20Calling%20deployed%20and%20approved%20Sites.pdf"
          target="__blank"
        >
          approved locations
        </a>{" "}
        may request a Zoom Phone Number at this time.
      </p>
      <p>
        Only request a Zoom Phone if you have a business case for
        making/receiving external calls. You do not need to request external
        dialing in the following cases:
      </p>
      <ul>
        <li>
          Occasional calls with external parties. You can use email, Zoom
          meetings, or a mobile device to occasionally talk with external
          parties.
        </li>
        <li>
          Emergency dialing is available with internal Zoom calling; an external
          dialing plan is not required.
        </li>
      </ul>
      <p>
        Both <b>requested by</b> and <b>requested for</b> individuals will
        receive automated confirmation emails for phone requests and location
        changes.
      </p>
      <p>
        You can verify your request was submitted by viewing the{" "}
        <Link target="_blank" to="/phone-request-logs">
          Submitted Zoom Phone Requests page.
        </Link>
      </p>
      {/* <p>
          <b>Zoom external calling will be deployed to&nbsp;<a href="https://ishareteam2.na.xom.com/sites/ModWkplc/moc/Shared%20Documents/Zoom%20External%20Calling%20Rollout%20Schedule_2022.pdf" target="__blank" >16 sites</a> in 2022. At this time, only users from those locations can
          submit a request to get a Zoom phone number.</b>
        </p> */}

      <div className="App-horizontal-line" />

      {(zoomSitesEmergencyAddressLoading || graphAccessTokenLoading) && (
        <LoadingScreen_V2 loadingMessage="Loading Zoom information."></LoadingScreen_V2>
      )}

      <div
        className={
          zoomSitesEmergencyAddressLoading || graphAccessTokenLoading ? "loading" : ""
        }
      >
        {/* <div> */}
        <h3>Get Zoom Phone Number</h3>

        <div className="row-section">
          <div>
            <Input
              note="Requester email address."
              label="Requested by"
              type="text"
              placeholder="user name"
              value={requestedByEmail}
              disabled
              toolTipMessage="Email of person submitting the request."
            />
          </div>
        </div>

        <div>
          {/*  display the current phone number */}
          {!zoomSitesLocationsError && !triggerSubmitPhoneRequest && (
            <div className="row-section">
              {/* <label>Requested for</label> */}
              <AutoComplete
                label="Requested for"
                inputValue={requestedForEmailBulkSearch}
                setInputValue={setRequestedForEmailBulkSearch}
                note={
                  <p>
                    You can search by name or ExxonMobil email. <br></br>You may
                    add more than one user to the <b>requested for</b> list.
                  </p>
                }
                listOfObjects={requestedForSearchBulkResult}
                optionKeyValues={["mail"]}
                returnSelectedObj={setRequestedForBulkObj}
                toolTipMessage="Email of person receiving the Zoom Phone."
                disableValidation
                userType
              ></AutoComplete>
            </div>
          )}

          {showCouldNotFoundOnAzureAD && (
            <div id="error-notfounduser">
              <div className="em-c-alert em-c-alert--warning">
                <div className="em-c-alert__body">
                  User not found. Check if the user is in Identity
                  Manager,&nbsp;
                  <a href="https://goto/idm" target="__blank">
                    goto/IDM
                  </a>
                  . If yes, please try again in 24 hours. If they are not in
                  Identity Manager,&nbsp;
                  <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
                    goto/GenericSR
                  </a>{" "}
                  to submit a ticket and assign to Zoom Support group or contact
                  the&nbsp;
                  <a target="_blank" href="https://goto/itservices" rel="noreferrer">
                    Help Desk
                  </a>
                  .
                </div>
                <div className="em-c-alert__actions">
                  <button
                    type="button"
                    className="em-c-text-btn em-c-alert--warning"
                    onClick={() => setShowCouldNotFoundOnAzureAD(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          <div>
            {/* Show Error Zoom Phone Site Not Enabled */}

            {usersInNotAllowSite.map((user) => {
              return (
                <div id="error-get-phone-msg">
                  <div className="em-c-alert em-c-alert--warning">
                    <div className="em-c-alert__body">
                      Sorry the user {user.userEmail} is in "
                      {user.userSiteErrorNotAllow}" phone location, that has not
                      yet implemented external calling. You cannot request a
                      zoom phone for this user at this time. If the phone
                      location is incorrect, have the user go to the{" "}
                      <Link target="_blank" to="/phone-move-form">
                        Zoom phone location update form
                      </Link>{" "}
                      to change their location. You cannot request a location
                      change on behalf of others
                    </div>
                    <div className="em-c-alert__actions">
                      <button
                        type="button"
                        className="em-c-text-btn em-c-alert--warning"
                        onClick={() => removeUserFromUsersInNotAllowSite(user)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* Show Error Zoom Phone Site Not Enabled */}
            {userAlreadyHaveAPhone && (
              <div id="error-get-phone-msg">
                <div className="em-c-alert em-c-alert--warning">
                  <div className="em-c-alert__body">
                    This user already has a zoom phone number, you cannot
                    request a new one. If you still need another number please
                    &nbsp;
                    <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
                      goto/GenericSR
                    </a>
                    &nbsp; to submit a ticket and assign to Zoom Support group
                    or contact the&nbsp;
                    <a target="_blank" href="https://goto/itservices" rel="noreferrer">
                      Help Desk
                    </a>
                    .
                  </div>
                  <div className="em-c-alert__actions">
                    <button
                      type="button"
                      className="em-c-text-btn em-c-alert--warning"
                      onClick={() => {
                        setUserAlreadyHaveAPhone(false)
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Show Error Phone Account Not Found */}
            {showErrorPhoneAccountNotFound &&
              errorPhoneAccount.message === "Not Found" && (
                <div id="warning-get-phone-msg">
                  <div className="em-c-alert em-c-alert--error ">
                    <div className="em-c-alert__body">
                      This user is not yet set up in Zoom. Please try again
                      tomorrow.
                    </div>
                    <div className="em-c-alert__actions">
                      <button
                        type="button"
                        className="em-c-text-btn em-c-alert--error "
                        onClick={() => setShowErrorPhoneAccountNotFound(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}

            {/* Show Error try to search an zoom phone account*/}
            {errorPhoneAccount &&
              errorPhoneAccount.message !== "Not Found" &&
              showErrorPhoneAccountNotFound && (
                <div id="warning-get-phone-msg">
                  <div className="em-c-alert em-c-alert--error ">
                    <div className="em-c-alert__body">
                      There is an error. Please try again. If not resolved,
                      please &nbsp;{" "}
                      <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
                        goto/GenericSR
                      </a>{" "}
                      to submit a ticket and assign to Zoom Support group or
                      contact the &nbsp;{" "}
                      <a target="_blank" href="https://goto/itservices" rel="noreferrer">
                        Help Desk
                      </a>
                      . <br></br>
                      {errorPhoneAccount.message}
                    </div>
                    <div className="em-c-alert__actions">
                      <button
                        type="button"
                        className="em-c-text-btn em-c-alert--error "
                        onClick={() => setShowErrorPhoneAccountNotFound(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}

            {/* Fail get site locations */}
            {(zoomSitesEmergencyAddressFail || onboardedSitesError) && (
              <div className="em-c-alert em-c-alert--error">
                <div className="em-c-alert__body">
                  There is an error. Please try again. If not resolved, please
                  &nbsp;
                  <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
                    goto/GenericSR
                  </a>{" "}
                  to submit a ticket and assign to Zoom Support group or contact
                  the Help Desk.. <br></br>
                  {zoomSitesLocationsError.message}
                </div>
                <div className="em-c-alert__actions">
                  <button
                    className="em-c-text-btn"
                    onClick={() => setZoomSitesEmergencyAddressFail(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}

            {graphAccessTokenFail && (
              <div className="em-c-alert em-c-alert--error">
                <div className="em-c-alert__body">
                  There is an error. Please try again. If not resolved, please
                  &nbsp;
                  <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
                    goto/GenericSR
                  </a>{" "}
                  to submit a ticket and assign to Zoom Support group or contact
                  the Help Desk.. <br></br>
                  {graphAccessTokenError.message}
                </div>
                <div className="em-c-alert__actions">
                  <button
                    className="em-c-text-btn"
                    onClick={() => setGraphAccessTokenFail(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}

            {bulkUsersGetNumber.length > 0 &&
              showVerifyLocation &&
              !triggerSubmitPhoneRequest && (
                <div id="error-get-phone-msg">
                  <div className="em-c-alert">
                    <div className="em-c-alert__body">
                      Hover your cursor over the user information below and click
                      Update Zoom Location if the physical location isn't correct.
                    </div>
                    <div className="em-c-alert__actions">
                      <button
                        type="button"
                        className="em-c-text-btn em-c-alert"
                        onClick={() => setShowVerifyLocation(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}

            {bulkUsersGetNumber.length > 0 && (
              <div className="PhoneRequestForm-getnumber-list">
                <div className="">
                  <label>Requested for list:</label>
                  {bulkUsersGetNumber.map((email, index) => (
                    <PhoneUserCard
                      key={index}
                      zoomSitesEmergencyAddress={zoomSitesEmergencyAddressResultData}
                      zoomSitesEmergencyAddressLoading={zoomSitesEmergencyAddressLoading}
                      zoomSitesEmergencyAddressFail={zoomSitesEmergencyAddressFail}
                      phoneSitesOnboarded={onboardedSitesResponse}
                      
                      userEmail={email}
                      callBackRemoveUserFromBulkGetNumber={
                        removeUserFromBulkGetNumber
                      }
                      callBackErrorPhoneAccountNotFound={
                        HandleErrorPhoneAccountNotFound
                      }
                      callBackErrorZoomPhoneNotEnabled={
                        HandleErrorZoomPhoneNotEnabled
                      }
                      callBackErrorUserAlreadyHaveAPhone={
                        HandleUserAlreadyHaveAPhone
                      }
                      triggerSubmitPhoneRequest={triggerSubmitPhoneRequest}
                      setIsLoading={setIsLoading}
                      isLoading={isLoading}
                      setIsShowingPopUp={setIsShowingPopUp}
                      isShowingPopUp={isShowingPopUp}
                      getState={getStateMoveSuccessPopUp}
                    />
                  ))}
                </div>
                <div></div>

                {!triggerSubmitPhoneRequest ? (
                  <div>
                    <div className="btn-row-section">
                      <div id="btn-get-new-number2">
                        <button
                          className="btn btn-medium"
                          type="button"
                          onClick={() => {
                            setPhoneNumberRequestConfirmation(true);
                          }}
                          disabled={
                            isLoading ||
                            isShowingPopUp ||
                            userAlreadyHaveAPhone ||
                            checkIFThereIsAnyMoveSuccessPopUpOpen()
                          }
                        >
                          Get Number
                        </button>
                      </div>

                      <button
                        id="clear-list"
                        className="btn btn-medium"
                        type="button"
                        onClick={clearBulkGetNumber}
                        disabled={
                          isLoading ||
                          isShowingPopUp ||
                          checkIFThereIsAnyMoveSuccessPopUpOpen()
                        }
                      >
                        Clear List
                      </button>
                    </div>
                    {checkIFThereIsAnyMoveSuccessPopUpOpen() && (
                      <div
                        id="warning-disable-button"
                        className="em-c-alert em-c-alert--warning"
                      >
                        <div className="em-c-alert__body">
                          Close the update location message(s) above to enable
                          the Get Number button.
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="btn-row-section">
                    <div id="btn-get-new-number3">
                      <button
                        className="btn btn-medium"
                        type="button"
                        onClick={handleNewRequest}
                        disabled={isLoading || isShowingPopUp}
                      >
                        New Request
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div id="technical-issues">
          <p>
            For any other items or technical issues{" "}
            <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
              goto/GenericSR
            </a>{" "}
            to submit a ticket and assign to Zoom Support group or contact the{" "}
            <a target="_blank" href="https://goto/itservices" rel="noreferrer">
              Help Desk
            </a>
            .
          </p>
        </div>
      </div>

      {phoneNumberRequestConfirmation && (
        <PopUp
          title="Get New Zoom Phone Number"
          message={`Are you sure you want a new Zoom phone number"?`}
          showPopUp={phoneNumberRequestConfirmation}
          setShowPopUp={setPhoneNumberRequestConfirmation}
          cancelCallback={() => setPhoneNumberRequestConfirmation(false)}
          submitForm={() => setTriggerSubmitPhoneRequest(true)}
        ></PopUp>
      )}
    </PhoneRequestFormWrapper>
  );
};

export default PhoneRequestForm;