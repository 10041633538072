import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { customAPIConfig, graphConfig } from "../AppConfig";
import useWebAPIToken from "../Hooks/useWebAPIToken";
import useAccessToken from "../Hooks/useAccessToken"


export function useFetch(url = null, isBackendCall = true){
  
  const backendAccessToken  = useWebAPIToken();
  const graphAccessToken  = useAccessToken();

  const accessToken = isBackendCall ? backendAccessToken : graphAccessToken;

  const baseURL = isBackendCall ? customAPIConfig.customAPIBaseEndpoint : graphConfig.graphBaseEndpoint;

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  

  useEffect(()=>{
    if(accessToken){
      const api =  axios.create({
        baseURL: `${baseURL}`,
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      });
  
      if(url){
        setIsFetching(true);
        api.get(url)
          .then(response => {
            setData(response.data);
          })
          .catch(err => {
            setError(err);
          })
          .finally(()=> {
            setIsFetching(false);
          })
      }
      else{
        setIsFetching(false);
      }
    }
  },[accessToken, url])


  return { data, error, isFetching, setData }
}
