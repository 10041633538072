import React from "react";
import { useEffect, useState } from "react";
import "./ZoomOnboardSiteForm.css";
import { Link } from "react-router-dom";

import AutoComplete from "../../Components/AutoComplete/AutoComplete";

import { useFetch } from "../../Hooks/useFetch";
import useWebAPIToken from "../../Hooks/useWebAPIToken";
import OnboardSiteRequest from "../../Hooks/funcOnboardSiteRequest";
import GetPhoneSitesOnboarded from "../../Hooks/funcGetPhoneSitesOnboarded";

import Table from "../../Components/Table";
import LoadingScreen_V2 from "../../Components/LoadingScreen_V2";
import PopUp from "../../Components/Popup";


export const ZoomOnboardSiteForm = () => {

  //#region vars and setters
  const [inputStatePhoneSite, setInputStatePhoneSite] = useState("");
  const [siteSelected, setSiteSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [onboardSiteRequestConfirmation, setOnboardSiteRequestConfirmation] = useState(false);
  const [triggerSubmitPhoneRequest, setTriggerSubmitPhoneRequest] = useState(false);
  const [onboardSiteRequestLoading, setOnboardSiteRequestLoading] = useState(false);
  const [onboardSiteRequestError, setOnboardSiteRequestError] = useState(false);


  const [onboardSiteRequestResponse, setOnboardSiteRequestResponse] = useState(null);
  const [onboardSiteRequestSuccess, setOnboardSiteRequestSuccess] = useState(false);

  const [onboardedSitesLoading, setOnboardedSitesLoading] = useState(false);
  const [onboardedSitesResponse, setOnboardedSitesResponse] = useState(null);
  const [onboardedSitesError, setOnboardedSitesError] = useState(null);


  
  
  
  // const {
  //   data: onboardedSitesResponse = null,
  //   error: onboardedSitesError = null,
  //   isFetching: onboardedSitesLoading = null,
  // } = useFetch(`/PhoneSiteOnboard/GetPhoneSitesOnboard`);

  const {
    data: sitesAddress = null,
    error: errorSitesAddress = null,
    isFetching: loadingSitesAddress = null,
  } = useFetch(`/PhoneRequest/GetEmergencyAddressList`);

    // TOKEN FOR CUSTOM WEB API
    const webApiToken = useWebAPIToken();

    //#endregion vars and setters

  //#region Functions
    const matchAddressSite = (address, sitesOnboarded) => {
      const sitesOnboardedIds = sitesOnboarded.map(site => site.siteId);
      
      return address.filter(element => sitesOnboardedIds.includes(element.siteId));
    };

    const sitesNotOnboarded = (address, sitesOnboarded) => {
      const sitesOnboardedIds = sitesOnboarded.map(site => site.siteId);
      
      return address.filter(element => !sitesOnboardedIds.includes(element.siteId));
    };

    const hanbleOnboardSiteRequest = async () => {
      if (webApiToken) {
        setOnboardSiteRequestLoading(true);
        try {
          let formData = new FormData();
          formData.append("siteId", siteSelected.siteId);
          formData.append("siteName", siteSelected.siteName);

          const [onboardResponse] = await Promise.all([
            OnboardSiteRequest(webApiToken, formData),
          ]);
          setOnboardSiteRequestSuccess(true);
          setOnboardSiteRequestResponse(onboardResponse);

        } catch (err) {
          setOnboardSiteRequestError(err);
        } finally {
          setOnboardSiteRequestLoading(false);
        }
      }
    };



    const handleGetPhoneSitesOnboarded = async () => {
      if (webApiToken) {
        setOnboardedSitesLoading(true);
        try {
          const [phoneSitesOnboardedResponse] = await Promise.all([
            GetPhoneSitesOnboarded(webApiToken),
          ]);
          setOnboardedSitesResponse(phoneSitesOnboardedResponse.data);
          setOnboardedSitesLoading(false);
        } catch (err) {
          setOnboardedSitesError(err);
        } finally {
          setOnboardedSitesLoading(false);
        }
      }
    };

    const handleNewRequest = () => {
      window.location.reload();

    }


  //#endregion Functions

  //#region useEffect

  // CUSTOM FUNCTION TO UPDATE isLoading
  // isLoading IS A STATE THAT REPRESENTS IF THERE IS ANY REQUESTION LOADING
  useEffect(() => {
    setIsLoading(loadingSitesAddress || onboardedSitesLoading || onboardSiteRequestLoading);
  }, [loadingSitesAddress, onboardedSitesLoading, onboardSiteRequestLoading]);

  useEffect(() => {
    if(triggerSubmitPhoneRequest){
      hanbleOnboardSiteRequest()
    }
  }, [triggerSubmitPhoneRequest]);

  useEffect(()=> {
    handleGetPhoneSitesOnboarded();
  }, [webApiToken, onboardSiteRequestResponse])

  //#endregion useEffect

  return (
    <div className="ZoomOnboardSiteForm-container"><label htmlFor=""></label>


    <div className="ZoomOnboardSiteForm-header">
      <h1>Zoom Onboard Phone Site Form</h1>
        <div className="App-horizontal-line" />
        <br />
        <p>
          This form is used for the Zoom support team to onboard new Zoom phone sites.
        </p>
        <p>
          You can verify your request was submitted by viewing the{" "}
          <Link target="_blank" to="/phone-request-logs">
            Submitted Zoom Phone Requests page.
          </Link>
        </p>

    </div>



    { isLoading && <LoadingScreen_V2 loadingMessage="Loading Zoom information."></LoadingScreen_V2> }
      <div
        className={isLoading ? "loading" : ""}
      >



        {/* Show Error Zoom Phone Site Not Enabled */}
        {(onboardedSitesError || errorSitesAddress) && (
          <div className="App-em-c-alert App-em-c-alert--warning">
            <div className="App-em-c-alert__body">
              There is an error. Please try again.
            </div>
          </div>
        )}

        {onboardedSitesResponse && sitesAddress && (

          <div>



            <Table
              data={matchAddressSite(sitesAddress, onboardedSitesResponse)?.map((item) => {
                return {
                  "Site Name": item.siteName,
                  "Country": item.country,
                  "Address": item.addressLine1,
                  "City": item.city,
                  "State Code": item.stateCode,
                  "Postal code": item.postalCode,
                };
              })}
            />


            <div className="App-horizontal-line" />


            {/* Show Error Zoom Phone Site Not Enabled */}
            {onboardSiteRequestError && onboardSiteRequestError.message !== 404 && (
              <div className="App-em-c-alert App-em-c-alert--warning">
                <div className="App-em-c-alert__body">
                  There is an error. The site has not been onboarded. Please try again.
                </div>
              </div>
            )}

            {/* Show Error Zoom Phone Site Not Enabled due not available numbers */}
            {onboardSiteRequestError.message === 404 && (
              <div className="App-em-c-alert App-em-c-alert--warning">
                <div className="App-em-c-alert__body">
                  The site has not been onboarded because there aren't available numbers. 
                </div>
              </div>
            )}


            {onboardSiteRequestSuccess && (
              <div className="App-em-c-alert App-em-c-alert--success">
                <div className="App-em-c-alert__body">
                  Congratulations! The phone site "{siteSelected.siteName}" has been successfully enabled.&nbsp;{" "}
                  <Link target="_blank" to="/phone-request-logs">
                    Click here
                  </Link>{" "}
                  to verify your request was submitted.
                </div>
                <div className="App-em-c-alert__actions">
                  <button
                    className="App-em-c-text-btn"
                    onClick={() => setOnboardSiteRequestSuccess(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}


            <AutoComplete
              label="Select a Zoom phone site"
              inputValue={inputStatePhoneSite}
              setInputValue={setInputStatePhoneSite}
              note="Select the Zoom phone site that you would like to onboard. 
              This list not contains the onboarded sites above"
              listOfObjects={sitesNotOnboarded(sitesAddress, onboardedSitesResponse)}
              optionKeyValues={['siteName']}
              returnSelectedObj={setSiteSelected}
              toolTipMessage="Select one site"
              disableValidation
            >
            </AutoComplete>

            {!onboardSiteRequestResponse && !onboardSiteRequestError
              ? (     
                <button
                  className="App-btn App-btn-medium"
                  type="button"
                  onClick={() => {
                    setOnboardSiteRequestConfirmation(true);
                  }}
                  disabled={ (!inputStatePhoneSite || isLoading)}
                >
                  Onboard site
                </button>
              )
              : (
                <button
                  className="App-btn App-btn-medium"
                  type="button"
                  onClick={() => {
                    handleNewRequest();
                  }}
                  disabled={ (!inputStatePhoneSite || isLoading)}
                >
                  New request
                </button>
              )
            }


          </div>


        )}

        
      </div>

      {onboardSiteRequestConfirmation && (
        <PopUp
          title="Onboard a new Zoom phone site into phone request"
          message={`Are you sure you want to onboard the site "${siteSelected.siteName}", users
           from this site will be able to request Zoom phone numbers"?`}
          showPopUp={onboardSiteRequestConfirmation}
          setShowPopUp={setOnboardSiteRequestConfirmation}
          cancelCallback={() => setOnboardSiteRequestConfirmation(false)}
          submitForm={() => setTriggerSubmitPhoneRequest(true)}
        ></PopUp>
      )}


    </div>
  );
}

export default ZoomOnboardSiteForm;
