import { useState, useEffect } from "react";
import { PhoneLocationCardWrapper } from "./PhoneUserForeignDIDCard.css.js";
import { SVGXFilled } from "../../Assets/SVGTiles.js";

//api tokens
import useWebAPIToken from "../../Hooks/useWebAPIToken.js";

//api calls
import PostForeignNumberRequest from "../../Hooks/funcForeignNumberRequest.js";
import PostDualDIDRequest from "../../Hooks/funcDualDIDRequest.js";


//Components
import LoadingZoomPhoneRequest from "../LoadingZoomPhoneRequest.js";

const PhoneUserForeignDIDCard = ({
  setPhoneNumberRequestLoading,
  phoneNumberRequestLoading,
  userPhoneAccount,
  setUserPhoneAccount,
  userSupervisor,
  isDualDID,
  zoomSitesEmergencyAddress,

  phoneNumberRequestSuccess,
  setPhoneNumberRequestSuccess,
  
  callBackRemoveUserGetNumber,
  

  triggerSubmitPhoneRequest,
  siteIdForeignDID,

  businessJustificationInput,
  ...rest
}) => {
  //#region const state

  // zoom phone number request
  const [phoneNumberRequestError, setPhoneNumberRequestError]                 = useState(null);
  const [phoneNumberRequestFail, setPhoneNumberRequestFail]                   = useState(false);
  const [userSiteLocation, setUserSiteLocation]                               = useState(null);

  // SETTING LOCAL CONSTANT FOR THE COMPONENT
  // 1. TOKEN FOR CUSTOM WEB API
  const webApiToken = useWebAPIToken();

  //#endregion const state

  //#region useEffect

  // CHECK IF THE USER CLICK ON THE BUTTON GET NUMBER, IF IT IS CALL THE FUNCTION handlePhoneNumberRequest
  useEffect(() => {
    if (triggerSubmitPhoneRequest) {
      handlePhoneNumberRequest();
    }
  }, [triggerSubmitPhoneRequest]);


  useEffect(() => {
    if (zoomSitesEmergencyAddress.length > 0 && userPhoneAccount?.email) 
    {
      let userEmergencyAddressSite = zoomSitesEmergencyAddress.find((location) => location.siteId === userPhoneAccount.siteId);

      if (userEmergencyAddressSite) {
        setUserSiteLocation(userEmergencyAddressSite);
      }
    }
    // value={   }
  }, [zoomSitesEmergencyAddress, userPhoneAccount]);


  //#region functions

  // Phone Number Request
  const handlePhoneNumberRequest = async () => {
    if (webApiToken) {
      setPhoneNumberRequestLoading(true);
      try {
        let formData = new FormData();
        formData.append("siteId", siteIdForeignDID);
        formData.append("userId", userPhoneAccount.email);
        formData.append("businessJustification", businessJustificationInput);
        formData.append("userSupervisor", userSupervisor);

        const [phoneNumberRequestResponse] = await Promise.all([
          isDualDID 
            ? PostDualDIDRequest(webApiToken, formData)
            : PostForeignNumberRequest(webApiToken, formData)
        ]);

        setPhoneNumberRequestSuccess(true);
      } catch (err) {
        setPhoneNumberRequestError(err.message);
        setPhoneNumberRequestFail(true);
      } finally {
        setPhoneNumberRequestLoading(false);
      }
    }
  };


  //#endregion functions

  return (
    <PhoneLocationCardWrapper>
      {phoneNumberRequestFail && phoneNumberRequestError === 404 && (
        <div id="error-get-phone-msg">
          <div className="PhoneUserForeignDIDCard-em-c-alert PhoneUserForeignDIDCard-em-c-alert--error">
            <div className="PhoneUserForeignDIDCard-em-c-alert__body">
              Unfortunately we cannot assign a number at this time. <br></br>
              An email has been triggered to the Support team. Someone will handle
              your request soon.
            </div>
            <div className="PhoneUserForeignDIDCard-em-c-alert__actions">
              <button
                type="button"
                className="PhoneUserForeignDIDCard-em-c-text-btn PhoneUserForeignDIDCard-em-c-alert--error"
                onClick={() => setPhoneNumberRequestFail(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {phoneNumberRequestFail && phoneNumberRequestError !== 404 && (
        <div className="PhoneUserForeignDIDCard-em-c-alert PhoneUserForeignDIDCard-em-c-alert--error">
          <div className="PhoneUserForeignDIDCard-em-c-alert__body">
            There is an error. Please try again. If not resolved, please &nbsp;{" "}
            <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
              goto/GenericSR
            </a>{" "}
            to submit a ticket and assign to Zoom Support group or contact the
            &nbsp;{" "}
            <a target="_blank" href="https://goto/itservices" rel="noreferrer">
              Help Desk
            </a>
            . <br></br>
            {phoneNumberRequestError}
          </div>
          <div className="PhoneUserForeignDIDCard-em-c-alert__actions">
            <button
              type="button"
              className="PhoneUserForeignDIDCard-em-c-text-btn PhoneUserForeignDIDCard-em-c-alert--error"
              onClick={() => setPhoneNumberRequestFail(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <div className="em-c-card">
        <div className="em-c-card__body">
          <div className="em-c-media-block__body">
            <span className="em-c-media-block__headline fn">
              {userPhoneAccount.email}
              <br></br>
            </span>
              {userPhoneAccount?.phoneNumbers?.length > 0 
                && (
                  <span className="PhoneUserForeignDIDCard-PhoneNumbers">
                    <b>
                      Zoom Phone: {userPhoneAccount.phoneNumbers[0].number}
                    </b>
                  </span>
                )}
          </div>

          <div className="em-c-card__footer">
            {!phoneNumberRequestLoading ? (
              <div>
                <span className="card_headline">Zoom Phone Location</span>

                {userSiteLocation && (
                  <ul className="em-c-link-list em-c-link-list--small em-c-link-list--has-icons">
                    <li
                      className="em-c-link-list__item"
                      key={userSiteLocation.country}
                    >
                      <span className="em-c-link-list__text">
                        Country: {userSiteLocation.country}
                      </span>
                    </li>
                    <li
                      className="em-c-link-list__item"
                      key={userSiteLocation.stateCode}
                    >
                      <span className="em-c-link-list__text">
                        State: {userSiteLocation.stateCode}
                      </span>
                    </li>
                    <li
                      className="em-c-link-list__item"
                      key={userSiteLocation.city}
                    >
                      <span className="em-c-link-list__text">
                        City: {userSiteLocation.city}
                      </span>
                    </li>
                    <li
                      className="em-c-link-list__item"
                      key={userSiteLocation.siteName}
                    >
                      <span className="em-c-link-list__text">
                        Zoom Phone Site Name: {userSiteLocation.siteName}
                      </span>
                    </li>
                  </ul>
                )}

                <button
                    type="button"
                    className="PhoneUserForeignDIDCard-remove-user-btn"
                    onClick={() =>
                      callBackRemoveUserGetNumber()
                    }
                  >
                    <SVGXFilled specificSize="20" />
                  </button>

              </div>
            ) : (
              <div className="PhoneUser-circle-loading">
                {phoneNumberRequestLoading && (
                  <LoadingZoomPhoneRequest
                    loadingMessage={"Loading Zoom phone number request."}
                    size="40px"
                    color="#007bff"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </PhoneLocationCardWrapper>
  );
};

export default PhoneUserForeignDIDCard;
