import API, { httpStatus, getError } from "./api";
import InternalServerError from "./Errors/InternalServer";

const PatchPhoneNumberRelease = async (accessToken, formObj) => {

  try {
    const api = await API(accessToken);
    return await api.patch(`/PhoneRequest/UnassignPhoneNumber`, formObj);
  } catch (err) {
    if (err.response) {
      if (err.response.status === httpStatus.InternalServerError) {
        throw new InternalServerError();
      }
    }
    throw getError(err);
  }
};

export default PatchPhoneNumberRelease;
