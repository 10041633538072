import { ZoomCountriesAbbreviationList }  from "../Data/ZoomCountriesAbbreviationList"  
import { ZoomStatesAbbreviationList }  from "../Data/ZoomStatesAbbreviationList"  

const findCountryNameByCode = (countryCode) => {
  let countryObj =  ZoomCountriesAbbreviationList.find( country => country.ID === countryCode)
  if( countryObj ) {
    return countryObj.Name
  }
  return countryCode
}

const findStateNameByCode = (stateCode, country) => {
  let stateObj;
  if(country === "US"){
    stateObj =  ZoomStatesAbbreviationList.find( state => state.ID === stateCode)
  }

  if( stateObj ) {
    return stateObj.Name
  }
  return stateCode
}

export const uniqueEmergencyCountryList = (EmergencyAddressesList) => EmergencyAddressesList.reduce((accumulator, currentVal) =>
  accumulator.concat(accumulator.find(y => y.country === currentVal.country) ? [] : 
  [
    {
      "country" : currentVal.country,
      "countryName" : findCountryNameByCode(currentVal.country)

    }
  ])
  , []);

export const uniqueEmergencyStateList = (EmergencyAddressesList, selectedCountry) => EmergencyAddressesList.reduce((accumulator, currentVal) =>
accumulator.concat(accumulator.find(y => y.stateCode === currentVal.stateCode) ? [] : 
  [
    {
      "country" : currentVal.country, 
      "stateCode" : currentVal.stateCode && currentVal.stateCode.length > 0 ? currentVal.stateCode : "" ,
      "stateName" : findStateNameByCode(currentVal.stateCode, currentVal.country)
    }
  ])
, []).filter(location => location.country === selectedCountry && location.stateCode.length > 0);



export const uniqueEmergencyCityList = (EmergencyAddressesList, selectedStateCode) => EmergencyAddressesList.reduce((accumulator, currentVal) =>
accumulator.concat(accumulator.find(y => y.city === currentVal.city) ? [] : 
  [
    {
      "country" : currentVal.country, 
      "stateCode" : currentVal.stateCode, 
      "city" : currentVal.city
    }
  ])
, []).filter(location => location.stateCode === selectedStateCode);


export const uniqueEmergencyCityListByCountry = (EmergencyAddressesList, selectedCountry) => EmergencyAddressesList.reduce((accumulator, currentVal) =>
accumulator.concat(accumulator.find(y => y.city === currentVal.city) ? [] : 
  [
    {
      "country" : currentVal.country, 
      "city" : currentVal.city
    }
  ])
, []).filter(location => location.country === selectedCountry);

export const uniqueEmergencyAddressList = (EmergencyAddressesList, selectedCity) => EmergencyAddressesList.reduce((accumulator, currentVal) =>
accumulator.concat(accumulator.find(y => y.addressLine1 === currentVal.addressLine1) ? [] : 
  [
    {
      "country" : currentVal.country, 
      "stateCode" : currentVal.stateCode, 
      "city" : currentVal.city,
      "siteId" : currentVal.siteId,
      "addressLine1" : currentVal.addressLine1,
      "siteName" : currentVal.siteName
    }
  ])
  , []).filter(location => location.city === selectedCity);

  export const uniqueEmergencyAddressListByState = (EmergencyAddressesList, selectedState) => EmergencyAddressesList.reduce((accumulator, currentVal) =>
accumulator.concat(accumulator.find(y => y.addressLine1 === currentVal.addressLine1) ? [] : 
  [
    {
      "country" : currentVal.country, 
      "stateCode" : currentVal.stateCode, 
      "siteId" : currentVal.siteId,
      "addressLine1" : currentVal.addressLine1,
      "siteName" : currentVal.siteName
    }
  ])
  , []).filter(location => location.stateCode === selectedState);

  export const uniqueEmergencyAddressListByCountry = (EmergencyAddressesList, selectedCountry) => EmergencyAddressesList.reduce((accumulator, currentVal) =>
  accumulator.concat(accumulator.find(y => y.addressLine1 === currentVal.addressLine1) ? [] : 
    [
      {
        "country" : currentVal.country, 
        "siteId" : currentVal.siteId,
        "addressLine1" : currentVal.addressLine1,
        "siteName" : currentVal.siteName
      }
    ])
    , []).filter(location => location.country === selectedCountry);


  export const uniqueEmergencyPhoneSiteList = (EmergencyAddressesList, selectedAddress) => EmergencyAddressesList.reduce((accumulator, currentVal) =>
  accumulator.concat(accumulator.find(y => y.addressLine1 === currentVal.addressLine1) ? [] : 
    [
      {
        "country" : currentVal.country, 
        "stateCode" : currentVal.stateCode, 
        "city" : currentVal.city,
        "siteId" : currentVal.siteId,
        "addressLine1" : currentVal.addressLine1,
        "siteName" : currentVal.siteName
      }
    ])
    , []).filter(location => location.addressLine1 === selectedAddress);
