import API, { httpStatus, getError } from "./api";
import InternalServerError from "./Errors/InternalServer";

const IDMConfirmRequest = async (accessToken, formObj) => {

  try {
    const api = await API(accessToken);
    return await api.post(`/PhoneHarvest/IdmConfirm`, formObj);
  } catch (err) {
    if (err.response) {
      if (err.response.status === httpStatus.InternalServerError) {
        throw new InternalServerError();
      }
    }
    throw getError(err);
  }
};

export { IDMConfirmRequest } ;
