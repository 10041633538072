import { useState, useEffect} from "react";
import { useParams } from 'react-router-dom';

import PopUp from "../../Components/Popup";
import LoadingScreen_V2 from "../../Components/LoadingScreen_V2";
import Input from "../../Components/Input";
import RequestedBy from "../../Components/RequestedBy/RequestedBy";

//api calls
import useWebAPIToken from "../../Hooks/useWebAPIToken";
import { ReturnRequest, KeepRequest } from "../../Hooks/funcHarvestRequests";
import useAccessToken from "../../Hooks/useAccessToken";

import { useFetch } from "../../Hooks/useFetch";

import "./PhoneHarvest.css";
/////////////////////////MAIN COMPONENT/////////////////////////////////

const PhoneHarvest = () => {
  //#region vars and setters

  const params = useParams();
    
    const [keepRequestConfirmation, setKeepRequestConfirmation] = useState(false);
    const [returnRequestConfirmation, setReturnRequestConfirmation] = useState(false);

    const [successfullyKept, setSuccessfullyKept] = useState(false);
    const [isKeepRequestLoading, setIsKeepRequestLoading] = useState(false);
    const [isFailToKeepRequest, setIsFailToKeepRequest] = useState(false);

    const [successfullyReturned, setSuccessfullyReturned] = useState(false);
    const [isReturnRequestLoading, setIsReturnRequestLoading] = useState(false);
    const [isFailToReturnRequest, setIsFailToReturnRequest] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(false);

    
    // SETTING LOCAL STATE FOR THE COMPONENT
    // 1. requestedByEmail EMAIL
    const [requestedByEmail , setRequestedByEmail] = useState("");
    // 2. GENERATES TOKEN FOR THE GRAPH API
    const accessToken = useAccessToken();
    // 3. TOKEN FOR CUSTOM WEB API
    const webApiToken = useWebAPIToken();

    const { data: harvestInfo = null, error: errorHarvestInfo = null, isFetching: loadingHarvestInfo = null } = useFetch(`/PhoneHarvest/GetLastPhoneCall?id=${params.requestId}`);

    
  //#endregion vars and setters

  //#region Functions
    
    // keep request
    const handleKeepRequest = async () => {
      if (webApiToken) {
        setIsKeepRequestLoading(true);
        setIsFailToKeepRequest(false);
        try {
          let formData = new FormData();
          formData.append("requestId", params.requestId)
          const [keepRequestResponse] = await Promise.all([
            KeepRequest(webApiToken, formData),
          ]);

          setSuccessfullyKept(true);

        } catch (err) {
          setIsFailToKeepRequest(true);
        }
        finally{
          setIsKeepRequestLoading(false)
        }
      }
    }

    const handleReturnRequest = async () => {
      if (webApiToken) {
        setIsReturnRequestLoading(true);
        setIsFailToReturnRequest(false);
        try {
          let formData = new FormData();
          formData.append("requestId", params.requestId)
          const [returnRequestResponse] = await Promise.all([
            ReturnRequest(webApiToken, formData),
          ]);
          setSuccessfullyReturned(true);

        } catch (err) {
         setIsFailToReturnRequest(true);
        }
        finally{
          setIsReturnRequestLoading(false)
        }
      }
    }


    
  //#endregion Functions

  //#region useEffect
  

    useEffect(()=>{
      setIsLoading(loadingHarvestInfo || isReturnRequestLoading || isKeepRequestLoading);

      if(loadingHarvestInfo){
        setLoadingMessage("Loading Zoom information");
      }
      else{
        if(isReturnRequestLoading){
          setLoadingMessage("Processing return request.");
        }
        else {
          setLoadingMessage("Processing keep request.");
        }
      } 
    },[loadingHarvestInfo, isReturnRequestLoading, isKeepRequestLoading])
    
  //#endregion useEffect

  const isToShowRequestError = (isFailToKeepRequest || isFailToReturnRequest);
  
  const isUserAlreadyRelease = errorHarvestInfo?.response.status === 404;
  
  // JSX CODE FOR MAIN COMPONENT
  return (
    <div className="PhoneHarvest-container">
      <h1>Zoom Phone Harvesting Form</h1>
      <div className="PhoneHarvest-horizontal_line" />
      <div className="PhoneHarvest-description">
        <br />
          <p>
            Use this form to indicate if you would like to return or keep your Zoom Phone Pro License for external calling. 
          </p>
        <ul>
          <li>
            If you do not submit this form by the date stated on the email, the Zoom phone is automatically returned to the general pool for later use.
          </li>
          <li>
            Either the Zoom Pro License holder or their manager can complete this form and both will receive the automated confirmation email(s).
          </li>     
          <li>
            Should you need a Zoom Phone again in the future, <a target="_blank" href="https://goto/ZoomNumber">goto/ZoomNumber</a> and a new number 
            will be issued immediately. You will not receive your old number.
          </li>       
        </ul>
      </div>

      {isLoading && (<LoadingScreen_V2 loadingMessage={loadingMessage}></LoadingScreen_V2>)}
      
      <main className={ isLoading ? 'PhoneHarvest-loading' : ''}>

        { isUserAlreadyRelease 
          ? (
            <div className="App-em-c-alert App-em-c-alert--warning ">
                <div className="App-em-c-alert__body">
                  This number was previously kept/returned. No further action required.
                </div>
            </div>
          )
          :(
            <div>
              <RequestedBy setRequestedByEmail={setRequestedByEmail}/>

              {harvestInfo && (
                <div>
                  <Input
                    note="User email address."
                    label="Requested for"
                    type="text"
                    placeholder="user mail"
                    value={harvestInfo.userEmail}
                    disabled
                    toolTipMessage="Email of person who will be affected by the request."
                  />

                  <Input
                    note="Zoom Phone."
                    label="Zoom Phone"
                    type="text"
                    placeholder="Zoom phone"
                    value={harvestInfo.phoneNumber}
                    disabled
                    toolTipMessage="The Zoom Phone that will be affected by this request."
                  />

                  {/* <Input
                    note="Last phone call."
                    label="Last phone call"
                    type="text"
                    placeholder="Last phone call"
                    value={harvestInfo.dateTime}
                    disabled
                    toolTipMessage="Last phone call."
                  /> */}
                  
                </div>
              )}

              {/*Error get harvest log*/}
              { errorHarvestInfo && (
                //<div id="warning-get-phone-msg">
                <div className="App-em-c-alert App-em-c-alert--error ">
                  <div className="App-em-c-alert__body">
                    There is an error. Please try again. If not resolved, please <a target="_blank" 
                    href={"https://goto/GenericSR"}>goto/GenericSR</a> to submit a ticket and assign to Zoom Support group or contact the <a target="_blank" 
                    href="https://goto/itservices">Help Desk</a>. <br></br>
                  </div>
                </div>
              )} 

              {/*Error return/keep request*/}
              {  isToShowRequestError &&(
                //<div id="warning-get-phone-msg">
                <div className="App-em-c-alert App-em-c-alert--error ">
                <div className="App-em-c-alert__body">
                    There is an error. Please try again. If not resolved, please <a target="_blank" 
                    href={"https://goto/GenericSR"}>goto/GenericSR</a> to submit a ticket and assign to Zoom Support group or contact the <a target="_blank" 
                    href="https://goto/itservices">Help Desk</a>. <br></br>
                  </div>
                </div>
              )} 


              {successfullyReturned && (
                <div className="App-em-c-alert App-em-c-alert--success">
                  <div className="App-em-c-alert__body">
                    Return successful, no further action required.
                  </div>
                </div>
              )}

              {successfullyKept && (
                <div className="App-em-c-alert App-em-c-alert--success">
                  <div className="App-em-c-alert__body">
                  Keep successful, no further action required.
                  </div>
                </div>
              )}

              { !successfullyReturned && !successfullyKept && !errorHarvestInfo &&
                (
                  <div>
                    <button
                      id="btn-keep"
                      className="App-btn App-btn-large"
                      type="button"
                      onClick={() => { setKeepRequestConfirmation(true) }}
                      disabled={ isLoading }
                    >
                      Keep
                    </button>
                    <button
                      id="PhoneHarvest-btn-return"
                      className="App-btn App-btn-large"
                      type="button"
                      onClick={() => { setReturnRequestConfirmation(true) }}
                      disabled={ isLoading }
                    >
                      Return
                    </button>

                  </div>
                )
              }

              {keepRequestConfirmation && (
                <PopUp
                  title="Keep my Zoom Phone Number"
                  message= "Are you sure you want to KEEP your Zoom phone number?"
                  showPopUp={keepRequestConfirmation}
                  setShowPopUp={setKeepRequestConfirmation}
                  cancelCallback={() => setKeepRequestConfirmation(false)}
                  submitForm={() => handleKeepRequest()}
                >
                </PopUp>
              )}

              {returnRequestConfirmation && (
                <PopUp
                  title="Return my Zoom Phone Number"
                  message= "Are you sure you want to RETURN your Zoom phone number?"
                  showPopUp={returnRequestConfirmation}
                  setShowPopUp={setReturnRequestConfirmation}

                  cancelCallback={() => setReturnRequestConfirmation(false)}
                  submitForm={() => handleReturnRequest()}
                >
                </PopUp>
              )}
        

            </div>
          )}
      </main>


    
    
    </div>
  );

};

export default PhoneHarvest;