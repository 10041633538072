import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRequest } from "../AppConfig";

// HOOK USED TO RETRIEVE ACCESS TOKEN FOR ACCESS API's ON BEHALF OF THE USER
const useAccessToken = () => {
  // INITIALIZING LOCAL STATES AND MSAL STATES
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState(undefined);

  function RequestAccessToken() {
    // SETTING THE REQUEST DATA
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // SILENTLY ACQUIRES AN ACCESS TOKEN FROM SESSION STORAGE IF IT IS STILL VALID
    // IF TOKEN DOES NOT EXIST OR IS EXPIRED, REACH TO /token ENDPOINT IN MICROSOFT
    // AUTH SERVER AND REFRESH THE TOKEN
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken);
      })
      .catch((e) => {
        instance.acquireTokenRedirect(request).then((response) => {
          setAccessToken(response.accessToken);
        });
      });
  }

  // RUN THE FUNCTION RequestAccessToken ONLY IF AUTHENTICATION IS ALREADY DONE
  useEffect(() => {
    if (inProgress === "none") {
      RequestAccessToken();
    }
  }, [inProgress]);

  return accessToken;
};

export default useAccessToken;
