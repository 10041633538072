import { useState, useRef, useEffect } from "react"
import { PhoneMoveCardWrapper } from "./PhoneMoveCard.css.js"
import { useHistory } from "react-router";

//Zoom Lists
import { ZoomCountriesAbbreviationListWithIAMfixes } from "../../Assets/Data/ZoomCountriesAbbreviationListWithIAMfixes"
import { ZoomStatesAbbreviationList } from "../../Assets/Data/ZoomStatesAbbreviationList"

import {Link} from "react-router-dom";

//Locations Lists
import { 
  uniqueEmergencyStateList, 
  uniqueEmergencyCityList, 
  uniqueEmergencyAddressList, 
  uniqueEmergencyCityListByCountry,
  uniqueEmergencyAddressListByState,
  uniqueEmergencyAddressListByCountry
} from "../../Assets/Data/EmergencyAddressesList"

//tokens
import useAccessToken from "../../Hooks/useAccessToken";
import useWebAPIToken from "../../Hooks/useWebAPIToken";


//Api requests
import PostMoveUserSite from "../../Hooks/funcMoveUserSite";

//Components
import AutoComplete from '../AutoComplete/AutoComplete';
import Input from "../Input";
import PopUp from "../Popup";
import LoadingScreen_V2 from "../LoadingScreen_V2"


const PhoneMoveCard = (
  {
    userCountry,
    showChangeSite,
    setShowChangeSite,
    zoomSitesEmergencyAddress,
    zoomSitesLocationsLoading,
    phoneSitesOnboarded,
    user,
    setUser,
    isLoading,
    setIsLoading,
    setIsShowingPopUp,
    isShowingPopUp,
    setPopUpMoveSuccess,
    popUpMoveSuccess,
    ...rest
  }) =>{

  //#region const state

    // Move request
    const [moveUserError, setMoveUserError] = useState(null);
    const [moveRequestLoading, setMoveRequestLoading] = useState(false);
    const [moveUserConfirmationPopUp, setMoveUserConfirmationPopUp] = useState(false);
    const [inputStatesForChangeSite, setInputStatesForChangeSite] = useState({});
    const [triggeredMoveUser, setTriggeredMoveUser] = useState(false);
    const [showChangeSiteOptions, setShowChangeSiteOptions] = useState(true);
    const [moveFail, setMoveFail] = useState(false);
    
    // Input and select for change site
    const [inputCountryForChangeSite, setInputCountryForChangeSite] = useState("");
    const [selectedInputCountryForChangeSite, setSelectedInputCountryForChangeSite] = useState({});

    const [inputStateForChangeSite, setInputStateForChangeSite] = useState("");
    const [selectedInputStateForChangeSite, setSelectedInputStateForChangeSite] = useState({});
    const [statesOptions, setStatesOptions] = useState([]);

    const [inputCityForChangeSite, setInputCityForChangeSite] = useState("");
    const [selectedInputCityForChangeSite, setSelectedInputCityForChangeSite] = useState({});
    const [citiesOptions, setCitiesOptions] = useState([]);

    const [inputAddressForChangeSite, setInputAddressForChangeSite] = useState("");
    const [selectedInputAddressForChangeSite, setSelectedInputAddressForChangeSite] = useState({});
    const [addressesOptions, setAddressesOptions] = useState([]);

    //States use to check if the form has properly fill
    const [touchAllForChangeSite, setTouchAllForChangeSite] = useState("");
    
    const [sitesLocations, setSitesLocations] = useState([]);
    const [userSiteLocation, setUserSiteLocation] = useState(null);
    const [showWarningIDM, setShowWarningIDM] = useState(true);

    // const [showCouldNotLocation, setShowCouldNotLocation] = useState(true);
    // SETTING LOCAL CONSTANT FOR THE COMPONENT
    // 1. HISTORY TO REDIRECT PAGE
    const history = useHistory();
    // 2. GENERATES TOKEN FOR THE GRAPH API
    const accessToken = useAccessToken();
    // 3. TOKEN FOR CUSTOM WEB API
    const webApiToken = useWebAPIToken();

  //#endregion const state

  //#region useEffect

  useEffect(() => { 
    if(user?.siteId){
      setSitesLocations(zoomSitesEmergencyAddress.filter(site => !site.siteName.toLowerCase().includes('test'))
                                          .filter(site => !site.siteName.includes('00_'))
                                            .filter(site => site.siteId !== user.siteId))
    }
  },[zoomSitesEmergencyAddress, user])

  useEffect(()=>{
    if(zoomSitesEmergencyAddress.length > 0 && user?.email){
      let userSite = zoomSitesEmergencyAddress.find(location => location.siteId == user.siteId)
      
      if(userSite?.siteName){
        setUserSiteLocation(userSite)
      }
    }
    // value={   }

  },[zoomSitesEmergencyAddress, user])

    // CUSTOM FUNCTION TO UPDATE isShowingPopUp
    // isShowingPopUp IS A STATE THAT REPRESENTS IF THERE IS SHOWING ANY POPUP
    useEffect(() => {
      setIsShowingPopUp(moveUserConfirmationPopUp)
    },[ moveUserConfirmationPopUp])
    
    // CUSTOM FUNCTION TO UPDATE isLoading
    // isLoading IS A STATE THAT REPRESENTS IF THERE IS ANY REQUESTION LOADING
    useEffect(() => {
      setIsLoading(moveRequestLoading)
    },[ moveRequestLoading])

    // CUSTOM FUNCTION TO RESET STATE, CITY AND ADDRESS IF CHANGE THE COUNTRY
    useEffect(() => {
      if(inputCountryForChangeSite.length === 0){
        setSelectedInputCountryForChangeSite({})
      }

      setStatesOptions([]);
      setCitiesOptions([]);
      setAddressesOptions([]);


      setInputStateForChangeSite("");  
      setSelectedInputStateForChangeSite({});
      
      setInputCityForChangeSite("");
      setSelectedInputCityForChangeSite({});

      setInputAddressForChangeSite("");
      setSelectedInputAddressForChangeSite({});

    }, [inputCountryForChangeSite]);

    // CUSTOM FUNCTION TO RESET CITY AND ADDRESS IF CHANGE THE STATE
    useEffect(() => {
      if(inputStateForChangeSite.length === 0){
        setSelectedInputStateForChangeSite({})
      }

      setCitiesOptions([]);
      setAddressesOptions([]);

      setInputCityForChangeSite("");
      setSelectedInputCityForChangeSite({});

      setInputAddressForChangeSite("");
      setSelectedInputAddressForChangeSite({});

    }, [inputStateForChangeSite]);

    // CUSTOM FUNCTION TO RESET ADDRESS IF CHANGE THE CITY
    useEffect(() => {
      if(inputCityForChangeSite.length === 0){
        setSelectedInputCityForChangeSite({})
      }

      setAddressesOptions([]);

      setInputAddressForChangeSite("");
      setSelectedInputAddressForChangeSite({});

    }, [inputCityForChangeSite]);

    useEffect(() => {
      if(showChangeSite){
        setTriggeredMoveUser(false)
      }

    }, [showChangeSite]);
    
    useEffect(() => {
      let stateList;

      stateList = uniqueEmergencyStateList(sitesLocations, userCountry.ID);

      if(stateList.length > 0){
        setStatesOptions(stateList.filter(location => location?.stateCode.length > 0));
      }
      
    },[sitesLocations])

    useEffect(() => {
      if(statesOptions.length > 0){
        let cityList = uniqueEmergencyCityList(sitesLocations, selectedInputStateForChangeSite.stateCode);
        if(cityList){
          setCitiesOptions(cityList.filter(location => location.city && location.city.length > 0));
        }
      }
      else{
        if(selectedInputCountryForChangeSite.country && selectedInputCountryForChangeSite.country.length > 0){
          let cityList = uniqueEmergencyCityListByCountry(sitesLocations, selectedInputCountryForChangeSite.country);
          if(cityList){
            setCitiesOptions(cityList.filter(location => location.city && location.city.length > 0));
          }
        }
      }
    },[statesOptions, selectedInputStateForChangeSite])

    useEffect(() => {
      let addressList;
      if(citiesOptions.length > 0){
        addressList = uniqueEmergencyAddressList(sitesLocations, selectedInputCityForChangeSite.city);
        if(addressList){
          setAddressesOptions(addressList);
        }
      }
      else{
        if(statesOptions.length > 0){
          addressList = uniqueEmergencyAddressListByState(sitesLocations, selectedInputStateForChangeSite.stateCode);
          if(addressList){
            setAddressesOptions(addressList);
          }
        }
        else{
          if(selectedInputCountryForChangeSite.country && selectedInputCountryForChangeSite.country.length > 0){
            addressList = uniqueEmergencyAddressListByCountry(sitesLocations, selectedInputCountryForChangeSite.country);
            if(addressList){
              setAddressesOptions(addressList);
            }
          }
        }
      }
    },[citiesOptions, selectedInputCityForChangeSite])

  //#endregion useEffect

  //#region functions

    // React updateZoomSiteLocation to store ref
    const updateZoomSiteLocation = useRef(null)

    const executeScroll = () => updateZoomSiteLocation.current.scrollIntoView()   

    // // CHECK IF THE USER IS IN WAVE 1 SITES WITH ZOOM PHONE,
    // // IF NOT RETURN AN OBJECT WITH THE USER EMAIL AND showErrorZoomPhoneNotEnabled AS TRUE
    // const CheckIfTheUserIsInWave1SiteList = ()  => {
    //   let enableSiteList = ZoomPhoneSiteListWave1Dev
    //   // if the env is prd or acc use the ZoomPhoneSiteListWave1Prd
    //   if((process.env.REACT_APP_ENV === 'PRD') || (process.env.REACT_APP_ENV === 'ACC')){
    //     enableSiteList = ZoomPhoneSiteListWave1Prd;
    //   }
    //   let userInAllowSite = enableSiteList.some((site) => selectedInputAddressForChangeSite.siteId === site.siteId);
    //   return !userInAllowSite;
    // }

      // CHECK IF THE USER IS IN WAVE 1 SITES WITH ZOOM PHONE,
  // IF NOT RETURN AN OBJECT WITH THE USER EMAIL AND showErrorZoomPhoneNotEnabled AS TRUE
  const CheckIfTheUserIsInWave1SiteList = () => {

      let isOnboardedSite = phoneSitesOnboarded.some(
        (site) => selectedInputAddressForChangeSite.siteId === site.siteId
      );
      return !isOnboardedSite;

    
  };


    //Zoom phone account info return only the country code, this function replace the country code to contry name
    const replaceCountryCodeToCountryName = (countryCode) => {
      let countryObj =  ZoomCountriesAbbreviationListWithIAMfixes.find( country => country.ID === countryCode)
      if( countryObj ) {
        return countryObj.Name
      }
      return countryCode
    }

    //Zoom phone account info return only the state code, this function replace the state code to contry name
    const replaceStateCodeToStateName = (stateCode) => {
      let stateObj =  ZoomStatesAbbreviationList.find( state => state.ID === stateCode)
      if( stateObj ) {
        return stateObj.Name
      }
      return stateCode
    }

    //Before submit request to move a user, check if all the fields is valid 
    const checkFormMoveUser = () => {
      setTouchAllForChangeSite("trigger"); 
      if (!Object.values(inputStatesForChangeSite).includes(false)) {
        setMoveUserConfirmationPopUp(true);
      }
      else{
        console.log("Form not filled properly");
        setMoveUserConfirmationPopUp(false);
      }
    } 

    // Submit the move request
    const HandleMoveUser = async () => {
      setTriggeredMoveUser(true);
      if (webApiToken) {
        try {
          setMoveUserConfirmationPopUp(false);
          setMoveRequestLoading(true);
          
          let formData = new FormData();
          formData.append("userId", user.email);
          formData.append("targetSiteId", selectedInputAddressForChangeSite.siteId);       
          formData.append("siteCode", user.extensionNumber.toString().substring(0,4));       
          
          const [moveUserResp] = await Promise.all([
            PostMoveUserSite(webApiToken, formData),
          ]);

          setPopUpMoveSuccess(true);
          setUser(moveUserResp.data);
        } catch (err) {
          console.log("err.message", err.message);
          setMoveUserError(err);
          setMoveFail(true);
        } finally {
          setMoveRequestLoading(false);  
          setShowChangeSiteOptions(false);    
        }
      };
    }

    // FUNCTION TO UPDATE THE INPUT STATES
    // USED TO DEFINE IF THE GLOBAL FORM STATE IS VALID OR INVALID
    const GetStateForChangeSite = (state) => {
      setInputStatesForChangeSite((oldState) => {
        return { ...oldState, ...state };
      });
    };

  //#endregion functions

  return(
    <PhoneMoveCardWrapper>

      {/* {moveRequestLoading && (<LoadingScreen_V2 loadingMessage="Processing move request..."></LoadingScreen_V2>)} */}


      {/* Show warning IDM country */}
      { showWarningIDM && (
        <div id="warning-get-phone-msg">
          <div className="App-em-c-alert App-em-c-alert--warning">
            <div className="App-em-c-alert__body">
              Notice: The Country field populates from IDM. If the listed country is not correct,
              you must first update in IDM, wait 24 hours to populate, then try this form again. 
              When the Country updates from IDM, you may complete your Zoom location move here. 
            </div>
            <div className="App-em-c-alert__actions">
              <button 
                type="button"
                className="App-em-c-text-btn App-em-c-alert--warning"
                onClick={ () => setShowWarningIDM(false)}
              >
                  Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Success Result message for move user */}
      {popUpMoveSuccess && (
        <div className="PhoneMoveCard-em-c-alert PhoneMoveCard-em-c-alert--success">
          <div className="PhoneMoveCard-em-c-alert__body">
            Congratulations! User has been successfully moved to a new Zoom phone location. Please close this message to enable Get Number button below. &nbsp; <Link target="_blank" to="/phone-request-logs">Click here</Link> to verify your request was submitted.
          </div>
          <div className="PhoneMoveCard-em-c-alert__actions">
            <button 
              className="PhoneMoveCard-em-c-text-btn"
              onClick={() => { setPopUpMoveSuccess(false); setShowChangeSite(false) }}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Fail Result message for move user */}
      {moveFail && (
        <div className="PhoneMoveCard-em-c-alert PhoneMoveCard-em-c-alert--error">
          <div className="PhoneMoveCard-em-c-alert__body">
            There is an error. Please try again. If not resolved, please &nbsp;
            <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">goto/GenericSR</a> 
            to submit a ticket and assign to Zoom Support group or contact the&nbsp;<a target="_blank" href="https://goto/itservices" rel="noreferrer">Help Desk</a>. <br></br>
            {moveUserError.message}
          </div>
          <div className="PhoneMoveCard-em-c-alert__actions">
            <button 
              className="PhoneMoveCard-em-c-text-btn"
              onClick={() => setMoveFail(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Change zoom site */}
      <div ref={updateZoomSiteLocation}>
        {showChangeSiteOptions &&
        (
          <div > {/* div body with all components */}
            <div className="loading-sites-container">
              <label>Update Zoom phone location:</label>
              <div className={`PhoneMoveCard-row-section ${isLoading || moveRequestLoading? "loading": ""}`}>
              {userSiteLocation &&(
                /*  Display the current user phone info */
                <div className="PhoneMoveCard-zoom-account-info">
                  <h5>Current Zoom phone location</h5>
                  {/* Country */}
                  <Input
                    note="Current country for Zoom phone location."
                    label="Country"
                    type="text"
                    placeholder="Site Country Name"
                    value={replaceCountryCodeToCountryName(userSiteLocation.country)}
                    disabled
                    toolTipMessage=""
                  />

                  {/* State */}
                  <Input
                    note="Current state for Zoom phone location."
                    label="State (Province)"
                    type="text"
                    placeholder="Site State (Province) Name"
                    value={replaceStateCodeToStateName(userSiteLocation.stateCode)}
                    disabled
                    toolTipMessage=""
                  />

                  {/* City */}
                  <Input
                    note="Current city for your Zoom phone location."
                    label="City"
                    type="text"
                    placeholder="Site City Name"
                    value={userSiteLocation.city}
                    disabled
                    toolTipMessage=""
                  />
                  {/* Zoom Phone Site Name */}
                  <Input
                    note="This is the name of your zoom phone location."
                    label="Zoom phone location name"
                    type="text"
                    placeholder="Location Name"
                    value={ userSiteLocation.siteName }
                    toolTipMessage=""
                    disabled
                  />
                </div>

              )}

                {/*  Display the user phone info to change */}
                <div className="PhoneMoveCard-zoom-account-info">
                  <h5>New Zoom phone location</h5>
                  
                  {/* Select Country */}
                  <Input
                      note="This is your country from identity manager (IDM)"
                      label="IDM Country"
                      type="text"
                      placeholder="IDM country"
                      value={userCountry.Name}
                      // value={user.country}
                      disabled
                      toolTipMessage="Your current country from identity manager"
                    />

                  {/* Select State */}
                  {statesOptions.length > 0 && (

                    <div>
                      <AutoComplete
                        label="Select a State (Province)"
                        inputValue={inputStateForChangeSite}
                        setInputValue={setInputStateForChangeSite}
                        note="Please search by state name."
                        listOfObjects={statesOptions}
                        optionKeyValues={['stateName']}
                        returnSelectedObj={setSelectedInputStateForChangeSite}
                        toolTipMessage = ""
                        GetState={GetStateForChangeSite}
                        touchAll={touchAllForChangeSite}

                      >
                      </AutoComplete>
                    </div>
                  )}
                  {/* Select City */}
                  {citiesOptions.length > 0 && 
                  (
                    <div>
                      <AutoComplete
                        label="Select a City"
                        inputValue={inputCityForChangeSite}
                        setInputValue={setInputCityForChangeSite}
                        note="Please search by city name."
                        listOfObjects={citiesOptions}
                        optionKeyValues={['city']}
                        returnSelectedObj={setSelectedInputCityForChangeSite}
                        toolTipMessage=""
                        GetState={GetStateForChangeSite}
                        touchAll={touchAllForChangeSite}
                      >
                      </AutoComplete>
                    </div>
                  )}

                  {/* Select Zoom Phone Site */}
                  {addressesOptions.length > 0 && (
                    <div>
                      <AutoComplete
                        label="Select a Zoom Phone Location"
                        inputValue={inputAddressForChangeSite}
                        setInputValue={setInputAddressForChangeSite}
                        note="Please search by Zoom phone location name."
                        listOfObjects={addressesOptions}
                        optionKeyValues={['siteName']}
                        returnSelectedObj={setSelectedInputAddressForChangeSite}
                        toolTipMessage=""
                        GetState={GetStateForChangeSite}
                        touchAll={touchAllForChangeSite}
                      >
                      </AutoComplete>
                    </div> 
                  )}

                  <div id="notfound-location">
                    <p>If you cannot find the location, <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">goto/GenericSR</a> to submit a ticket and assign to Zoom Support group or contact the <a target="_blank" href="https://goto/itservices" rel="noreferrer">Help Desk</a>.</p>
                  </div>

                  {/* {showCouldNotLocation && (
                    <div id="error-notfounduser">
                      <div className="em-c-alert em-c-alert--warning">
                        <div className="em-c-alert__body">
                          If you cannot find the location, contact the Help Desk <a target="_blank" href={"https://goto/GenericSR"}>goto/GenericSR</a>.
                        </div>
                        <div className="em-c-alert__actions">
                          <button 
                            type="button"
                            className="em-c-text-btn em-c-alert--warning"
                            onClick={ () => setShowCouldNotLocation(false)}
                          >
                              Close
                          </button>
                        </div>
                      </div>
                    </div>     
                  )} */}

                  {/*Button Section */}
                  {!triggeredMoveUser && (
                    <div className="PhoneMoveCard-btn-row-section">
                        <button id="PhoneMoveCard-btn-save-changes" className="PhoneMoveCard-btn PhoneMoveCard-btn-medium"
                          onClick={() => {checkFormMoveUser()}}
                          disabled={ isLoading || isShowingPopUp }
                          type="button"
                        >
                          Save change
                        </button>

                        <button id="PhoneMoveCard-btn-cancel-changes" className="PhoneMoveCard-btn PhoneMoveCard-btn-medium"
                          onClick={() => { setShowChangeSite(false) }}
                          disabled={ isLoading || isShowingPopUp}
                          type="button"
                        >
                          Cancel
                        </button>
                    </div>
                  )}



                </div>
              </div>   

              { zoomSitesLocationsLoading 
              && (
                <div className="loading-sites">
                  <LoadingScreen_V2 loadingMessage="Loading Zoom phone locations"></LoadingScreen_V2>
                </div>
              ) }

              { moveRequestLoading 
              && (
                <div className="loading-sites">
                  <LoadingScreen_V2 loadingMessage="Processing move request."></LoadingScreen_V2>
                </div>
              )}

            </div>   
          </div>
        )}
      
      </div>

      {moveUserConfirmationPopUp && (
        <PopUp
          title="Change Zoom Phone Location"
          message={CheckIfTheUserIsInWave1SiteList() ? "The new location has not yet implemented external calling, you will not be able to request a number for this user at this time. Do you want to continue with the location update?" : "Are you sure you want to update the location?"}
          showPopUp={moveUserConfirmationPopUp}
          setShowPopUp={setMoveUserConfirmationPopUp}
          cancelCallback={() => setMoveUserConfirmationPopUp(false)}
          submitForm={HandleMoveUser}
        >
        </PopUp>
      )}
    </PhoneMoveCardWrapper>
  )
}

export default PhoneMoveCard