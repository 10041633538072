import { useEffect, useState } from "react";
import styled from "styled-components";

import { SVGGroup } from "../Assets/SVGTiles";
import { SVGUser1 } from "../Assets/SVGTiles";
import { SVGXFilled } from "../Assets/SVGTiles";

import "./BulkSearch.css";

/////////////////////////COMPONENT STYLES/////////////////////////////////

/////////////////////////MAIN COMPONENT/////////////////////////////////
const BulkSearch = (props) => {
  // INITIALIZING LOCAL STATES FOR THE APPLICATION
  // 1. STATE THAT DEFINES IF THE INPUT IS VALID
  // 2. STATE THAT DEFINES IF THE USER ALREADY TRIED TO WORK WITH THIS FIELD
  // 3. STATE THAT HOLDS THE ERROR MESSAGE THAT WILL BE DISPLAYED
  const [validInput, setValidInput] = useState(true);
  const [validInputList, setValidInputList] = useState(true);
  const [firstTry, setFirstTry] = useState(true);
  const [InputErrorMessage, setInputErrorMessage] =
    useState("Validation error");
  const [InputListErrorMessage, setInputListErrorMessage] =
    useState("Validation error");
  const [isSingleRequest, setIsSingleRequest] = useState(true);

  // BLOCK THAT MONITORS THE TOUCH ALL VARIABLE OF PARENT COMPONENT, IF IT IS trigger
  // SET firstTry TO FALSE IF IT IS submitted SET firstTry TO TRUE
  if (props.touchAll === "trigger" && firstTry) {
    setFirstTry(false);
  } else if (props.touchAll === "submitted" && !firstTry) {
    setFirstTry(true);
  }

  // FUNCTION THAT VALIDATED THE USER INPUT
  const InputListValidator = () => {
    if (props.validationRules) {
      // 1. TESTING IF THE INPUT IS EMPTY
      if (props.recorderList.length === 0) {
        setInputListErrorMessage("List cannot be empty.");
        setValidInputList(false);
        return;
      } else {
        // IF ALL TESTS ABOVE SUCCEED TWEAK THE VALID STATE TO TRUE
        setValidInputList(true);
      }
    } else {
      setValidInputList(true);
    }
  };
  // FUNCTION THAT VALIDATED THE USER INPUT
  const InputValidator = () => {
    setValidInput(true);
    // IF THE COMPONENT HAS VALIDATION RULES IN PROPS, THIS BLOCK RUNS:
    if (props.validationRules) {
      // CREATE LOCAL VARIABLE -> rules
      const rules = props.validationRules;
      // 2. TESTING IF THE INPUT IS BIGGER THAN THE MIN LENGTH
      if (rules.minLength && rules.minLength >= props.value.length) {
        setInputErrorMessage(
          `Field must have more than ${rules.minLength} chars.`
        );
        return;
      }
      // 3. TESTING IF THE INPUT INCLUDES A SPECIAL CHARACTER
      if (rules.includes && !props.value.includes(rules.includes)) {
        setInputErrorMessage(`Field must have "${rules.includes}" symbol.`);
        setValidInput(false);
        return;
      }
      // 4. TESTING IF THE MIN VALUE IS BEING RESPECTED
      if (rules.min !== undefined && rules.min > props.value) {
        setInputErrorMessage(`Value must be greater than ${rules.min}.`);
        setValidInput(false);
        return;
      }
      // 5. TESTING EXACT ENTRIES FOR THE VALUE
      if (rules.exactMail && !rules.exactMail.includes(props.value)) {
        setInputErrorMessage("Please select a valid e-mail address.");
        setValidInput(false);
        return;
      }
      // IF ALL TESTS ABOVE SUCCEED TWEAK THE VALID STATE TO TRUE
      setValidInput(true);
    }
    // IF THERE IS NO VALIDATION RULES IN THE COMPONENT PROPS, THEN
    // VALID STATE IS SET TO TRUE
    else {
      setValidInput(true);
    }
  };

  // RUNS THE INPUT VALIDATOR FUNCTION EVERY TIME THAT THE INPUT VALUE CHANGES
  // AND EVERY TIME THAT exact PROPERTY FROM validationRules CHANGE THIS IS A
  // HACK DONE TO ASSURE THAT, IF THE USER COPY AND PASTE A STRING ON ANY
  // INPUT THIS BLOCK OF CODE WILL RUN AGAIN
  const exact = JSON.stringify(props.validationRules);

  useEffect(() => {
    InputListValidator();
    if (props.GetState) {
      props.GetState({ [props.label]: validInputList });
    }
  }, [props.recorderList, validInputList, props.label, exact]);

  useEffect(() => {
    InputValidator();
  }, [props.value, validInput, props.label, exact]);

  // FUNCTION THAT CHANGES THE VALUE FOR THE INPUT EVERY TIME THE USER HITS THE KEYBOARD
  const changeHandler = (e) => {
    if (props.setValue) {
      props.setValue(e.target.value);
    }
  };

  const toggleRequestType = () => {
    setIsSingleRequest(!isSingleRequest);
  };

  const clearInput = () => {
    props.setValue("");
  };

  const addRecorderToList = (entry) => {
    props.setValue("");
    if (!props.recorderList.find((user) => user.mail === entry.mail)) {
      props.setRecorderList([...props.recorderList, entry]);
    } else {
      setInputErrorMessage(`User alreaedy in the list`);
    }
  };

  const removeRecorderFromList = (entry) => {
    props.setRecorderList(
      props.recorderList.filter((user) => user.mail !== entry.mail)
    );
  };

  const clearRecorderList = () => {
    props.setRecorderList([]);
  };

  const changeHandlerTextArea = (e) => {
    props.setTextAreaInput(e.target.value);
  };

  const addTextAreaToListToSearch = () => {
    // Convert values from textarea into an array of users
    let buldReqArr = props.textAreaInput.split(/[\r\n]+/);
    props.setListToSearch([]);

    // regex pattern for email address
    let regx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Validate each requester email in array
    buldReqArr.forEach((email) => {
      email = email.toLowerCase().trim();
      // Skip blank lines
      if (email) {
        // If email didn't pass regx test, flag it
        let found = email.match(regx);
        if (!found) {
          props.setTextAreaInput(`"${email}" <-- ERROR: Not an email\n`);
        } else {
          if (!props.listToSearch.find((user) => user.mail === email)) {
            props.setListToSearch((previousState) => [...previousState, email]);
          }
        }
      }
    });
  };

  // COMPONENT JSX DEFINITION
  return (
    <div className="Wrapper">
      <div className="request-component">
        {isSingleRequest ? (
          <div>
            {" "}
            {/* div single Request */}
            <div className="recorder-header">
              <h5>Single Recorder Search </h5>
              <p>
                Click on icon below to switch to <b>BULK</b> search
              </p>
              <button
                type="button"
                className="bulk-btn"
                onClick={() => {
                  toggleRequestType();
                  clearInput();
                }}
              >
                <SVGGroup specificSize="25" />
              </button>
            </div>
            <div>
              <label>{props.label}:</label>
            </div>
            <div className="">
              <div className="input-search-tip-wrapper">
                <input
                  className={`${!validInput && !firstTry && "invalid"}`}
                  onChange={changeHandler}
                  onBlur={() => {
                    setFirstTry(false);
                  }}
                  value={props.value}
                  type={props.type}
                  placeholder={props.placeholderInput}
                  min={props.min}
                />

                <div className="BulkSearch-container-for-searchtip">
                  {props.autocomplete &&
                    props.value.length >= 4 &&
                    props.autocomplete.length > 0 && (
                      <ul className="BulkSearch-box-list">
                        {props.autocomplete.map((entry) => (
                          <li
                            className="BulkSearch-li"
                            key={entry.mail}
                            onClick={() => {
                              addRecorderToList(entry);
                            }}
                          >
                            {entry.mail} &nbsp; ({entry.displayName})
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>

              {!validInput && !firstTry && (
                <p className="alert">{InputErrorMessage}</p>
              )}
            </div>
          </div> //end of div single Request
        ) : (
          <div>
            {" "}
            {/* div bulk Request */}
            <div className="recorder-header">
              <h5>Bulk Recorder Search </h5>
              <p>
                Click on icon below to switch to <b>SINGLE</b> search
              </p>
              <button
                type="button"
                className="bulk-btn"
                onClick={() => {
                  toggleRequestType();
                  clearInput();
                }}
              >
                <SVGUser1 specificSize="25" />
              </button>
            </div>
            <div>
              <label>{props.label}:</label>
            </div>
            <div className="">
              <p>
                Enter an email address for each recorder, one email per line.
              </p>

              <div className="input-search-tip-wrapper">
                <textarea
                  rows="5"
                  cols="50"
                  onChange={changeHandlerTextArea}
                  onBlur={() => {
                    setFirstTry(false);
                  }}
                  value={props.textAreaInput}
                  placeholder={props.placeholderTextArea}
                  min={props.min}
                />
              </div>
              {props.textAreaInput.length > 0 && (
                <button
                  type="button"
                  className="clear-list-btn"
                  onClick={() => addTextAreaToListToSearch()}
                >
                  Add recorders
                </button>
              )}
            </div>
            {/* end of div bulk Request */}
          </div>
        )}
        <div className="recorder-list">
          <label>Recorder List:</label>

          {!props.loadingRecorderSearchList &&
            props.recorderList.length > 0 && (
              <button
                type="button"
                className="clear-list-btn"
                onClick={clearRecorderList}
              >
                Clear List
              </button>
            )}
          <ul className="fit-content">
            {props.recorderList.map((entry) => (
              <li
                className="BulkSearch-li" 
                key={entry.mail}>
                <div className="recorder-list-item">
                  {entry.displayName} <br /> {entry.mail}
                  <button
                    type="button"
                    className="remove-recorder-btn"
                    onClick={() => removeRecorderFromList(entry)}
                  >
                    <SVGXFilled specificSize="20" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
          {!validInputList && !firstTry && (
            <p className="alert">{InputListErrorMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BulkSearch;
