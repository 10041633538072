import { useEffect, useState } from "react";
import styled from "styled-components";
import ToolTip from "../Components/ToolTip/ToolTip";

/////////////////////////COMPONENT STYLES/////////////////////////////////

// const SVGStyle = styled.svg`
//   ${(props) =>
//     props.specificSize &&
//     css`
//       width: ${props.specificSize}px !important;
//     `};
// `;

const InputWrapper = styled.div`
  display: flex;  
  margin: 8px 15px;
  height: 110px 

  }; 

  .Input-label {
    font-size: 17px;
    font-weight: 600;
    margin : 5px 0px;
  }

  input {
    font-size: 15px;
    padding: 0px 15px;
    height: 35px;
    border: 1px solid var(--lightgrey);
    border-radius: 10px;
    width: ${(props) =>
      props.specificSize ? `${props.specificSize}px;` : "400px;"}; 
    margin: 0 5px;
  }
    
  textarea {
    font-size: 15px;
    padding: 0px 15px;
    border: 1px solid var(--lightgrey);
    border-radius: 10px;
    width: 400px;
    margin: 0 5px;
  }


  input:focus-visible {
    outline: none;
    background-color: var(--lightgrey);
  }

  input.invalid {
    border: 1px solid var(--red);
  }

  .Input-warning-message{
    color: var(--red) !important;
    font-size: 14px;
    margin-left: 15px;
    margin-bottom: 0;
  }

  .Input-button {
    margin: 0 20px;
    height: 35px;
    background-color: var(--primary-blue);
    color: var(--white);
    border: var(--primary-blue) solid 2px;
    border-radius: 10px;
    padding: 2px 15px;
    min-width: 140px;
  }

  .Input-button:hover {
    color: var(--primary-blue);
    background-color: var(--white);
  }

  .Input-container-for-searchtip {
    background-color: none;
    position: relative;
  }

  .Input-flex-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
  }

  .Input-search-box-outer,
  .Input-search-box-inner {
    height: 0;
    overflow: hidden;
  }
  input:focus + div .Input-search-box-outer,
  .Input-search-box-outer:hover {
    position: absolute;
    height: 250px;
    width: 100%;
    overflow: hidden;
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: var(--darkgrey) solid 2px;
    border-top: none;
  }
  input:focus + div .Input-search-box-inner,
  .Input-search-box-inner:hover {
    overflow: auto;
    height: 250px;
  }
  .Input-search-box-inner::-webkit-scrollbar {
    width: 10px;
  }
  .Input-search-box-inner::-webkit-scrollbar-track {
    background-color: #d8d8d8;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.45);
  }
  .Input-search-box-inner::-webkit-scrollbar-thumb {
    background-color: var(--darkgrey);
    border-radius: 20px;
    border: 3px solid #d8d8d8;
  }

  .Input-entry-card {
    border-bottom: 1px solid var(--lightgrey);
    padding: 6px;
    cursor: pointer;
  }
  .Input-entry-card:hover {
    background-color: var(--lightgrey);
  }
  .Input-entry-mail {
    font-size: 15px;
    color: #4e4e4e;
    margin: 6px;
  }
  .Input-entry-name {
    font-size: 13px;
    color: #636363db;
    margin: 6px 15px;
    
  }

  .Input-note{
    font-size: .80rem;
    color: #112;
    font-weight: normal;
    margin: 0px 15px;
    width: 400px; 
  }

  .Input-tool-tip{
    position: absolute;
    top: 2px;
    right : 30px;
    z-index: 1;
  }
`;

/////////////////////////MAIN COMPONENT/////////////////////////////////
const Input = (props) => {
  // INITIALIZING LOCAL STATES FOR THE APPLICATION
  // 1. STATE THAT DEFINES IF THE INPUT IS VALID
  // 2. STATE THAT DEFINES IF THE USER ALREADY TRIED TO WORK WITH THIS FIELD
  // 3. STATE THAT HOLDS THE ERROR MESSAGE THAT WILL BE DISPLAYED
  const [validInput, setValidInput] = useState(true);
  const [firstTry, setFirstTry] = useState(true);
  const [errorMessage, setErrorMessage] = useState("Validation error");

  // BLOCK THAT MONITORS THE TOUCH ALL VARIABLE OF PARENT COMPONENT, IF IT IS trigger
  // SET firstTry TO FALSE IF IT IS submitted SET firstTry TO TRUE
  if (props.touchAll === "trigger" && firstTry) {
    setFirstTry(false);
  } else if (props.touchAll === "submitted" && !firstTry) {
    setFirstTry(true);
  }

  // FUNCTION THAT VALIDATED THE USER INPUT
  const InputValidator = () => {
    // IF THE COMPONENT HAS VALIDATION RULES IN PROPS, THIS BLOCK RUNS:
    if (props.validationRules) {
      // CREATE LOCAL VARIABLE -> rules
      const rules = props.validationRules;
      // 1. TESTING IF THE INPUT IS EMPTY
      if (props.value.trim() === "") {
        setErrorMessage("Field cannot be empty.");
        setValidInput(false);
        return;
      }
      // 2. TESTING IF THE INPUT IS BIGGER THAN THE MIN LENGTH
      if (rules.minLength && rules.minLength >= props.value.length) {
        setErrorMessage(`Field must have more than ${rules.minLength} chars.`);
        setValidInput(false);
        return;
      }
      // 3. TESTING IF THE INPUT INCLUDES A SPECIAL CHARACTER
      if (rules.includes && !props.value.includes(rules.includes)) {
        setErrorMessage(`Field must have "${rules.includes}" symbol.`);
        setValidInput(false);
        return;
      }
      // 4. TESTING IF THE MIN VALUE IS BEING RESPECTED
      if (rules.min !== undefined && rules.min > props.value) {
        setErrorMessage(`Value must be greater than ${rules.min}.`);
        setValidInput(false);
        return;
      }
      // 5. TESTING EXACT ENTRIES FOR THE VALUE
      if (rules.exactMail && !rules.exactMail.includes(props.value)) {
        setErrorMessage("Please select a valid e-mail address.");
        setValidInput(false);
        return;
      }
      //6 TEXTING EMAIL
      if (rules.emailRule) {
        let regx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        let found = props.value.match(regx);

        if (!found) {
          setErrorMessage("Please select a valid e-mail address.");
          setValidInput(false);
          return;
        }
      }
      if (rules.maxLength && props.value.length > rules.maxLength) {
        setErrorMessage(`Field must have less than ${rules.maxLength} chars.`);
        setValidInput(false);
        return;
      }

      // IF ALL TESTS ABOVE SUCCEED TWEAK THE VALID STATE TO TRUE
      setValidInput(true);
    }
    // IF THERE IS NO VALIDATION RULES IN THE COMPONENT PROPS, THEN
    // VALID STATE IS SET TO TRUE
    else {
      setValidInput(true);
    }
  };

  // RUNS THE INPUT VALIDATOR FUNCTION EVERY TIME THAT THE INPUT VALUE CHANGES
  // AND EVERY TIME THAT exact PROPERTY FROM validationRules CHANGE THIS IS A
  // HACK DONE TO ASSURE THAT, IF THE USER COPY AND PASTE A STRING ON ANY
  // INPUT THIS BLOCK OF CODE WILL RUN AGAIN
  const exact = JSON.stringify(props.validationRules);
  useEffect(() => {
    InputValidator();
    if (props.GetState) {
      props.GetState({ [props.label]: validInput });
    }
  }, [props.value, validInput, props.label, exact]);

  // FUNCTION THAT CHANGES THE VALUE FOR THE INPUT EVERY TIME THE USER HITS THE KEYBOARD
  const changeHandler = (e) => {
    if (props.setValue) {
      props.setValue(e.target.value);
    }
  };

  // COMPONENT JSX DEFINITION
  return (
    <InputWrapper specificSize={props.specificSize}>
      <div>
        <div className="Input-label">
          {props.textAfter ? `${props.label} ${props.textAfter}` : props.label}
          <br />
        </div>
        <div className="Input-flex-row">
          {props.type === "textarea" ? (
            <div className="Input-container-for-searchtip">
              <textarea
                rows="5"
                cols="50"
                className={`${!validInput && !firstTry && "invalid"}`}
                onChange={changeHandler}
                onBlur={() => {
                  setFirstTry(false);
                }}
                value={props.value}
                type={props.type}
                placeholder={props.placeholder}
                min={props.min}
              />
              {props.toolTipMessage && (
                <div className="Input-tool-tip">
                  <ToolTip
                    id={props.label}
                    tipMessage={props.toolTipMessage}
                    svgSize="20"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="Input-container-for-searchtip">
              <input
                disabled={props.disabled}
                className={`${!validInput && !firstTry && "invalid"}`}
                onChange={changeHandler}
                onBlur={() => {
                  setFirstTry(false);
                }}
                value={props.value}
                type={props.type}
                placeholder={props.placeholder}
                min={props.min}
              />
              {props.toolTipMessage && (
                <div className="Input-tool-tip">
                  <ToolTip
                    id={props.label}
                    tipMessage={props.toolTipMessage}
                    svgSize="20"
                  />
                </div>
              )}

              <div className="Input-note">{props.note}</div>
            </div>
          )}
          <div className="Input-container-for-searchtip">
            {props.autocomplete &&
              props.value.length >= 4 &&
              props.autocomplete.length > 0 &&
              props.autocomplete[0].mail !== props.value && (
                <div className="Input-search-box-outer">
                  <div className="Input-search-box-inner">
                    {props.autocomplete.map((entry) => (
                      <div
                        key={entry.mail}
                        onClick={() => {
                          props.setValue(entry.mail);
                        }}
                        className="Input-entry-card"
                      >
                        <h2 className="Input-entry-mail">{entry.mail}</h2>
                        <h2 className="Input-entry-name">
                          {entry.displayName}
                        </h2>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
          {props.actionButton && (
            <button
              className="Input-button"
              type="button"
              onClick={props.actionButton}
            >
              {props.actionButtonText}
            </button>
          )}
        </div>
        {!validInput && !firstTry && (
          <p className="Input-warning-message">{errorMessage}</p>
        )}
      </div>
    </InputWrapper>
  );
};

export default Input;
