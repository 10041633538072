import { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../Components/Input";
import FetchGraphData from "../Hooks/funcFetchGraphData";
import useAccessToken from "../Hooks/useAccessToken";
import SelectInput from "../Components/SelectInput";
import useWebAPIToken from "../Hooks/useWebAPIToken";
import { GetFilteredRooms, ResetRoomCode } from "../Hooks/funcFetchRooms";

import { GetAllLocations } from "../Hooks/funcFetchRoomLocations";
import LoadingScreen from "../Components/LoadingScreen";

/////////////////////////COMPONENT STYLES/////////////////////////////////
const Wrapper = styled.div`
  background-color: var(--primary-white-light);
  padding: var(--content-padding);
  border-radius: var(--content-border-radius);
  margin: var(--content-margin);
  box-shadow: var(--box-shadow-main);

  p,
  li,
  h1 {
    color: var(--black);
  }

  .btn {
    background-color: var(--primary-blue);
    color: var(--white);
    border: var(--primary-blue) solid 2px;
    padding: 10px 50px;
    margin: 15px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    box-shadow: 2px 2px 5px #0000008c;
  }

  .btn:hover {
    color: var(--primary-blue);
    background-color: var(--white);
    box-shadow: 2px 2px 5px var(--primary-blue);
  }

  .btn {
    width: 163px;
    height: 51px;
  }
  .inactive,
  .inactive:hover {
    background-color: var(--primary-blue);
    color: var(--white);
    cursor: progress !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .message-container {
    align-items: center;
    justify-content: flex-start;
  }
  .message {
    display: inline-block;
    font-weight: 600;

    &.invalid {
      color: var(--red);
    }
    &.valid {
      color: var(--primary-blue);
    }
  }

  .response-container {
    margin: 15px;
    margin-top: 15px;
    border-top: 1px solid var(--lightgrey);
  }
  .response-body {
    margin: 15px;
  }
  .response-title {
    margin: 15px;
    align-text: center;
  }
  .room-expired-submit {
    border-top: 1px solid var(--lightgrey);
  }
  .deactivate-status {
    color: red;
    font-weight: bold;
  }

  .room-expired-submit-label {
    display: block;
    margin-top: 15px;
    color: #112;
    font-size: 1rem;
    font-family: EMprintSemibold, HelveticaNeue, Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
`;

function RoomSelectedElement(props) {
  let room = props.rooms.find((room) => room.id === props.roomId);
  return (
    <div className="response-container">
      <h4 className="response-title">Room Info</h4>
      <div className="response-body">
        <p>
          {" "}
          <b>Name:</b> {room.name}{" "}
        </p>
        {!room.activation_code ? (
          <p>
            <span className="deactivate-status">
              <b>Activation Code:</b> Activation code expired{" "}
            </span>
          </p>
        ) : (
          <p>
            <b>Activation Code:</b> <h3>{room.activation_code}</h3>
          </p>
        )}
        {!room.activation_code ? (
          <div className="room-expired-submit">
            {/* <h5>Do you want to reset the activation code?</h5> */}
            <label className="room-expired-submit-label">
              Do you want to reset the activation code?
            </label>
            <input
              className="btn"
              type="submit"
              value="Submit"
              onClick={props.button}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

function ResetCodeResponseElement(props) {
  return (
    <div className="response-container">
      <h4 className="response-title">
        Activation code regenerated successfully
      </h4>
      <div className="response-body">
        <p>
          {" "}
          <b>Name:</b> {props.room.name}{" "}
        </p>
        <p>
          {" "}
          <b>Activation Code:</b> <h3>{props.room.activation_code}</h3>{" "}
        </p>
      </div>
    </div>
  );
}

/////////////////////////MAIN COMPONENT/////////////////////////////////
const ZoomRoomForm = () => {
  // SETTING LOCAL STATED FOR THE COMPONENT
  // 0. REQUESTOR NAME/EMAIL
  // 1. CUSTOMER EMAIL
  // 2. ARRAY WITH THE HINT SEARCH BOX DATA FOR CUSTOMER_EMAIL
  // 3. ASSISTANCE DATE
  // 4. ASSISTANCE TIME
  // 5. TIME ZONE OF THE USER
  // 6. WORK ORDER NUMBER THAT GENERATE THE ASSISTANCE
  // 7. DURATION OF THE ASSISTANCE
  // 8. OBJECT THAT CONTAINS THE VALIDITY STATES FOR ALL THE INPUTS
  // 9. OBJECT THAT TURNS ALL FIELDS TO TOUCHED WHEN SUBMIT BUTTON IS CLICKED
  // 10. ARRAY THAT HOLDS ALL time_zones
  // 11. STATE THAT DEFINES IF THE SUBMIT REQUEST IS IN PROGRESS
  // 12. STATE THAT HOLDS THE RESPONSE STATUS FOR THE REQUEST
  const [requestorName, setRequestorName] = useState("");
  const [requestorEmail, setRequestorEmail] = useState("");
  const [roomId, setRoomId] = useState("");

  const [rooms, setRooms] = useState([]);
  const [loadingRooms, setLoadingRooms] = useState(false);

  const [roomSelected, setRoomSelected] = useState([]);
  const [roomResetCode, setRoomResetCode] = useState([]);

  const [inputStatesResetCode, SetInputStatesResetCode] = useState({});
  const [touchAllResetCode, setTouchAllResetCode] = useState("");
  const [submitInProgressResetCode, setSubmitInProgressResetCode] =
    useState(false);

  //Location Details
  const [continents, setContinents] = useState([]);
  const [continentId, setContinentId] = useState("");
  const [countries, setCountries] = useState([]);
  const [campuses, setCampuses] = useState([]);
  const [campusId, setCampusId] = useState("");
  const [countryId, setCountryId] = useState("");
  const [loadingText, setLoadingText] = useState(
    "Getting all the right data for you .. Hang Tight !"
  );

  // GENERATES A TOKEN FOR THE GRAPH API AND CUSTOM WEB API
  const access_token = useAccessToken();
  const web_api_token = useWebAPIToken();

  // IF TIME ZONES ARE NOT FETCHED YET, AND, VALID API TOKEN IS AVAILABLE
  // FETCH THE TIME ZONES FROM ASP NET BACKEND
  useEffect(() => {
    if (web_api_token && campusId) {
      setLoadingText("Loading Rooms");
      setLoadingRooms(true);
      setRoomId("");
      GetFilteredRooms(web_api_token, campusId).then((data) => {
        setRooms(data);
        setLoadingRooms(false);
      });
    }
  }, [web_api_token, campusId]);

  //Load all continents
  useEffect(() => {
    if (web_api_token) {
      setLoadingText("Loading Continents");
      setLoadingRooms(true);
      setRoomId("");
      GetAllLocations(web_api_token, "country", "").then((data) => {
        setContinents(data);
        setCountries([]);
        setCampuses([]);
        setRooms([]);
        setRoomResetCode("");
        setLoadingRooms(false);
      });
    }
  }, [web_api_token]);

  //Loading Countries
  useEffect(() => {
    if (web_api_token && continentId) {
      setLoadingText("Loading Countries");
      setLoadingRooms(true);
      setRoomId("");
      GetAllLocations(web_api_token, "state", continentId).then((data) => {
        setCountries(data);
        setCampuses([]);
        setRooms([]);
        setRoomResetCode("");
        setLoadingRooms(false);
      });
    }
  }, [web_api_token, continentId]);

  //Loading Campuses
  useEffect(() => {
    if (web_api_token && countryId) {
      setLoadingText("Loading Campuses");
      setLoadingRooms(true);
      setRoomId("");
      GetAllLocations(web_api_token, "campus", countryId).then((data) => {
        setCampuses(data);
        setRooms([]);
        setRoomResetCode("");
        setLoadingRooms(false);
      });
    }
  }, [web_api_token, countryId]);

  // requestorEmail HANDLER //
  // CUSTOM FUNCTION TO FETCH DATA FROM /me ENDPOINT FROM GRAPH API
  // WHEN THE HTTP REQUEST IS DONE, requestorEmail IS UPDATED WITH
  // USER DATA RECEIVED FROM GRAPH
  useEffect(() => {
    if (access_token) {
      FetchGraphData({
        endpoint: "/me",
        accessToken: access_token,
      }).then((data) => {
        setRequestorEmail(data.mail);
        setRequestorName(data.displayName);
      });
    }
  }, [access_token]);

  useEffect(() => {
    let room = rooms.find((room) => room.id === roomId.slice(0, 22));
    setRoomSelected(room);
  }, [roomId]);

  // FUNCTION TO UPDATE THE INPUT STATES
  // USED TO DEFINE IF THE GLOBAL FORM STATE IS VALID OR INVALID
  const GetStateResetCode = (state) => {
    SetInputStatesResetCode((old_state) => {
      return { ...old_state, ...state };
    });
  };

  // FUNCTION THAT HANDLES THE SUBMISSION OF THE FORM
  const SubmitFormResetCode = (e) => {
    e.preventDefault();
    setTouchAllResetCode("trigger");

    if (!Object.values(inputStatesResetCode).includes(false)) {
      setLoadingText("Re-generating Activation Code ");
      setSubmitInProgressResetCode(true);
      let formData = new FormData();
      formData.append("RequestorName", requestorName);
      formData.append("RequestorMail", requestorEmail);

      formData.append("Id", roomSelected.id);
      formData.append("Name", roomSelected.name);
      formData.append("Status", roomSelected.status);
      formData.append("RoomId", roomSelected.room_id);
      formData.append("LocationId", roomSelected.location_id);
      ResetRoomCode(web_api_token, formData).then((data) => {
        setRoomResetCode(data);
        GetFilteredRooms(web_api_token, campusId).then((data) => {
          setRooms(data);
          setLoadingRooms(false);
        });
        setSubmitInProgressResetCode(false);
        setRoomId("");
      });
    }
  };
  // JSX CODE FOR MAIN COMPONENT
  return (
    <Wrapper>
      <h1>Zoom Room Activation Code Request Form</h1>
      <div className="App-horizontal-line" />
      <br />
      <p>
        This form is used to request an activation code. The request can be a
        first-time activation code request or for the refresh of a code that has
        expired. This form works for all locations globally. <br />
        <br />
        For first-time activation code requests, please ensure you have followed
        the guidelines found in <a href="http://goto/zrdeployment">here</a> to
        have your room created in the portal.
        <br />
        <br />
        <b>NOTE : </b> Newly added rooms may take up to 24 hours after creation
        before appearing here on this form. <br />
        If you need additional support, please reach out to the CS&amp;MC team
        through the <b>CX&lt;&gt;AVETS Chat</b> Zoom channel or through email at{" "}
        <a href="mailto:GSC-EMIT-AV-ZOOMROOMS@exxonmobil.com">
          GSC-EMIT-AV-ZOOMROOMS
        </a>{" "}
        .
      </p>

      <div>
        {submitInProgressResetCode || loadingRooms ? (
          <LoadingScreen loading_text={loadingText} />
        ) : (
          <div>
            <h2>Request/Refresh Activation Code</h2>
            <div>
              <form onSubmit={SubmitFormResetCode}>
                <Input
                  label="Requestor"
                  type="text"
                  placeholder="requestor@exxonmobil.com"
                  value={requestorEmail}
                  validation_rules={{ min_length: 4, includes: "@" }}
                  GetState={GetStateResetCode}
                  touch_all={touchAllResetCode}
                />
                {continents.length < 1 ? (
                  <br />
                ) : (
                  <SelectInput
                    label="Select Region"
                    value={continentId}
                    setValue={setContinentId}
                    validation_rules={{}}
                    GetState={GetStateResetCode}
                    touchAll={touchAllResetCode}
                    textAfter=""
                    optionsList={continents}
                    optionKey="id"
                    optionValues={"id"}
                    optionContent={"name"}
                  />
                )}
                {countries.length < 1 ? (
                  <br />
                ) : (
                  <SelectInput
                    label="Select Country"
                    value={countryId}
                    setValue={setCountryId}
                    validation_rules={{}}
                    GetState={GetStateResetCode}
                    touchAll={touchAllResetCode}
                    textAfter=""
                    optionsList={countries}
                    optionKey="id"
                    optionValues={"id"}
                    optionContent={"name"}
                  />
                )}
                {campuses.length < 1 ? (
                  <br />
                ) : (
                  <SelectInput
                    label="Select Site"
                    value={campusId}
                    setValue={setCampusId}
                    validation_rules={{}}
                    GetState={GetStateResetCode}
                    touchAll={touchAllResetCode}
                    textAfter=""
                    optionsList={campuses}
                    optionKey="id"
                    optionValues={"id"}
                    optionContent={"name"}
                  />
                )}
                {rooms.length < 1 ? (
                  <br />
                ) : (
                  <SelectInput
                    label="Select a Zoom Room from the Site"
                    value={roomId}
                    setValue={setRoomId}
                    validation_rules={{}}
                    GetState={GetStateResetCode}
                    touchAll={touchAllResetCode}
                    textAfter=""
                    optionsList={rooms}
                    optionKey="id"
                    optionValues={"id"}
                    optionContent={"name"}
                  />
                )}
              </form>

              <div className="flex-row message-container">
                {roomId && rooms && !submitInProgressResetCode && (
                  <RoomSelectedElement
                    roomId={roomId}
                    rooms={rooms}
                    button={SubmitFormResetCode}
                  ></RoomSelectedElement>
                )}
                {!roomId &&
                  roomResetCode.name &&
                  !submitInProgressResetCode && (
                    <ResetCodeResponseElement
                      room={roomResetCode}
                    ></ResetCodeResponseElement>
                  )}
              </div>
            </div>
          </div>
        )}

        {/* <div>
                      <h2>Search a Zoom Room </h2>

          <form>
            <input onSubmit={handleSearchInputChange} />
          </form>
        </div> */}
      </div>
    </Wrapper>
  );
};

export default ZoomRoomForm;
