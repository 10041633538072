import { useState, useEffect } from "react";

import { useFetch } from "../../Hooks/useFetch"
import Input from "../Input"

const RequestedBy = ({
  setRequestedByEmail,
}) =>{

  const { data: user = null, error: userError = null, isFetching: userLoading = null } = useFetch("/me", false);

  useEffect(()=>{
    setRequestedByEmail(user?.mail);
  },[user])
  return(
    <div className="RequestedBy">
      {/* Requested by input field */}
      {user && (
        <Input
          note="Requester email address."
          label="Requested by"
          type="text"
          placeholder="user name"
          value={user?.mail}
          disabled
          toolTipMessage="Email of person submitting the request."
        />
      )}

      {/* Show Error get site addresses */}
      {userError && (
        <div className="App-em-c-alert App-em-c-alert--error">
          <div className="App-em-c-alert__body">
            <p>
              There is an error. Please try again. 
              If not resolved, please <a target="_blank" href={"https://goto/GenericSR"}>goto/GenericSR</a>&nbsp;
              to submit a ticket and assign to Zoom Support group or contact the &nbsp; 
              <a target="_blank" href="https://goto/itservices">Help Desk</a>. <br></br>
              { userError?.message && "Error message: " + userError.message}                                                      
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default RequestedBy;
