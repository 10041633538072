import { useEffect, useState } from "react";
import "./RecordingException.css";
import Input from "../../Components/Input";
import CheckBox from "../../Components/CheckBox";
import BulkSearch from "../../Components/BulkSearch";
import LoadingScreen_V2 from "../../Components/LoadingScreen_V2";
import AutoComplete from "../../Components/AutoComplete/AutoComplete";

import { useHistory } from "react-router";

import FetchGraphData from "../../Hooks/funcFetchGraphData";
import useAccessToken from "../../Hooks/useAccessToken";
import useWebAPIToken from "../../Hooks/useWebAPIToken";
import { PostRecordingException } from "../../Hooks/funcRecordException";
import CircleLoader from "react-spinners/CircleLoader";

import getGraphToken from "../../Hooks/funcGetGraphToken";

/////////////////////////MAIN COMPONENT/////////////////////////////////
const RecordExceptionForm = () => {
  // SETTING LOCAL STATED FOR THE COMPONENT
  // 1. REQUESTOR NAME/EMAIL
  const [requestor, setRequestor] = useState("");
  // 2. CUSTOMER EMAIL INPUT
  const [requestorEmail, setRequestorEmail] = useState("");
  // 3. RECORDER EMAIL BULK SEARCH
  const [recorderEmail, SetRecorderEmail] = useState("");
  // 4. RECORDER LIST BULK SEARCH
  const [recorderList, setRecorderList] = useState([]);
  // 5. ARRAY WITH THE HINT SEARCH BOX DATA FOR RECORDER
  const [recorderEmailSearch, SetRecorderSearch] = useState([]);
  // 6. HOST EMAIL INPUT
  const [hostEmail, SetHostEmail] = useState("");
  // 7. ARRAY WITH THE HINT SEARCH BOX DATA FOR CUSTOMER_EMAIL
  const [hostEmailSearch, SetHostEmailSearch] = useState([]);
  const [selectedhostEmail, setSelectedhostEmail] = useState({});

  // 8. RECORDING START DATE INPUT
  const [recordingStartDate, setRecordingStartDate] = useState("");
  // 9. RECORDING END DATE INPUT
  const [recordingEndDate, setRecordingEndDate] = useState("");
  // 10. RECORDING PURPOSE INPUT
  const [recordingPurpose, setRecordingPurpose] = useState("");
  // 11. OBJECT THAT CONTAINS THE VALIDITY STATES FOR ALL THE INPUTS
  const [inputStates, setInputStates] = useState({});
  // 12. OBJECT THAT TURNS ALL FIELDS TO TOUCHED WHEN SUBMIT BUTTON IS CLICKED
  const [touchAll, setTouchAll] = useState(false);
  // 13. STATE THAT DEFINES IF THE SUBMIT REQUEST IS IN PROGRESS
  const [submitInProgress, setSubmitProgress] = useState(false);
  // 14. STATE THAT HOLDS THE RESPONSE STATUS FOR THE REQUEST
  const [submitStatus, setSubmitStatus] = useState(undefined);
  // 15. STATE THAT HOLDS LOADING STATUS TO SEARCH THE RECORDER LIST
  const [loadingRecorderSearchList, setLoadingRecorderSearchList] =
    useState(false);
  // 16. STATE THAT HOLDS THE LIST TO SEARCH
  const [listToSearch, setListToSearch] = useState([]);
  // 17. TEXT AREA INPUT
  const [textAreaInput, setTextAreaInput] = useState("");
  // 18. DOAG 7 APPROVAL CHECKBOX
  const [doag7Approval, setDoag7Approval] = useState(false);
  // 19. LEGAL APPROVAL CHECKBOX
  const [legalApproval, setLegalApproval] = useState(false);
  // 20. DATA PRIVACY APPROVAL CHECKBOX
  const [dataPrivacyApproval, setDataPrivacyApproval] = useState(false);
  // 21. SUBJECT TO LEGAL HOLD OBLIGATIONS CHECKBOX
  const [legalHoldObligations, setLegalHoldObligations] = useState(false);
  // 22. RECORDING  IN ACCORDANCE WITH MANAGEMENT PROTECTION OF INFORMATION MPI CHECKBOX
  const [recordingInAccordanceMPI, setRecordingInAccordanceMPI] =
    useState(false);
  // 23. DATE AS CST
  const [cstDateState, setCstDateState] = useState();

  const [graphAccessToken, setGraphAccessToken] = useState({});
  const [graphAccessTokenError, setGraphAccessTokenError] = useState("");
  const [graphAccessTokenFail, setGraphAccessTokenFail] = useState(false);
  const [graphAccessTokenLoading, setGraphAccessTokenLoading] = useState(false);

  // SETTING LOCAL CONSTANT FOR THE COMPONENT
  // 1. HISTORY TO REDIRECT PAGE
  const history = useHistory();
  // 2. GENERATES TOKEN FOR THE GRAPH API
  const accessToken = useAccessToken();
  // 3. TOKEN FOR CUSTOM WEB API
  const webApiToken = useWebAPIToken();

  // SETTING LOCAL FUNCTIONS FOR THE COMPONENT
  // FUNCTION TO DISABLE PAST DATE TO RECORDING START DATE INPUT
  // CREATE A LOCAL VARIABLE WITH THE CURRENT DATE
  // RETURNING THIS DATE FORMATED AS yyyy-mm-dd
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  // FUNCTION TO DISABLE DATE LESS THAN THE START TO RECORDING END DATE INPUT
  // CREATE A LOCAL DATE VARIABLE WITH THE recordingStartDate
  // IF THERE IS A recordingStartDate RETURNING THIS VALUE
  // IF THERE IS NOT A recordingStartDate RETURNING THE CURRENT DATE
  const disableStartDate = () => {
    let dateStart = new Date(recordingStartDate + "T00:00");

    if (recordingStartDate.length > 0) {
      const dd = String(dateStart.getDate()).padStart(2, "0");
      const mm = String(dateStart.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = dateStart.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    } else {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    }
  };

  // FUNCTION TO FORMAT DATES
  // CONVERT THE date PARAMETER TO THE FORMAT mm/dd/yyyy
  // RETURNING THE FORMATED DATE mm/dd/yyyy
  const formatDate = (date) => {
    var month = date.getMonth() + 1; //months from 1-12
    var day = date.getDate();
    var year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  // FUNCTION TO REDIRECT THE PAGE
  const redirectPage = () => {
    setTimeout(() => {
      history.push(`/zoom-recording-data`);
      
    }, 1000);
  }

  const AccessDateInfo = () => {
    let today = new Date();

    //when you write new Date('2019-06-11'), you actually create a date that says 11th June, 2019, 12am UTC.
    //This is why people who live in areas behind GMT get a 10th June instead of 11th June.

    // If you want to create a date in Local Time with the date-string method,
    // you need to include the time. When you include time, you need to write the HH and mm at a minimum (or Google Chrome returns an invalid date).

    let dateStart = new Date(recordingStartDate + "T00:00");
    var dayBeforeStart = new Date(recordingStartDate + "T00:00");
    var dayAfterEnd = new Date(recordingEndDate + "T00:00");

    dayBeforeStart.setDate(dayBeforeStart.getDate() - 2);
    dayAfterEnd.setDate(dayAfterEnd.getDate() + 1);

    let difDays = dateStart.getDate() - today.getDate();

    if (difDays == 0) {
      return (
        <div className="access-date-message">
          <p>
            The recording access will be immediately granted and will be removed
            at {formatDate(dayAfterEnd)} 11:59 PM Central Time.
            <br />
            <b>Central Standard Time (24h):</b> {cstDateState}
          </p>
        </div>
      );
    } else if (difDays == 1) {
      return (
        <div className="access-date-message">
          <p>
            The recording access will be immediately granted and will be removed
            at {formatDate(dayAfterEnd)} 11:59 PM Central Time.
            <br />
            <b>Central Standard Time (24h):</b> {cstDateState}
          </p>
        </div>
      );
    } else {
      return (
        <div className="access-date-message">
          <p>
            The recording access will be granted at {formatDate(dayBeforeStart)}{" "}
            11:59 PM Central Time and will be removed at{" "}
            {formatDate(dayAfterEnd)} 11:59 PM Central Time.
            <br />
            <b>Central Standard Time (24h)</b> {cstDateState}
          </p>
        </div>
      );
    }
  };

  const handleGetGraphToken = async () => {
    if (webApiToken) {
      setGraphAccessTokenLoading(true);
      try {
        const [getGraphTokenResponse] = await Promise.all([
          getGraphToken(webApiToken),
        ]);
        setGraphAccessToken(getGraphTokenResponse.data);
      } catch (err) {
        console.log("err.message: ", err.message);
        setGraphAccessTokenError(err);
        setGraphAccessTokenFail(true);
      } finally {
        setGraphAccessTokenLoading(false);
      }
    }
  };

  // FUNCTION TO UPDATE THE INPUT STATES
  // USED TO DEFINE IF THE GLOBAL FORM STATE IS VALID OR INVALID
  const GetState = (state) => {
    setInputStates((oldState) => {
      return { ...oldState, ...state };
    });
  };

  // FUNCTION THAT HANDLES THE SUBMISSION OF THE FORM
  const SubmitForm = (e) => {
    e.preventDefault();
    setTouchAll("trigger");
    if (!Object.values(inputStates).includes(false)) {
      let formData = new FormData();

      formData.append("RequestorName", requestor);
      formData.append("RequestorMail", requestorEmail);
      formData.append("RecorderList", JSON.stringify(recorderList));
      // formData.append("RecorderList", JSON.stringify([{"mail":"leonaldo.b.junior@exmglab.com","displayName":"Junior, Leonaldo Barbosa Da Silva"}]));
      formData.append("HostName", selectedhostEmail.displayName);
      formData.append("HostMail", selectedhostEmail.mail);
      // formData.append("HostMail", "leonaldo.b.junior@exmglab.com");
      formData.append("RecordingPurpose", recordingPurpose);
      formData.append("StartDate", `${recordingStartDate}`);
      formData.append("EndDate", `${recordingEndDate}`);

      setSubmitProgress(true);
      PostRecordingException(webApiToken, formData).then((resp) => {
        setSubmitStatus(resp);
        if (resp.status === "success") {
          setTouchAll("submitted");
          SetHostEmail("");
          setSelectedhostEmail({});
          setRecordingStartDate("");
          setRecordingPurpose("");
          redirectPage();
        }
        setSubmitProgress(false);
      });
    } else {
      setSubmitStatus(undefined);
      console.log("Form not filled properly");
    }
  };

  useEffect(() => {
    setRecordingEndDate("");
    disableStartDate();
  }, [recordingStartDate]);

  useEffect(() => {
    handleGetGraphToken();
  }, [webApiToken]);

  useEffect(() => {
    let timeout;
    if (graphAccessToken && recorderEmail.length >= 4) {
      timeout = setTimeout(async () => {
        let data = await FetchGraphData({
          endpoint:
            `/users?` +
            `$search="displayName:${recorderEmail}" OR "mail:${recorderEmail}"&` +
            `$filter=mail ne null&` +
            `$select=displayName,mail&` +
            `$orderby=displayName asc`,
          accessToken: graphAccessToken,
        });
        if (data.value && data.value.length > 0) {
          SetRecorderSearch(
            data.value.map((entry) => {
              return { mail: entry.mail, displayName: entry.displayName };
            })
          );
        }
      }, 300);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [recorderEmail, graphAccessToken]);

  useEffect(() => {
    let timeout;
    if (graphAccessToken && listToSearch.length >= 0) {
      listToSearch.forEach((mail) => {
        setLoadingRecorderSearchList(true);
        timeout = setTimeout(async () => {
          let data = await FetchGraphData({
            endpoint:
              `/users?` +
              `$search="displayName:${mail}" OR "mail:${mail}"&` +
              `$filter=mail ne null&` +
              `$select=displayName,mail&` +
              `$orderby=displayName asc`,
            accessToken: graphAccessToken,
          });
          if (data.value && data.value.length > 0) {
            if (!recorderList.find((user) => user.mail === mail)) {
              data.value.map((entry) => {
                setRecorderList((previousState) => {
                  return [
                    ...previousState,
                    { mail: entry.mail, displayName: entry.displayName },
                  ];
                });
              });
            }
          }
        }, 300);
      });

      setLoadingRecorderSearchList(false);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [listToSearch, graphAccessToken]);

  // SET SEARCH HINT BOX FOR THE hostEmail INPUT FIELD
  // WHEN THE ACCESS TOKEN EXISTS AND hostEmail HAS MORE THAN 4 LETTER,
  // HELP THE USER WITH A HINT BOX
  useEffect(() => {
    let timeout;
    if (graphAccessToken && hostEmail.length >= 4) {
      timeout = setTimeout(async () => {
        let data = await FetchGraphData({
          endpoint:
            `/users?` +
            `$search="displayName:${hostEmail}" OR "mail:${hostEmail}"&` +
            `$filter=mail ne null&` +
            `$select=displayName,mail&` +
            `$orderby=displayName asc`,
          accessToken: graphAccessToken,
        });
        if (data.value && data.value.length > 0) {
          SetHostEmailSearch(
            data.value.map((entry) => {
              return { mail: entry.mail, displayName: entry.displayName };
            })
          );
        }
      }, 300);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hostEmail, graphAccessToken]);

  // requestorEmail HANDLER //
  // CUSTOM FUNCTION TO FETCH DATA FROM /me ENDPOINT FROM GRAPH API
  // WHEN THE HTTP REQUEST IS DONE, requestorEmail IS UPDATED WITH
  // USER DATA RECEIVED FROM GRAPH
  useEffect(() => {
    if (accessToken) {
      FetchGraphData({
        endpoint: "/me",
        accessToken: accessToken,
      }).then((data) => {
        setRequestorEmail(data.mail);
        setRequestor(data.displayName);
      });
    }
  }, [accessToken]);

  useEffect(() => {
    setInterval(
      () => {
        var now = new Date();
        var cst = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        cst.setHours(cst.getHours() - 6);

        let dd = ("0" + cst.getDate()).slice(-2);
        let mm = ("0" + (cst.getMonth() + 1)).slice(-2);
        let yyyyy = cst.getFullYear();
        let hh = ("0" + cst.getHours()).slice(-2);
        let mi = ("0" + cst.getMinutes()).slice(-2);
        let ss = ("0" + cst.getSeconds()).slice(-2);

        setCstDateState(`${mm}/${dd}/${yyyyy} ${hh}:${mi}:${ss}`);
      },

      1000
    );
  }, []);

  // JSX CODE FOR MAIN COMPONENT
  return (
    <div className="RecordingExceptionForm-container">
      <h1>Zoom Recording Form</h1>
      <div className="App-horizontal-line" />
      <br />
      <div className="RecordingExceptionForm-description-text">
        <div>
          This form is for users to submit requests to be granted temporary 
          recording functionality in Zoom to record a meeting or webinar.
          To avoid data leakage, the recording functionality in Zoom is
          blocked by default for all ExxonMobil users. 
        </div>
        <p><b>Important information:</b></p>
        <ul>
          <div className="list-content">
            <li> 
              Access to the recording features in Zoom can be granted only 
              temporarily for a specific period of time
            </li>
            <li>
              To learn more about the recording functionality visit <a 
              href="https://goto/ZoomRecording" target="_blank" rel="noreferrer">
              goto/ZoomRecording
              </a>
            </li>
            <li>
              Due to the sensitivities and risks associated with recordings
              access to the recording feature is strictly regulated and linked
              to several actions.
            </li>
            <li>
              To learn more about ExxonMobil Recording Guidance <a 
              href="https://goto/RecordingGuidelines" target="_blank" rel="noreferrer">
              goto/RecordingGuidelines
              </a> 
            </li>
          </div>
        </ul>

        <p><b>Obtain mandatory permissions:</b></p>

        <ol>
          <div className="list-content">
            <li>
                <b>Business Justification</b> <br></br>
                Ensure you have a valid business justification and proposed recording 
                is not prohibited by the ExxonMobil Recording Guidance <a 
                href="https://goto/RecordingGuidelines" target="_blank" rel="noreferrer">
                goto/RecordingGuidelines
              </a> 
            </li>

            <li>
                <b>Review with Law</b> <br></br>
                Review the proposed recording with your Law contact, which may be found 
                under “Find Your Lawyer” Tab at <a 
                href="https://goto/law" target="_blank" rel="noreferrer">goto/law</a>. 
                Provide as much information as possible about your use case for recording.
                Share what you intend to do with the recording at the conclusion of the 
                event. Be specific about how it will be stored and with whom you 
                intend to share it.          
            </li>

            <li>
                <b>Data Privacy review</b> <br></br>
                Review the proposed recording with the Data Privacy Office (DPO), 
                which may be found at <a href="https://goto/DataPrivacy" target="_blank"
                rel="noreferrer">goto/DataPrivacy</a> by completing the Data Privacy
                Impact Assessment <a href="https://goto/dpmt" target="_blank" 
                rel="noreferrer">goto/dpmt</a>              
            </li>
            <li>
                <b>DOAG 7 Approval</b> <br></br>
                Request DOAG 7 approval (<a href="https://goto/DOAG" target="_blank"
                rel="noreferrer">goto/DOAG</a>). Approval has to be requested from 
                the appropriate DOAG level in the ExxonMobil business entity that 
                will own the proposed recording and such ExxonMobil business entity will
                need to maintain and dispose of such recording in accordance with the 
                Management Protection of Information at <a href="https://goto/mpi" target="_blank" 
                rel="noreferrer">goto/mpi</a> and Records Management Guidelines at <a href="https://goto/rmg" 
                target="_blank" rel="noreferrer">goto/rmg</a>. If the recording will be 
                shared outside the ExxonMobil business entity, DOAG requirements
                associated with the 'Release of Information Outside the Company' 
                should be reviewed and managed accordingly. If the proposed recording relates 
                to subject matter on legal hold, then such recording must be retained.
            </li>
          </div>
        </ol>
      <p><i>Note</i>: All actions above are <b>mandatory</b>.</p>
    </div>


      <form onSubmit={SubmitForm}>
        {graphAccessTokenLoading && (
          <LoadingScreen_V2 loadingMessage="Loading Zoom information."></LoadingScreen_V2>
        )}

        <div className={graphAccessTokenLoading ? "loading" : ""}>
        <p><b>Acknowledgement:</b></p>
          <div className="checkbox">
            <div className="checkbox-item">
              <CheckBox
                label="I have obtained all MANDATORY permissions as above"
                uncheckedMessage="You need approval"
                value={doag7Approval}
                GetState={GetState}
                touchAll={touchAll}
                setValue={setDoag7Approval}
                validationRules={{ set: true }}
              />
            </div>

            <div className="checkbox-item">
              <CheckBox
                label="Subject to Legal Hold obligations, 
                I will dispose of this recording in accordance 
                with the Records Management Guidelines. (Goto/RMG)"
                uncheckedMessage="You need to review with law"
                value={legalApproval}
                GetState={GetState}
                touchAll={touchAll}
                setValue={setLegalApproval}
                validationRules={{ set: true }}
              />
            </div>

            <div className="checkbox-item">
              <CheckBox
                label="I will store this recording in accordance
                with Management Protection of Information (MPI) 
                Guidelines (Goto/MPI)"
                uncheckedMessage="You need to review with Data Privacy"
                value={dataPrivacyApproval}
                GetState={GetState}
                touchAll={touchAll}
                setValue={setDataPrivacyApproval}
                validationRules={{ set: true }}
              />
            </div>

          </div>
          <Input
            label="Requestor"
            type="text"
            placeholder="requestor@exxonmobil.com"
            value={requestorEmail}
            validationRules={{ minLength: 4, includes: "@" }}
            GetState={GetState}
            touchAll={touchAll}
          />

          <BulkSearch
            label="Recorder"
            type="text"
            placeholderInput={"recorder@exxonmobil.com"}
            placeholderTextArea={
              "recorder@exxonmobil.com\nrecorder2@exxonmobil.com\n..."
            }
            value={recorderEmail}
            setValue={SetRecorderEmail}
            validationRules={{
              minLength: 4,
              exactMail: recorderEmailSearch.map((entry) => entry.mail),
            }}
            GetState={GetState}
            touchAll={touchAll}
            autocomplete={recorderEmailSearch}
            setRecorderList={setRecorderList}
            recorderList={recorderList}
            listToSearch={listToSearch}
            setListToSearch={setListToSearch}
            textAreaInput={textAreaInput}
            setTextAreaInput={setTextAreaInput}
            loadingRecorderSearchList={loadingRecorderSearchList}
          />

          <div>
            <AutoComplete
              label="Host (The person that scheduled the meeting)"
              inputValue={hostEmail}
              setInputValue={SetHostEmail}
              note="You can search by name or ExxonMobil email."
              listOfObjects={hostEmailSearch}
              optionKeyValues={["mail"]}
              returnSelectedObj={setSelectedhostEmail}
              toolTipMessage=""
              GetState={GetState}
              touchAll={touchAll}
              userType
            ></AutoComplete>
          </div>

          <Input
            text="You will be given permission 24h before your request"
            label="Recording Start Date"
            type="date"
            value={recordingStartDate}
            setValue={setRecordingStartDate}
            validationRules={{}}
            GetState={GetState}
            touchAll={touchAll}
            min={disablePastDate()}
          />

          <Input
            disabled={recordingStartDate.length === 0}
            text=""
            label="Recording End Date"
            type="date"
            value={recordingEndDate}
            setValue={setRecordingEndDate}
            validationRules={{}}
            GetState={GetState}
            touchAll={touchAll}
            min={disableStartDate()}
          />

          {recordingStartDate.length > 0 && recordingEndDate.length > 0 && (
            <AccessDateInfo></AccessDateInfo>
          )}

          <div className="input-textarea">
            <Input
              label="Recording Purpose"
              type="textarea"
              placeholder="enter text here"
              value={recordingPurpose}
              setValue={setRecordingPurpose}
              validationRules={{ minLength: 4 }}
              GetState={GetState}
              touchAll={touchAll}
            />
          </div>

          <div className="flex-row message-container">
            {!submitInProgress ? (
              <input
                className="RecordingExceptionForm-button"
                type="submit"
                value="Submit"
              />
            ) : (
              <div className="RecordingExceptionForm-button inactive">
                <CircleLoader size="40px" color="white" />
              </div>
            )}
            {submitStatus && submitStatus.status === "success" && (
              <div className="message valid">
                Form successfully submitted! <br />
                Entry GUID: {submitStatus.entry}
              </div>
            )}
            {submitStatus && submitStatus.status === "failed" && (
              <div className="message invalid">
                Error during form submission! <br />
                Reason: {submitStatus.error} <br />
                Message: {submitStatus.message}
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default RecordExceptionForm;
