import { customAPIConfig } from "../AppConfig";
export const GetFilteredRooms = async (access_token,LocationId) => {
    const options = {
      method: "GET",
      headers: { Authorization: `bearer ${access_token}` },
    };
    const resp = await fetch(
      `${customAPIConfig.customAPIBaseEndpoint}/CodeRegeneration/GetFilteredRooms?LocationId=` + LocationId,
      options
    );
    const resp_data = await resp.json();
    return resp_data;
  };

  export const ResetRoomCode = async (access_token,formData) => {
    const options = {
      method: "POST",
      body : formData,
      headers: { 
        Authorization: `bearer ${access_token}`
      },
    };
    const resp = await fetch(
      `${customAPIConfig.customAPIBaseEndpoint}/CodeRegeneration/ResetRoomCode`,
      options
    );
    const resp_data = await resp.json();
    return resp_data;


  };
  
  export const GetCache = async (access_token) => {
    const options = {
      method: "GET",
      headers: { 
        Authorization: `bearer ${access_token}`
      },
    };
    const resp = await fetch(
      `${customAPIConfig.customAPIBaseEndpoint}/CodeRegeneration/GetCacheData`,
      options
    );
    const resp_data = await resp.json();
    return resp_data;


  };