import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import LoadingScreen_V2 from "../../Components/LoadingScreen_V2"
import MoveCard from "../../Components/MoveCard/MoveCard"

//api calls
// import GetZoomPhoneSites from "../../Hooks/funcGetZoomPhoneSites";
import useAccessToken from "../../Hooks/useAccessToken";
import useWebAPIToken from "../../Hooks/useWebAPIToken";

import {Link} from "react-router-dom";


import PhoneMoveFormWrapper from "./PhoneMoveForm.css";
import { useFetch } from "../../Hooks/useFetch";


import { ZoomCountriesAbbreviationListWithIAMfixes } from "../../Assets/Data/ZoomCountriesAbbreviationListWithIAMfixes"
import RequestedBy from "../../Components/RequestedBy/RequestedBy";
/////////////////////////MAIN COMPONENT/////////////////////////////////

const PhoneMoveForm = () => {
  //#region vars and setters
    // SETTING LOCAL STATE FOR THE COMPONENT
    const [requestedByEmail , setRequestedByEmail] = useState(null);

    const [urlGetPhoneAccountInfo , setUrlGetPhoneAccountInfo] = useState(null);
    const [userCountryZoomFormat , setUserCountryZoomFormat] = useState(null);
    const [foundCountryInPhoneSites , setFoundCountryInPhoneSites] = useState(null);

    const [userPhoneState, setUserPhoneState] = useState(null);

    const [isLoading, setIsLoading] = useState(false); 
    
    const [showWarningPhoneWillReturned, setShowWarningPhoneWillReturned] = useState(false);
    const [showWarningIDM, setShowWarningIDM] = useState(true);
    
      
    const { data: zoomSitesAddresses = null, error: errorZoomSitesAddresses = null, isFetching: loadingZoomSitesAddresses = null } = useFetch("/PhoneRequest/GetEmergencyAddressList");
    const { data: userCountry = null, error: errorUserCountry = null, isFetching: loadingUserCountry = null } = useFetch(`/PhoneRequest/GetCurrentUserCountry?userEmail=`);
    const { data: userPhone = null, error: userPhoneError = null, isFetching: userPhoneLoading = null } = useFetch(urlGetPhoneAccountInfo);
    const { data: phoneSitesOnboarded = null, error: phoneSitesOnboardedError = null, isFetching: phoneSitesOnboardedLoading = null } = useFetch("/PhoneSiteOnboard/GetPhoneSitesOnboarded");
    
    useEffect(() => {
      userPhoneState?.phoneNumbers.length > 0 ? setShowWarningPhoneWillReturned(true) : setShowWarningPhoneWillReturned(false);

    },[userPhoneState])
    
    // SETTING LOCAL CONSTANT FOR THE COMPONENT
    // 1. HISTORY TO REDIRECT PAGE
    const history = useHistory();
    // 2. GENERATES TOKEN FOR THE GRAPH API
    const accessToken = useAccessToken();
    // 3. TOKEN FOR CUSTOM WEB API
    const webApiToken = useWebAPIToken();

  //#endregion vars and setters


  //#region Functions

    
    const findZoomCountryCode = (country) =>{
      return ZoomCountriesAbbreviationListWithIAMfixes.find( site => site.Name.toLocaleLowerCase() === country.toLocaleLowerCase())
    }

    const isThereAnySite = (countryCode) =>{
      return zoomSitesAddresses.some( site => site.country.toLocaleLowerCase() === countryCode.toLocaleLowerCase())
    }
   

  //#endregion Functions

  //#region useEffect
    
    useEffect(()=>{      
        setIsLoading( loadingZoomSitesAddresses  || userPhoneLoading || loadingUserCountry || phoneSitesOnboardedLoading);     
    },[loadingZoomSitesAddresses, loadingUserCountry, userPhoneLoading, phoneSitesOnboardedLoading])    

    useEffect(()=>{
      if(requestedByEmail){
        setUrlGetPhoneAccountInfo(`/PhoneRequest/GetPhoneAccountInfo?userEmail=${requestedByEmail}`)
      }
    },[requestedByEmail])

    useEffect(()=>{
      setUserPhoneState(userPhone)
    },[userPhone])
  
    useEffect(()=>{
      if(userCountry){
        setUserCountryZoomFormat(findZoomCountryCode(userCountry))
      }
    },[userCountry])

    useEffect(()=>{
      if(userCountryZoomFormat && zoomSitesAddresses){
        setFoundCountryInPhoneSites(isThereAnySite(userCountryZoomFormat.ID))
      }
    },[userCountryZoomFormat, zoomSitesAddresses])
  

  //#endregion useEffect

  // JSX CODE FOR MAIN COMPONENT
  return (
    <PhoneMoveFormWrapper>    
      <h1>Zoom Phone Location Update Form</h1>
      <br />
      <p> 
        The Zoom Phone Location Update form is used to change your location so that Zoom
        can correctly assign a new local phone number.
      </p>

      <p> 
        if you already have a Zoom number and are moving to a new location, your number 
        will be returned to the pool and you will need to access the <Link target="_blank" to="/phone-request">
        Zoom Phone Request form </Link> to request
        a new number for your new location.
      </p>

      <p> 
        I You may not make location update requests on behalf of others.
      </p>
 
      <p> 
        You will receive a confirmation email and you can also verify your location update
        request was submitted by viewing the <Link target="_blank" to="/phone-request-logs">
        Submitted Zoom Phone Requests page.</Link> 
      </p>

      <div>      
        { isLoading && (<LoadingScreen_V2 loadingMessage="Loading zoom information..."></LoadingScreen_V2>)}

        <div className={isLoading ? 'PhoneMoveForm-loading' : ''}>
          
          <RequestedBy setRequestedByEmail={setRequestedByEmail}/>

          <div> {/* Show results */}

            {/* Show warning IDM country */}
            { showWarningIDM && (
              <div id="warning-get-phone-msg">
                <div className="App-em-c-alert App-em-c-alert--warning">
                  <div className="App-em-c-alert__body">
                    Notice: The Country field populates from IDM. If the listed country is not correct,
                    you must first update in IDM, wait 24 hours to populate, then try this form again. 
                    When the Country updates from IDM, you may complete your Zoom location move here. 
                  </div>
                  <div className="App-em-c-alert__actions">
                    <button 
                      type="button"
                      className="App-em-c-text-btn App-em-c-alert--warning"
                      onClick={ () => setShowWarningIDM(false)}
                    >
                        Close
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Show warning user could lost number */}
            { showWarningPhoneWillReturned && (
              <div id="warning-get-phone-msg">
                <div className="App-em-c-alert App-em-c-alert--warning">
                  <div className="App-em-c-alert__body">
                    Notice: You have an assigned Zoom number { userPhoneState?.phoneNumbers.map(elm => " " + elm.number).toString() } &nbsp;
                    which will be released when you change locations. 
                    If you do not wish to continue, close out of the form. 
                  </div>
                  <div className="App-em-c-alert__actions">
                    <button 
                      type="button"
                      className="App-em-c-text-btn App-em-c-alert--warning"
                      onClick={ () => setShowWarningPhoneWillReturned(false)}
                    >
                        Close
                    </button>
                  </div>
                </div>
              </div>
            )} 

            {/* Show Error get site addresses */}
            {errorZoomSitesAddresses && (
              <div className="App-em-c-alert App-em-c-alert--error">
                <div className="App-em-c-alert__body">
                  <p>
                    Could  not get zoom phone site addresses. There is an error. Please try again. 
                    If not resolved, please <a target="_blank" href={"https://goto/GenericSR"}>goto/GenericSR</a>&nbsp;
                    to submit a ticket and assign to Zoom Support group or contact the &nbsp; 
                    <a target="_blank" href="https://goto/itservices">Help Desk</a>. <br></br>
                    { errorZoomSitesAddresses?.message && "Error message: " + errorZoomSitesAddresses.message}                                                      
                  </p>
                </div>
              </div>
            )}
            
            {/* Show Error cannot found phone sites for that country */}
            {!foundCountryInPhoneSites && !loadingUserCountry && !loadingZoomSitesAddresses && !errorZoomSitesAddresses && !errorUserCountry &&(
              <div className="App-em-c-alert App-em-c-alert--error">
                <div className="App-em-c-alert__body">
                  <p>
                    Sorry the user's location is in a Country ({userCountry}) that has not yet implemented external calling. 
                    You cannot request a Zoom location update at this time.
                  </p>
                </div>
              </div>
            )}

            {/* Show Error phone account not found*/}
            { phoneSitesOnboardedError && (
              <div className="App-em-c-alert App-em-c-alert--error">
                <div className="App-em-c-alert__body">
                  <p>
                    There is an error. Please refresh the page and try again. 
                    If not resolved, please <a target="_blank" href={"https://goto/GenericSR"}>goto/GenericSR</a>&nbsp;
                    to submit a ticket and assign to Zoom Support group or contact the &nbsp; 
                    <a target="_blank" href="https://goto/itservices">Help Desk</a>. <br></br>
                  </p>
                </div>
              </div>
            )}

            {/* Show Error Fail get zoom account*/}
            { userPhoneError && userPhoneError.response?.status !== 404 && (
              <div className="App-em-c-alert App-em-c-alert--error">
                <div className="App-em-c-alert__body">
                  <p>
                    Could not get zoom user account.There is an error. Please try again. 
                    If not resolved, please <a target="_blank" href={"https://goto/GenericSR"}>goto/GenericSR</a>&nbsp;
                    to submit a ticket and assign to Zoom Support group or contact the &nbsp; 
                    <a target="_blank" href="https://goto/itservices">Help Desk</a>. <br></br>
                    { userPhoneError?.message && "Error message: " + userPhoneError.message}                                                      

                    </p>
                </div>
              </div>
            )}

            {/* Show Error Fail get user country */}
            {errorUserCountry && errorUserCountry?.response?.status === 404 && (
              <div className="App-em-c-alert App-em-c-alert--error">
                <div className="App-em-c-alert__body">
                  <p>
                    Sorry we could't find user's country from IDM, you must first update 
                    <a target="_blank" href="https://idm.na.xom.com/">goto/IDM</a> with your current  location, 
                    wait 24 hours to populate, then you may update your Zoom phone location.
                  </p>
                </div>
              </div>
            )}
             
            {/*Show Error Fail get user country */ }
            {errorUserCountry && errorUserCountry?.response?.status !== 404 && (
              <div className="App-em-c-alert App-em-c-alert--error">
                <div className="App-em-c-alert__body">
                  <p>
                    Could not get the country from IDM. There is an error. Please try again. 
                    If not resolved, please <a target="_blank" href={"https://goto/GenericSR"}>goto/GenericSR</a>&nbsp;
                    to submit a ticket and assign to Zoom Support group or contact the &nbsp; 
                    <a target="_blank" href="https://goto/itservices">Help Desk</a>. <br></br>
                    { errorUserCountry?.message && "Error message: " + errorUserCountry.message}                                                      
                  </p>
                </div>
              </div>
            )}



          </div> {/* Show results */} 

          
          <div className="move-card-container">
            {userPhoneState && zoomSitesAddresses && foundCountryInPhoneSites && phoneSitesOnboarded && !isLoading &&(
              <MoveCard 
                user={userPhoneState}
                setUser={setUserPhoneState}  
                userCountry = {userCountryZoomFormat}                 
                allSitesLocations = {zoomSitesAddresses}
                isLoading = {isLoading}
                phoneSitesOnboarded = {phoneSitesOnboarded}
              />     
            )}
          </div>

        </div>

      </div>{/* end of loading div */}
    </PhoneMoveFormWrapper>
  );

};

export default PhoneMoveForm;

