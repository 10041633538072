import React from "react";
import styled from 'styled-components'


const ModalWindow = styled.div`
    width: 40em;
    max-width: 90%;
    max-height: 90vh;
    background: #fff;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--paletteSurface00, #fff);   
    color: var(--paletteTextBase, #111122);
    padding: 1.5rem;
    border-radius: var(--content-border-radius);
    z-index: 10;
    box-shadow: var(--box-shadow-main);


    .modal-header {
      display: flex;
      align-items: flex-start;
    }
    .modal-body {
      padding: 1rem;
      border-top: 1px solid #e5e5e5;
    }
    .btn-popup {
      color: var(--colorsBlueBase, #0c69b0);
      background: none;
      
      padding: 2px 2px;
      margin: 5px;
      font-size: 18px;
      font-weight: 600;
      transition: all 0.5s;
      border: 1px solid var(--paletteSurface04, #cccccd);
      border-radius: 4px;
      transition: all 0.5s;
      height: 40px;
      width: 80px;
      text-align: center;
    }

    .btn-popup-invalid{
      color:var(--paletteSurface03, #e5e5e5);
    }
    

    .btn-popup:hover {
      background: var(--paletteSurface03, #e5e5e5);
      border: 1px solid var(--colorsBlueBase, #cccccd);
    }
    
    .btn-popup-disable {
      background: var(--paletteSurface03, #e5e5e5);
    }


`

    

export default function PopupWithInput(props){

    // FUNCTION THAT CHANGES THE VALUE FOR THE INPUT EVERY TIME THE USER HITS THE KEYBOARD
    const changeHandler = (e) => {
      if (props.setValue) {
        props.setValue(e.target.value);
      }
    };

    return(
      <div>
        { props.showPopUp? 
        (        
          <ModalWindow className="ModalWindow">
            <div className="modal-header">
              <h3>{props.title}</h3>
              <input type="button" value="Close" className="btn-popup" onClick={ () => { props.setShowPopUp(false)} }/>

            </div>
            <div className="modal-body">
              <p>{props.message}</p>

              <div>
                <textarea
                  rows="5"
                  cols="50"
                  
                  onChange={changeHandler}
                  value={props.value}
                  placeholder={props.placeholder}
                />
              </div>
              <input disabled={props.value.length < 3} type="button" value="Yes" className={props.value.length < 3 ? "btn-popup btn-popup-invalid" : "btn-popup"} onClick={ () => { 
                props.setShowPopUp(false);
                props.submitForm()
                } }/>
              <input type="button" value="No" className="btn-popup" onClick={ () => { 
                props.setShowPopUp(false);
                props.cancelCallback();
                } }/>
            </div>
          </ModalWindow>
        ) : (null) }


      </div>
  )
}