import { useState, useRef, useEffect } from "react"
import { MoveCardWrapper } from "./MoveCard.css.js"

import {Link} from "react-router-dom";

//Zoom Lists
import { ZoomCountriesAbbreviationList } from "../../Assets/Data/ZoomCountriesAbbreviationList"
import { ZoomStatesAbbreviationList } from "../../Assets/Data/ZoomStatesAbbreviationList"

//Locations Lists
//Locations Lists
import { 
  uniqueEmergencyStateList, 
  uniqueEmergencyCityList, 
  uniqueEmergencyAddressList, 
  uniqueEmergencyCityListByCountry,
  uniqueEmergencyAddressListByState,
  uniqueEmergencyAddressListByCountry
} from "../../Assets/Data/EmergencyAddressesList"

//tokens
import useAccessToken from "../../Hooks/useAccessToken";
import useWebAPIToken from "../../Hooks/useWebAPIToken";

import { useHistory } from "react-router";

import PostMoveUserSite from "../../Hooks/funcMoveUserSite"

//Components
import AutoComplete from '../AutoComplete/AutoComplete';
import Input from "../Input";
import PopUp from "../Popup";
import LoadingScreen_V2 from "../LoadingScreen_V2"

const MoveCard = (
  {
    user,
    setUser,
    allSitesLocations,
    phoneSitesOnboarded,
    setIsValidToSubmit,
    showOption,
    userCountry,
    isLoading,
    ...rest
  }) =>{

  //#region const state

    // Move request
    const [moveUserError, setMoveUserError] = useState(null);
    const [inputStatesForChangeSite, setInputStatesForChangeSite] = useState({});
    const [showMoveMoveFail, setShowMoveFail] = useState(false);
    
    const [sitesLocations, setSitesLocations] = useState([]);
    const [userSiteLocation, setUserSiteLocation] = useState(null);
 
    // Input and select for change site

    const [inputStateForChangeSite, setInputStateForChangeSite] = useState("");
    const [selectedInputStateForChangeSite, setSelectedInputStateForChangeSite] = useState({});

    const [inputCityForChangeSite, setInputCityForChangeSite] = useState("");
    const [selectedInputCityForChangeSite, setSelectedInputCityForChangeSite] = useState({});
    
    const [inputAddressForChangeSite, setInputAddressForChangeSite] = useState("");
    const [selectedInputAddressForChangeSite, setSelectedInputAddressForChangeSite] = useState({});

    //States use to check if the form has properly fill
    const [touchAllForChangeSite, setTouchAllForChangeSite] = useState("");

    const [statesOptions, setStatesOptions] = useState([]);
    const [citiesOptions, setCitiesOptions] = useState([]);
    const [addressesOptions, setAddressesOptions] = useState([]);
    const [moveUserConfirmationPopUp, setMoveUserConfirmationPopUp] = useState(false);
    
    const [submitTriggered, setSubmitTriggered] = useState(false);

    const [moveRequestLoading, setMoveRequestLoading] = useState(false);
    const [showMoveSuccess, setShowMoveSuccess] = useState(false);

    // SETTING LOCAL CONSTANT FOR THE COMPONENT
    // 1. HISTORY TO REDIRECT PAGE
    const history = useHistory();
    // 2. GENERATES TOKEN FOR THE GRAPH API
    const accessToken = useAccessToken();
    // 3. TOKEN FOR CUSTOM WEB API
    const webApiToken = useWebAPIToken();

  //#endregion const state

  //#region useEffect

    
  useEffect(() => { 
    if(user?.siteId){
      setSitesLocations(allSitesLocations.filter(site => !site.siteName.toLowerCase().includes('test'))
                                          .filter(site => !site.siteName.includes('00_'))
                                            .filter(site => site.siteId !== user.siteId))
    }
  },[allSitesLocations, user])

  useEffect(()=>{
    if(allSitesLocations.length > 0 && user?.email){
      let userSite = allSitesLocations.find(location => location.siteId == user.siteId)
      
      if(userSite?.siteName){
        setUserSiteLocation(userSite)
      }
    }
    // value={   }

  },[allSitesLocations, user])

    // CUSTOM FUNCTION TO RESET CITY AND ADDRESS IF CHANGE THE STATE
    useEffect(() => {
      if(inputStateForChangeSite.length === 0){
        setSelectedInputStateForChangeSite({})
      }

      setCitiesOptions([]);
      setAddressesOptions([]);

      setInputCityForChangeSite("");
      setSelectedInputCityForChangeSite({});

      setInputAddressForChangeSite("");
      setSelectedInputAddressForChangeSite({});

    }, [inputStateForChangeSite]);

    // CUSTOM FUNCTION TO RESET ADDRESS IF CHANGE THE CITY
    useEffect(() => {
      if(inputCityForChangeSite.length === 0){
        setSelectedInputCityForChangeSite({})
      }

      setAddressesOptions([]);

      setInputAddressForChangeSite("");
      setSelectedInputAddressForChangeSite({});

    }, [inputCityForChangeSite]);

    useEffect(() => {
      let stateList;

      stateList = uniqueEmergencyStateList(sitesLocations, userCountry.ID);

      if(stateList.length > 0){
        setStatesOptions(stateList.filter(location => location?.stateCode.length > 0));
      }
      
    },[sitesLocations])

    useEffect(() => {
      if(statesOptions.length > 0){
        let cityList = uniqueEmergencyCityList(sitesLocations, selectedInputStateForChangeSite.stateCode);
        if(cityList){
          setCitiesOptions(cityList.filter(location => location?.city.length > 0));
        }
      }
      else{
        let cityList = uniqueEmergencyCityListByCountry(sitesLocations, userCountry.ID);
        if(cityList){
          setCitiesOptions(cityList.filter(location => location?.city.length > 0));
        }
      }
    },[statesOptions, selectedInputStateForChangeSite])

    useEffect(() => {
      let addressList;
      if(citiesOptions.length > 0){
        addressList = uniqueEmergencyAddressList(sitesLocations, selectedInputCityForChangeSite.city);
        if(addressList){
          setAddressesOptions(addressList);
        }
      }
      else{
        if(statesOptions.length > 0){
          addressList = uniqueEmergencyAddressListByState(sitesLocations, selectedInputStateForChangeSite.stateCode);
          if(addressList){
            setAddressesOptions(addressList);
          }
        }
        else{
          addressList = uniqueEmergencyAddressListByCountry(sitesLocations, userCountry.ID);
          if(addressList){
            setAddressesOptions(addressList);
          }
        }
      }
    },[citiesOptions, selectedInputCityForChangeSite])
   
  //#endregion useEffect

  //#region functions

    // React ref to store ref
    const updateZoomSiteLocation = useRef(null)

    const ExecuteScroll = () => updateZoomSiteLocation.current.scrollIntoView()   

    //Zoom phone account info return only the country code, this function replace the country code to contry name
    const ReplaceCountryCodeToCountryName = (countryCode) => {
      let countryObj =  ZoomCountriesAbbreviationList.find( country => country.ID === countryCode)
      if( countryObj ) {
        return countryObj.Name
      }
      return countryCode
    }

    //Zoom phone account info return only the state code, this function replace the state code to contry name
    const ReplaceStateCodeToStateName = (stateCode) => {
      let stateObj =  ZoomStatesAbbreviationList.find( state => state.ID === stateCode)
      if( stateObj ) {
        return stateObj.Name
      }
      return stateCode
    }
   
    // FUNCTION TO UPDATE THE INPUT STATES
    // USED TO DEFINE IF THE GLOBAL FORM STATE IS VALID OR INVALID
    const GetStateForChangeSite = (state) => {
      setInputStatesForChangeSite((oldState) => {
        return { ...oldState, ...state };
      });
    };

    // move user
    const HandleMoveUser = async () => {
      if(webApiToken){
          try {
            setMoveRequestLoading(true);
            setSubmitTriggered(true);

            let formData = new FormData();
            formData.append("userId", user.email);
            formData.append("targetSiteId", selectedInputAddressForChangeSite.siteId);       
            formData.append("siteCode", user.extensionNumber.toString().substring(0,4));       
            
            const [moveUserResp] = await Promise.all([
              PostMoveUserSite(webApiToken, formData),
            ]);
  
            setShowMoveSuccess(true);
            setUser(moveUserResp.data) 
          }
          catch (err) {
            console.log("err.message", err.message);
            setMoveUserError(err);
            setShowMoveFail(true);
          } 
          finally {
            setMoveRequestLoading(false);  
          }
      }
    }

    //Before submit request to move a user, check if all the fields is valid 
    const checkFormMoveUser = () => {
      setTouchAllForChangeSite("trigger"); 

      Object.values(inputStatesForChangeSite).includes(false) 
        ? setMoveUserConfirmationPopUp(false) 
        : setMoveUserConfirmationPopUp(true)
    } 

      // CHECK IF THE USER IS IN WAVE 1 SITES WITH ZOOM PHONE,
  // IF NOT RETURN AN OBJECT WITH THE USER EMAIL AND showErrorZoomPhoneNotEnabled AS TRUE
  const CheckIfTheUserIsInWave1SiteList = () => {
    let isOnboardedSite = phoneSitesOnboarded.some(
      (site) => selectedInputAddressForChangeSite.siteId === site.siteId
    );
    return !isOnboardedSite;    
  };



  return(
    <MoveCardWrapper>

      {/* Success Result message for move user */}
      {showMoveSuccess && (
        <div className="MoveCard-em-c-alert MoveCard-em-c-alert--success">
          <div className="MoveCard-em-c-alert__body">
            Congratulations! User has been successfully moved to a new Zoom phone location. <Link target="_blank" to="/phone-request-logs">Click here
            </Link> to verify your request was submitted. You can see below your new Zoom phone location.
          </div>
        </div>
      )}

      {/* Fail Result message for move user */}
      {moveUserError && showMoveMoveFail &&(
        <div className="MoveCard-em-c-alert MoveCard-em-c-alert--error">
          <div className="MoveCard-em-c-alert__body">
            <p>
              Could not move update the user "{user.email}" to a new location, Try again later, if the problem persists, reach an administrator of Zoom Requests Portal.
              <br/>Error message: {moveUserError.message}
            </p>
          </div>
          <div className="MoveCard-em-c-alert__actions">
            <button 
              className="MoveCard-em-c-text-btn"
              onClick={() => setShowMoveFail(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Change zoom site */}
      <div ref={updateZoomSiteLocation}>
        {!moveUserError &&
        (

          <div className="loading-sites-container">{/* div body with all components */}
          <label>Update Zoom phone location:</label>
            <div className={`MoveCard-row-section ${moveRequestLoading? "loading": ""}`}>
              {/*  Display the current user phone info */}
              {userSiteLocation &&(
                <div className="MoveCard-zoom-account-info"> {/*Input components */}
                  <h5>Current Zoom phone location</h5>
                  {/* Country */}
                  <Input
                      note="Current country for Zoom phone location."
                      label="Country"
                      type="text"
                      placeholder="Site Country Name"
                      value={ReplaceCountryCodeToCountryName(userSiteLocation.country)}
                      disabled
                      toolTipMessage=""
                    />
                  {/* State */}
                  <Input
                    note="Current state for Zoom phone location."
                    label="State (Province)"
                    type="text"
                    placeholder="Site State (Province) Name"
                    value={ReplaceStateCodeToStateName(userSiteLocation.stateCode)}
                    disabled
                    toolTipMessage=""
                  />
                  {/* City */}
                  <Input
                    note="Current city for your Zoom phone location."
                    label="City"
                    type="text"
                    placeholder="Site City Name"
                    value={userSiteLocation.city}
                    disabled
                    toolTipMessage=""
                  />
                  {/* Zoom Phone Site Name */}
                  <Input
                    note="This is the name of your zoom phone location."
                    label="Zoom phone location name"
                    type="text"
                    placeholder="Location Name"
                    value={ userSiteLocation.siteName }
                    toolTipMessage=""
                    disabled
                  />
                </div>/*Input components */
              )}

              {/*  Display option to user update the location */}
              {!showMoveSuccess &&(
                <div className="MoveCard-zoom-account-info">
                <h5>New Zoom phone location</h5>
                  {/* Country */}
                  <Input
                    note="This is your country from identity manager (IDM)"
                    label="IDM Country"
                    type="text"
                    placeholder="IDM country"
                    value={userCountry.Name}
                    // value={user.country}
                    disabled
                    toolTipMessage="Your current country from identity manager"
                  />
                
                  {/* Select State */}
                  {statesOptions.length > 0 && (
                    <AutoComplete
                      label="Select a State (Province)"
                      inputValue={inputStateForChangeSite}
                      setInputValue={setInputStateForChangeSite}
                      note="Please search by state name."
                      listOfObjects={statesOptions}
                      optionKeyValues={['stateName']}
                      returnSelectedObj={setSelectedInputStateForChangeSite}
                      toolTipMessage = ""
                      GetState={GetStateForChangeSite}
                      touchAll={touchAllForChangeSite}

                    >
                    </AutoComplete>
                      
                  )}
                  {/* Select City */}
                  {citiesOptions.length > 0 && (
                    <AutoComplete
                      label="Select a City"
                      inputValue={inputCityForChangeSite}
                      setInputValue={setInputCityForChangeSite}
                      note="Please search by city name."
                      listOfObjects={citiesOptions}
                      optionKeyValues={['city']}
                      returnSelectedObj={setSelectedInputCityForChangeSite}
                      toolTipMessage=""
                      GetState={GetStateForChangeSite}
                      touchAll={touchAllForChangeSite}
                    >
                    </AutoComplete>
                  )}

                  {/* Select Zoom Phone Site */}
                  {addressesOptions.length > 0 && (
                    <AutoComplete
                      label="Select a Zoom Phone Location"
                      inputValue={inputAddressForChangeSite}
                      setInputValue={setInputAddressForChangeSite}
                      note="Please search by Zoom phone location name."
                      listOfObjects={addressesOptions}
                      optionKeyValues={['siteName']}
                      returnSelectedObj={setSelectedInputAddressForChangeSite}
                      toolTipMessage=""
                      GetState={GetStateForChangeSite}
                      touchAll={touchAllForChangeSite}
                    >
                    </AutoComplete>
                  )}

                  <div id="notfound-location">
                    <p>If you cannot find the location, <a target="_blank" href={"https://goto/GenericSR"}>goto/GenericSR</a> to submit a ticket and assign to Zoom Support group or contact the <a target="_blank" href="https://goto/itservices">Help Desk</a>.</p>
                  </div>

                </div>
              )} 
            </div>   

            {/*Button Section */}
            {!submitTriggered && (
              <div className="MoveCard-btn-row-section">
                <button id="MoveCard-btn-save-changes" className="MoveCard-btn MoveCard-btn-medium"
                  onClick={() => {checkFormMoveUser()}}
                  disabled={ isLoading }
                  type="button"
                >
                  Save change
                </button>
              </div>
            )} 

            { moveRequestLoading 

            && (
              <div className="loading-sites">
                <LoadingScreen_V2 loadingMessage="Processing move request..."></LoadingScreen_V2>
              </div>
            )}

          </div>   

        )}
              
        {moveUserConfirmationPopUp && (
          <PopUp
            title="Change Zoom Phone Location"
            message={CheckIfTheUserIsInWave1SiteList() ? "The new location has not yet implemented external calling, you will not be able to request a number for this user at this time. Do you want to continue with the location update?" : "Are you sure you want to update the location?"}
            showPopUp={moveUserConfirmationPopUp}
            setShowPopUp={setMoveUserConfirmationPopUp}
            cancelCallback={() => setMoveUserConfirmationPopUp(false)}
            submitForm={HandleMoveUser}
          >
          </PopUp>
        )}
      </div>



    </MoveCardWrapper>
  )
}

export default MoveCard