import styled, { css } from "styled-components";

const SVGStyle = styled.svg`
  ${(props) =>
    props.specificSize &&
    css`
      width: ${props.specificSize}px !important;
    `};
`;
export const SVGcsv = (props) => {
  return (
    <SVGStyle
      specificSize={props.specificSize}
      aria-hidden="true"
      data-prefix="far"
      data-icon="file-csv"
      viewBox="0 0 384 512"
    >
      <path
        fill="currentColor"
        d="M369.83 97.98L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V131.97c0-12.69-5.17-24.99-14.17-33.99zM255.95 51.99l76.09 76.08h-76.09V51.99zM336 464.01H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v287.95zM224 264v20.8c0 35.48 12.88 68.89 36.28 94.09 3.02 3.25 7.27 5.11 11.72 5.11s8.7-1.86 11.72-5.11c23.41-25.2 36.28-58.61 36.28-94.09V264c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v20.8c0 20.27-5.7 40.17-16 56.88-10.3-16.7-16-36.61-16-56.88V264c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8zm-104-8h-8c-26.51 0-48 21.49-48 48v32c0 26.51 21.49 48 48 48h8c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-8c-8.84 0-16-7.16-16-16v-32c0-8.84 7.16-16 16-16h8c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zm59.45 42.47c-1.38-1.19-2.12-2.55-2.12-3.84 0-3.12 4.45-6.62 10.41-6.62H200c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-12.27c-23.39 0-42.41 17.33-42.41 38.62 0 10.66 4.86 20.92 13.33 28.14l21.89 18.77c1.38 1.19 2.12 2.55 2.12 3.84 0 3.12-4.45 6.62-10.41 6.62H160c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h12.27c23.39 0 42.41-17.33 42.41-38.62 0-10.66-4.86-20.92-13.33-28.14l-21.9-18.77z"
      />
    </SVGStyle>
  );
};
