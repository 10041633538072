import axios from "axios"; // eslint-disable-line
import { customAPIConfig } from "../AppConfig";

export default async function createAPI(token) {
  try {
    return axios.create({
      baseURL: `${customAPIConfig.customAPIBaseEndpoint}`, // the baseURL already includes a trailing forward slash
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(error);
  }
}

export const getError = (err) => {
  // if (err && err.response && err.response.data && err.response.data.message) {
  //   return new Error(err.response.data.message);
  // }
  // return new Error(err.message);

  return err;
};

export const httpStatus = Object.freeze({
  NoContent: 204,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  InternalServerError: 500,
});