import styled from "styled-components";

const PhoneRequestForeignForm = styled.div`
  background-color: var(--primary-white-light);
  padding: var(--content-padding);
  border-radius: var(--content-border-radius);
  margin: var(--content-margin);
  box-shadow: var(--box-shadow-main);
  min-height: 35rem;

  p,
  li,
  h1 {
    color: var(--black);
  }

  label {
    font-size: 17px;
    font-weight: 600;
  }

  .marginLeftText {
    margin-left: 8px;
    border-radius: 10px;
    width: 90%;
  }

  .marginLeftLabel {
    margin-left: 15px;
    font-size: 17px;
    font-weight: 600;
  }

  .btn {
    background-color: var(--primary-blue);
    color: var(--white);
    border: var(--primary-blue) solid 2px;
    padding: 10px 50px;
    margin: 15px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    box-shadow: 2px 2px 5px #0000008c;
    border-radius: 4px;
    width: 163px;
    height: 51px;
  }

  .btnCenter {
    padding-top: 25px;
  }

  .btn-small {
    font-size: 16px;
    width: 4rem;
    height: 2.5rem;
    padding: 5px;
    margin: 5px;
    text-align: center;
  }

  .btn-large {
    background-color: var(--primary-blue);
    color: var(--white);
    border: var(--primary-blue) solid 2px;
    font-size: 16px;
    width: 180px;
    height: 51px;
    padding: 10px 10px;
    text-align: center;
    margin: 8px 15px;
  }

  .btn-medium {
    background-color: var(--primary-blue);
    color: var(--white);
    border: var(--primary-blue) solid 2px;
    font-size: 16px;
    width: 130px;
    height: 51px;
    padding: 10px 10px;
    text-align: center;
    margin: 8px 15px;
    font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial",
      sans-serif;
    font-weight: 500;
    font-style: normal;
  }

  .btn:hover {
    color: var(--primary-blue);
    background-color: var(--white);
    box-shadow: 2px 2px 5px var(--primary-blue);
  }

  #clear-list:hover {
    color: red;
    background-color: var(--white);
    box-shadow: 2px 2px 5px black;
  }

  button:disabled,
  button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }

  .btn-row-section {
    display: flex;
    align-items: center;
  }

  .inactive,
  .inactive:hover {
    background-color: var(--primary-blue);
    color: var(--white);
    cursor: progress !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .message-container {
    align-items: center;
    justify-content: flex-start;
  }
  .message {
    display: inline-block;
    font-weight: 600;

    &.invalid {
      color: var(--red);
    }
    &.valid {
      color: var(--primary-blue);
    }
  }
  .access-date-message {
    padding: 0px 15px;
    margin: 0 5px;
  }

  .site-item {
    display: flex;
    color: #000;
    padding: 10px 15px;
    transition: 0.3s;
    align-items: center;
    border-radius: 4px;
    box-shadow: 2px 2px 8px rgb(121 121 121 / 77%);
    flex-grow: 1;
    margin-bottom: 0.2rem;
    margin-top: 0.8rem;
    margin-right: 0.5rem;
  }

  select {
    font-size: 15px;
    padding: 0px 15px;
    height: 35px;
    border: 1px solid var(--lightgrey);
    border-radius: 10px;
    width: 500px;
    margin: 0 5px;
    margin-left: -15px;
  }

  select:focus-visible {
    outline: none;
    background-color: var(--lightgrey);
  }

  select.invalid {
    border: 1px solid var(--red);
  }

  option {
    background-color: #f5f5f5;
    font-size: 16px;
    font-weight: 500;
  }
  option:hover {
    background-color: #e1e1e1;
  }

  .extensionInput {
    width: 200px !important;
  }

  .loading {
    opacity: 0.2;
  }

  #requestedForName-info {
    margin-top: 20px;
  }

  .row-section {
    display: flex;
  }

  #btn-get-new-number1 {
    margin: 0px;
    margin-top: 14px;
  }

  #btn-clear-list {
    margin: 0px;
    margin-top: 10px;
    background-color: var(--primary-blue);
  }

  #btn-get-new-number2 {
    margin: 0px;
  }

  #btn-get-new-number3 {
    margin: 0px;
  }

  .tool-tip {
    padding-top: 20px;
    display: flex;
    justify-content: start;
  }

  #site-code {
    height: 150px;
  }
  #tool-tip-site-code {
    justify-content: center;
  }

  #short-Extension {
    position: relative;
    float: right;
    margin-right: ${(props) =>
      props.specificSize ? `${props.specificSize + 60}px;` : "260px;"};
    margin-left: ${(props) =>
      props.specificSize ? `-${props.specificSize + 60}px;` : "-260px;"};
  }

  .zoom-account-info {
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    margin: 8px 5px;
    h5 {
      margin: 10px;
    }
    height: 703px;
    width: 470px;
  }

  .PhoneRequestForm-getnumber-list-item {
    display: flex;
    color: #000;
    padding: 10px 15px;
    transition: 0.3s;
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgb(121 121 121 / 77%);
    flex-grow: 1;
    margin-bottom: 0.2rem;
    margin-top: 0.8rem;
    margin-right: 0.5rem;
  }

  .PhoneRequestForm-getnumber-list {
    margin: 8px 15px;
  }

  .PhoneRequestForm-fit-content {
    width: fit-content;
    margin: 0 !important;
    padding: 0 !important;
    margin-left: 0.5rem !important;
  }

  .bulk-user-item {
    position: relative;
  }

  span {
    color: #000;
  }

  .em-c-alert {
    display: -ms-flexbox;
    display: flex;
    padding: 1em;
    margin-bottom: 1rem;
    background: #d3f2ff;
    border: 1px solid #0192d0;
  }

  .em-c-alert--warning {
    background: #fffecf;
    border-color: #ffd700;
  }

  .em-c-alert--error {
    background: #fdded8;
    border-color: #b12a0b;
    color: #b12a0b;
  }

  .em-c-alert__actions {
    margin-left: 1rem;
  }

  .em-c-alert__body {
    -ms-flex: 1;
    flex: 1;
    font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial",
      sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 0.875rem;
  }

  .em-c-text-btn {
    color: var(--primary-blue);
    background: none;
    border: 0;
    padding: 0;
    text-decoration: underline;
    font-size: 0.875rem;
    transition: all 0.15s ease;
    font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial",
      sans-serif;
    font-weight: 600;
    font-style: normal;
  }

  #warning-get-phone-msg {
    margin: 8px 15px;
  }

  #warning-disable-button {
    margin: 8px 15px;
  }

  #error-get-phone-msg,
  #error-notfounduser {
    margin: 8px 15px;
  }

  #warning-get-phone-li {
    color: red;
  }

  .em-c-alert--success {
    background: #d4f3e6;
    border-color: #00b04f;
    color: #00b04f;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 0.4rem;
    margin-top: 1rem;
  }

  #technical-issues {
    margin: 8px 15px;
  }

  .note {
    font-size: 0.8rem;
    color: #112;
    font-weight: normal;
    margin: 0px 15px;
    width: 400px;
  }

  .PhoneRequestForeignForm-business-justification{
    margin-bottom: 4rem;
    margin-top: 2rem;
  }
`;

export default PhoneRequestForeignForm;
