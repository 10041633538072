export const ZoomStatesAbbreviationList =
[
  {
    "ID": "AL",
    "Name": "Alabama"
  },
  {
    "ID": "AK",
    "Name": "Alaska"
  },
  {
    "ID": "AZ",
    "Name": "Arizona"
  },
  {
    "ID": "AR",
    "Name": "Arkansas"
  },
  {
    "ID": "CA",
    "Name": "California"
  },
  {
    "ID": "CO",
    "Name": "Colorado"
  },
  {
    "ID": "CT",
    "Name": "Connecticut"
  },
  {
    "ID": "DE",
    "Name": "Delaware"
  },
  {
    "ID": "FL",
    "Name": "Florida"
  },
  {
    "ID": "GA",
    "Name": "Georgia"
  },
  {
    "ID": "HI",
    "Name": "Hawaii"
  },
  {
    "ID": "ID",
    "Name": "Idaho"
  },
  {
    "ID": "IL",
    "Name": "Illinois"
  },
  {
    "ID": "IN",
    "Name": "Indiana"
  },
  {
    "ID": "IA",
    "Name": "Iowa"
  },
  {
    "ID": "KS",
    "Name": "Kansas"
  },
  {
    "ID": "KY",
    "Name": "Kentucky"
  },
  {
    "ID": "LA",
    "Name": "Louisiana"
  },
  {
    "ID": "ME",
    "Name": "Maine"
  },
  {
    "ID": "MD",
    "Name": "Maryland"
  },
  {
    "ID": "MA",
    "Name": "Massachusetts"
  },
  {
    "ID": "MI",
    "Name": "Michigan"
  },
  {
    "ID": "MN",
    "Name": "Minnesota"
  },
  {
    "ID": "MS",
    "Name": "Mississippi"
  },
  {
    "ID": "MO",
    "Name": "Missouri"
  },
  {
    "ID": "MT",
    "Name": "Montana"
  },
  {
    "ID": "NE",
    "Name": "Nebraska"
  },
  {
    "ID": "NV",
    "Name": "Nevada"
  },
  {
    "ID": "NH",
    "Name": "New Hampshire"
  },
  {
    "ID": "NJ",
    "Name": "New Jersey"
  },
  {
    "ID": "NM",
    "Name": "New Mexico"
  },
  {
    "ID": "NY",
    "Name": "New York"
  },
  {
    "ID": "NC",
    "Name": "North Carolina"
  },
  {
    "ID": "ND",
    "Name": "North Dakota"
  },
  {
    "ID": "OH",
    "Name": "Ohio"
  },
  {
    "ID": "OK",
    "Name": "Oklahoma"
  },
  {
    "ID": "OR",
    "Name": "Oregon"
  },
  {
    "ID": "PA",
    "Name": "Pennsylvania"
  },
  {
    "ID": "RI",
    "Name": "Rhode Island"
  },
  {
    "ID": "SC",
    "Name": "South Carolina"
  },
  {
    "ID": "SD",
    "Name": "South Dakota"
  },
  {
    "ID": "TN",
    "Name": "Tennessee"
  },
  {
    "ID": "TX",
    "Name": "Texas"
  },
  {
    "ID": "UT",
    "Name": "Utah"
  },
  {
    "ID": "VT",
    "Name": "Vermont"
  },
  {
    "ID": "VA",
    "Name": "Virginia"
  },
  {
    "ID": "WA",
    "Name": "Washington"
  },
  {
    "ID": "WV",
    "Name": "Wet Virginia"
  },
  {
    "ID": "WI",
    "Name": "Wisconsin"
  },
  {
    "ID": "WY",
    "Name": "Wyoming"
  },
  {
    "ID": "AB",
    "Name": "Alberta"
  },
  {
    "ID": "BC",
    "Name": "British Columbia"
  },
  {
    "ID": "MB",
    "Name": "Manitoba"
  },
  {
    "ID": "NB",
    "Name": "New Brunswick"
  },
  {
    "ID": "NL",
    "Name": "Newfoundland"
  },
  {
    "ID": "NS",
    "Name": "Nova Scotia"
  },
  {
    "ID": "NU",
    "Name": "Nunavut"
  },
  {
    "ID": "ON",
    "Name": "Ontario"
  },
  {
    "ID": "PE",
    "Name": "Prince Edward Island"
  },
  {
    "ID": "QC",
    "Name": "Quebec"
  },
  {
    "ID": "SK",
    "Name": "Saskatchewan"
  },
  {
    "ID": "NT",
    "Name": "Northwest Territories"
  },
  {
    "ID": "YT",
    "Name": "Yukon Territory"
  },
  {
    "ID": "AA",
    "Name": "Armed Forces Americas"
  },
  {
    "ID": "AE",
    "Name": "Armed Forces Europe, Middle East, and Canada"
  }
]