import { customAPIConfig } from "../AppConfig";

export const GetAllLocations = async (AccessToken, LocationType, ParentLocationId) => {
    const options = {
        method: "GET",
        headers: { Authorization: `bearer ${AccessToken}` },
    };
    const resp = await fetch(
        `${customAPIConfig.customAPIBaseEndpoint}/CodeRegeneration/GetLocations?LocationType=` + LocationType + `&ParentLocationId=` + ParentLocationId,
        options
    );
    const resp_data = await resp.json();
    return resp_data;
};
