import styled from "styled-components";

const PhoneReleaseFormWrapper = styled.div`
  background-color: var(--primary-white-light);
  padding: var(--content-padding);
  border-radius: var(--content-border-radius);
  margin: var(--content-margin);
  box-shadow: var(--box-shadow-main);
  min-height: 35rem;

  p,
  li,
  h1 {
    color: var(--black);
  }

  h3 {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 0.4rem; 
  margin-top: 1rem; 
  }

  label{
    font-size: 17px;
    font-weight: 600;
  }
  
  .btn {
    background-color: var(--primary-blue);
    color: var(--white);
    border: var(--primary-blue) solid 2px;
    padding: 10px 50px;
    margin: 15px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    box-shadow: 2px 2px 5px #0000008c;
    border-radius: 4px;
    width: 163px;
    height: 51px;
  }

  .btn-large {
    background-color: var(--primary-blue);
    color: var(--white);
    border: var(--primary-blue) solid 2px;
    font-size: 16px;
    width: 180px;
    height: 51px;
    padding: 10px 10px;
    text-align: center;
    margin: 8px 15px;
  }

  .btn:hover {
    color: var(--primary-blue);
    background-color: var(--white);
    box-shadow: 2px 2px 5px var(--primary-blue);
  }

  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }


  .btn-row-section{
    display: flex;
    align-items: center;
  }

  .loading{
    opacity: 0.2;
  }

  .row-section{
    display: flex;
  }


  #btn-release-my-number{
    margin: 0px;
  }

  .btn-large-release-my-number{
    background-color: var(--primary-blue);
    color: var(--white);
    border: var(--primary-blue) solid 2px;
    font-size: 16px;
    width: 160px;
    height: 30px;
    padding: 10px 10px;
    margin: 1px 5px 2px 5px;
    text-align: center;
  }
  
  #warning-get-phone-msg{
    margin: 8px 15px;
  }

  #technical-issues{
    margin: 8px 15px;

  }

`;

export default PhoneReleaseFormWrapper;