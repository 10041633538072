import CircleLoader from "react-spinners/CircleLoader";
import styled from "styled-components";

const Div = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  .circle{
    width: 40px;
    height: 40px;
  }

  .loading-message{
    align-items: center;
    text-align: center;
    padding-top: 10px;
    font-size: 0.875rem; 

  }
`;

const LoadingZoomPhoneRequest = ({loadingMessage}) => {
  return (
    <Div>
      <div className="circle">
        <CircleLoader color="#007bff" />
      </div>

      <div className="loading-message">
        <p>{loadingMessage}</p>
      </div>

    </Div>
  );  
};

export default LoadingZoomPhoneRequest;
