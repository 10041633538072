import { useEffect, useState, createRef, useRef } from "react";
// import { useHistory } from "react-router";
import {Link} from "react-router-dom";

import LoadingScreen_V2 from "../../Components/LoadingScreen_V2"
import Input from "../../Components/Input";
import PopUp from "../../Components/Popup";
import AutoComplete from '../../Components/AutoComplete/AutoComplete';

//api calls
// import GetZoomPhoneSites from "../../Hooks/funcGetZoomPhoneSites";
import FetchGraphData from "../../Hooks/funcFetchGraphData";
import useAccessToken from "../../Hooks/useAccessToken";
import useWebAPIToken from "../../Hooks/useWebAPIToken";

//api calls
import GetPhoneAccountInfo from "../../Hooks/funcGetPhoneAccountInfo";
import PatchPhoneNumberRelease from "../../Hooks/funcPhoneNumberRelease";
import PatchCallingPlanRelease from "../../Hooks/funcCallingPlanRelease";

import PhoneReleaseFormWrapper from "./PhoneReleaseForm.css";

/////////////////////////MAIN COMPONENT/////////////////////////////////

  //#region vars and setters
    const PhoneReleaseRequestForm = () => {
    // SETTING LOCAL STATE FOR THE COMPONENT
    // 1. requestedByEmail EMAIL
    const [requestedByEmail , setRequestedByEmail] = useState("");
    
    
    
    //Phone number request
    const [phoneNumberReleaseConfirmation, setPhoneNumberReleaseConfirmation] = useState(false);
    const [triggerSubmitPhoneRelease, setTriggerSubmitPhoneRelease] = useState(false);
    
    
    const [isShowingPopUp, setIsShowingPopUp] = useState(false);

    //state used to fetch zoom account info
    const [userPhoneAccountInfo, setUserPhoneAccountInfo] = useState(null); 
    const [loadingUserPhoneAccountInfo, setLoadingUserPhoneAccountInfo] = useState(true); 
    const [errorUserPhoneAccountInfo, setErrorUserPhoneAccountInfo] = useState(null); 
    
    const [showErrorPhoneAccountNotFound, setShowErrorPhoneAccountNotFound] = useState(false);
    const [showErrorUserWithoutPhone, setShowErrorUserWithoutPhone] = useState(true);

    const [phoneNumberToRelease, setPhoneNumberToRelease] = useState(""); 
    const [phoneNumberReleaseError, setPhoneNumberReleaseError] = useState(null);
    const [phoneNumberReleaseLoading, setphoneNumberReleaseLoading] = useState(false);
    const [phoneNumberSuccessfullyReleased, setPhoneNumberSuccessfullyReleased] = useState(false);
    const [failedToReleasePhoneNumber, setFailedToReleasePhoneNumber] = useState(false);


    const [touchAllForChangeSite, setTouchAllForChangeSite] = useState("");
    const [inputAllStatesForRelease, setInputAllStatesForRelease] = useState("");
    const [inputStateForRelease, setInputStateForRelease] = useState("");
    const [selectedInputStateForRelease, setSelectedInputStateForRelease] = useState({});

    
    // SETTING LOCAL CONSTANT FOR THE COMPONENT
    // 1. HISTORY TO REDIRECT PAGE
    // const history = useHistory();
    // 2. GENERATES TOKEN FOR THE GRAPH API
    const accessToken = useAccessToken();
    // 3. TOKEN FOR CUSTOM WEB API
    const webApiToken = useWebAPIToken();
    

    
  //#endregion vars and setters

  //#region Functions
     
    const FetchZoomAccountInfo = async () => {
      if (webApiToken) {
        try {
          setLoadingUserPhoneAccountInfo(true);
          const [phoneAccountInfoResponse] = await Promise.all([
            // GetPhoneAccountInfo(webApiToken, email),
            GetPhoneAccountInfo(webApiToken, requestedByEmail),
          ]);
          setUserPhoneAccountInfo(phoneAccountInfoResponse.data)
        } catch (err) {
          console.log("err.message", err.message);
          setErrorUserPhoneAccountInfo(err);
        } finally {
          setLoadingUserPhoneAccountInfo(false);
        }
      }
    }

    const CheckIfUserDoesntHavePhone = () => {
      setShowErrorUserWithoutPhone(!(userPhoneAccountInfo && userPhoneAccountInfo.phoneNumbers && userPhoneAccountInfo.phoneNumbers.length > 0));
    }

    // Calling plan release
    const handleCallingPlanRelease = async () => {
      if (webApiToken) {
        setphoneNumberReleaseLoading(true)
        try {
          let formData = new FormData();
          formData.append("userId", userPhoneAccountInfo.email)
          formData.append("phoneNumber", userPhoneAccountInfo.phoneNumbers[0].number)
          formData.append("siteId", userPhoneAccountInfo.siteId)
          const [phoneNumberRequestResponse] = await Promise.all([
            // GetPhoneAccountInfo(webApiToken, email),
            PatchCallingPlanRelease(webApiToken, formData),
          ]);

          // setPhoneNumberRequestResultData( phoneNumberRequestResponse.data)
          setPhoneNumberSuccessfullyReleased(true);

        } catch (err) {
          setPhoneNumberReleaseError(err.message)
          setFailedToReleasePhoneNumber(true)
        }
        finally{
          setphoneNumberReleaseLoading(false)
        }
      }
    }

    // Phone number release
    const handlePhoneNumberRelease = async () => {
      if (webApiToken) {
        setphoneNumberReleaseLoading(true)
        try {
          let formData = new FormData();
          formData.append("userId", userPhoneAccountInfo.email)
          formData.append("phoneNumber", selectedInputStateForRelease.number)
          formData.append("siteId", userPhoneAccountInfo.siteId)
          const [phoneNumberRequestResponse] = await Promise.all([
            PatchPhoneNumberRelease(webApiToken, formData),
          ]);

          // setPhoneNumberRequestResultData( phoneNumberRequestResponse.data)
          setPhoneNumberSuccessfullyReleased(true);

        } catch (err) {
          setPhoneNumberReleaseError(err.message)
          setFailedToReleasePhoneNumber(true)
        }
        finally{
          setphoneNumberReleaseLoading(false)
        }
      }
    }    
        // FUNCTION TO UPDATE THE INPUT STATES
    // USED TO DEFINE IF THE GLOBAL FORM STATE IS VALID OR INVALID
    const GetStateForSelectNumber = (state) => {
      setInputAllStatesForRelease((oldState) => {
        return { ...oldState, ...state };
      });
    };

    //Before submit request to move a user, check all the fields 
    const CheckFormReleaseNumber = () => {
      setTouchAllForChangeSite("trigger");
      
      if(userPhoneAccountInfo.phoneNumbers.length > 1){
        if (!Object.values(inputAllStatesForRelease).includes(false)) {
          setPhoneNumberReleaseConfirmation(true);
          
        }
        else{
          console.log("Form not filled properly");
          setPhoneNumberReleaseConfirmation(false);
        }
      }
      else {
        setPhoneNumberToRelease(userPhoneAccountInfo.phoneNumbers[0].number)
        setPhoneNumberReleaseConfirmation(true);
      }
    } 

    const handleNewRequest = () => {
      setTriggerSubmitPhoneRelease(false);
      setPhoneNumberSuccessfullyReleased(false);
      setInputStateForRelease("");
      FetchZoomAccountInfo();
    }

    



  //#endregion Functions

  //#region useEffect

    useEffect(()=>{
      CheckIfUserDoesntHavePhone();
    },[userPhoneAccountInfo])

    //Fetch zoom account info for the requestedByEmail
    useEffect(() => {
      if(requestedByEmail.length > 0){
        FetchZoomAccountInfo();
      }
    },[webApiToken, requestedByEmail])
    
    // CUSTOM FUNCTION TO FETCH DATA FROM /me ENDPOINT FROM GRAPH API
    // WHEN THE HTTP REQUEST IS DONE, the state requestedByEmail IS UPDATED WITH
    // USER DATA RECEIVED FROM GRAPH
    useEffect(() => {
      if (accessToken) {
        FetchGraphData({
          endpoint: "/me",
          accessToken: accessToken,
        }).then((data) => {
          setRequestedByEmail(data.mail);
        });
      }
    }, [accessToken]);  

    // CUSTOM FUNCTION TO UPDATE isShowingPopUp
    // isShowingPopUp IS A STATE THAT REPRESENTS IF THERE IS SHOWING ANY POPUP
    useEffect(()=>{ 
        setIsShowingPopUp(phoneNumberReleaseConfirmation)
    },[phoneNumberReleaseConfirmation])
  
    // CHECK IF THE USER CLICK ON THE BUTTON GET NUMBER, IF IT IS CALL THE FUNCTION handleCallingPlanRelease
    useEffect(() => {
      if(triggerSubmitPhoneRelease){
        userPhoneAccountInfo.phoneNumbers.length > 1 ? handlePhoneNumberRelease() : handleCallingPlanRelease();
      }
    },[triggerSubmitPhoneRelease])
    
    useEffect(()=>{
      setPhoneNumberToRelease(selectedInputStateForRelease.number)
    },[selectedInputStateForRelease])

  //#endregion useEffect

  // JSX CODE FOR MAIN COMPONENT
  return (
    <PhoneReleaseFormWrapper>
      <h1>Zoom Phone Return Form</h1>
      <br />
      <p> Use this form to return Zoom phone numbers. </p>
      <p> You can only return phone numbers assigned to you. If you have multiple Zoom Phones, ensure you select the correct one for return. </p>
      <p> You will receive a confirmation email and you can also verify your return request was submitted by viewing the <Link target="_blank" to="/phone-request-logs">Submitted Zoom Phone Requests page.</Link> </p>
 
      
      {loadingUserPhoneAccountInfo && (<LoadingScreen_V2 loadingMessage="Loading Zoom information."></LoadingScreen_V2>)}
      {phoneNumberReleaseLoading && (<LoadingScreen_V2 loadingMessage="Returning Zoom number."></LoadingScreen_V2>)}
     
      <div className={ loadingUserPhoneAccountInfo || phoneNumberReleaseLoading ? 'loading' : ''}>
      
         {/* Requested by input field */}
        <Input
          note="Requester email address."
          label="Requested by"
          type="text"
          placeholder="user name"
          value={requestedByEmail}
          disabled
          toolTipMessage="Email of person submitting the request."
        />


        { !showErrorUserWithoutPhone && !phoneNumberSuccessfullyReleased &&(
          <div>  {/* zoom phone info */}
            {/* Zoom Phone Number */}
            {userPhoneAccountInfo.phoneNumbers.length > 1 &&
              (
                <AutoComplete
                  label="Select the number"
                  inputValue={inputStateForRelease}
                  setInputValue={setInputStateForRelease}
                  note="Select which number you would like to return."
                  listOfObjects={userPhoneAccountInfo.phoneNumbers}
                  optionKeyValues={['number']}
                  returnSelectedObj={setSelectedInputStateForRelease}
                  toolTipMessage="Select the number to be returned. "
                  GetState={GetStateForSelectNumber}
                  touchAll={touchAllForChangeSite}
                >
                </AutoComplete>
              ) 
            }
            {userPhoneAccountInfo.phoneNumbers.length === 1 &&
              (
                <Input
                  note="Zoom phone number."
                  label="Zoom phone"
                  type="text"
                  placeholder="user name"
                  value={userPhoneAccountInfo.phoneNumbers[0].number} 
                  disabled
                  toolTipMessage="Zoom phone number of person submitting the request."
                />
              )
            }
           
           {/* Release button */}
            { !phoneNumberSuccessfullyReleased &&
              (
                <div className="btn-row-section">
                  <div id="btn-release-my-number">
                    <button
                      className="btn btn-large"
                      type="button"
                      onClick={() => { CheckFormReleaseNumber() }}
                      disabled={loadingUserPhoneAccountInfo || isShowingPopUp || phoneNumberReleaseLoading}
                    >
                      Return my number
                    </button>
                  </div>
                </div>
              )
            } 

          </div> /* zoom phone info */
        )}

        <div>  {/* Show results */}
          {/* Show Error Phone Account Not Found */}
          { showErrorPhoneAccountNotFound && errorUserPhoneAccountInfo && errorUserPhoneAccountInfo.message === "Not Found" && (
            <div id="warning-release-phone-msg">
              <div className="App-em-c-alert App-em-c-alert--error ">
                <div className="App-em-c-alert__body">
                  This user is not yet set up in Zoom. Please try again tomorrow.
                </div>
                <div className="App-em-c-alert__actions">
                  <button 
                    type="button"
                    className="App-em-c-text-btn App-em-c-alert--error "
                    onClick={ () => setShowErrorPhoneAccountNotFound(false)}
                  >
                      Close
                  </button>
                </div>
              </div>
            </div>
          )} 

          {/* Show Error User Without a Phone*/}
          { showErrorUserWithoutPhone && userPhoneAccountInfo &&(
            <div id="warning-release-phone-msg">
              <div className="App-em-c-alert App-em-c-alert--warning ">
                <div className="App-em-c-alert__body">
                  This user doesn't have a Zoom phone number.
                </div>
                <div className="App-em-c-alert__actions">
                  {/* <button 
                    type="button"
                    className="App-em-c-text-btn App-em-c-alert--error "
                    onClick={ () => setShowErrorUserWithoutPhone(false)}
                  >
                      Close
                  </button> */}
                </div>
              </div>
            </div>
          )} 

          {/* Show Error try to search a zoom phone account*/}
          { errorUserPhoneAccountInfo && errorUserPhoneAccountInfo.message !== "Not Found" && (

            <div id="warning-release-phone-msg">
              <div className="App-em-c-alert App-em-c-alert--error ">
                <div className="App-em-c-alert__body">
                  There is an error. Please try again. If not resolved, please &nbsp; <a target="_blank" href={"https://goto/GenericSR"}>goto/GenericSR</a> to submit a ticket and assign to Zoom Support group or contact the &nbsp; <a target="_blank" href="https://goto/itservices">Help Desk</a>. <br></br>
                  {errorUserPhoneAccountInfo.message}
                </div>
                <div className="App-em-c-alert__actions">
                </div>
              </div>
            </div>
          )} 

          {/* Show Error try to release a zoom phone number*/}
          {failedToReleasePhoneNumber && (
            <div className="App-em-c-alert App-em-c-alert--error">
              <div className="App-em-c-alert__body">
                There is an error to return your number. Please try again. If not resolved, please &nbsp; <a target="_blank" href={"https://goto/GenericSR"}>goto/GenericSR</a> to submit a ticket and assign to Zoom Support group or contact the &nbsp; <a target="_blank" href="https://goto/itservices">Help Desk</a>. <br></br>
                {phoneNumberReleaseError.message}
              </div>
              <div className="App-em-c-alert__actions">
                <button 
                  type="button"
                  className="App-em-c-text-btn App-em-c-alert--error"
                  onClick={() => setFailedToReleasePhoneNumber(false)}
                >
                    Close
                </button>
              </div>
            </div>
          )}

          {/* Show success message number released*/}
          {phoneNumberSuccessfullyReleased && (
            <div className="App-em-c-alert App-em-c-alert--success">
              <div className="App-em-c-alert__body">
                Congratulations! The Zoom phone number {phoneNumberToRelease} has been returned.&nbsp; <Link target="_blank" to="/phone-request-logs">Click here</Link> to verify your return request was submitted.
              </div>
              {/* <div className="App-em-c-alert__actions">
                <button 
                  className="App-em-c-text-btn"
                  onClick={() => setPhoneNumberSuccessfullyReleased(false)}
                >
                  Close
                </button>
              </div> */}
            </div>
          )}

        </div> {/* Show results */}

        {phoneNumberSuccessfullyReleased && (
          <div className="btn-row-section">
            <div id="btn-get-new-number3">
              <button
                className="btn btn-large"
                type="button"
                onClick={handleNewRequest}
                disabled={isShowingPopUp}
              >New Request</button>
            </div>
          </div>          
        )}
            
        <div id="technical-issues">
          <p>For any other items or technical issues <a target="_blank" href={"https://goto/GenericSR"}>goto/GenericSR</a> to submit a ticket and assign to Zoom Support group or contact the <a target="_blank" href="https://goto/itservices">Help Desk</a>.</p>
        </div>     
      
        {/* PopUp Confirmation*/}   
        {phoneNumberReleaseConfirmation && (
          <PopUp
            title="Return Zoom Phone Number"
            message={`are you sure you want to return the Zoom phone number "${phoneNumberToRelease}"?`}
            showPopUp={phoneNumberReleaseConfirmation}
            setShowPopUp={setPhoneNumberReleaseConfirmation}
            cancelCallback={() => setPhoneNumberReleaseConfirmation(false)}
            submitForm={() => setTriggerSubmitPhoneRelease(true)}
          >
          </PopUp>
        )}
      </div>{/* div loading */}
    </PhoneReleaseFormWrapper>
  );

};

export default PhoneReleaseRequestForm;