import { useEffect, useState } from "react";

import "./SelectInput.css";

/////////////////////////COMPONENT STYLES/////////////////////////////////

/////////////////////////MAIN COMPONENT/////////////////////////////////
const SelectInput = (props) => {
  // INITIALIZING LOCAL STATES FOR THE APPLICATION
  // 1. STATE THAT DEFINES IF THE INPUT IS VALID
  // 2. STATE THAT DEFINES IF THE USER ALREADY TRIED TO WORK WITH THIS FIELD
  // 3. STATE THAT HOLDS THE ERROR MESSAGE THAT WILL BE DISPLAYED
  const [validInput, setValidInput] = useState(true);
  const [firstTry, setFirstTry] = useState(true);
  const [errorMessage, setErrorMessage] = useState("Validation error");

  // BLOCK THAT MONITORS THE TOUCH ALL VARIABLE OF PARENT COMPONENT, IF IT IS trigger
  // SET firstTry TO FALSE IF IT IS submitted SET firstTry TO TRUE
  if (props.touchAll === "trigger" && firstTry) {
    setFirstTry(false);
  } else if (props.touchAll === "submitted" && !firstTry) {
    setFirstTry(true);
  }

  // FUNCTION THAT VALIDATED THE USER INPUT
  const InputValidator = () => {
    // IF THE COMPONENT HAS VALIDATION RULES IN PROPS, THIS BLOCK RUNS:
    if (props.validationRules) {
      // 1. TESTING IF THE INPUT IS EMPTY
      if (props.value.trim() === "") {
        setErrorMessage("Please, select an option");
        setValidInput(false);
        return;
      }
      // IF ALL TESTS ABOVE SUCCEED TWEAK THE VALID STATE TO TRUE
      setValidInput(true);
    }
    // IF THERE IS NO VALIDATION RULES IN THE COMPONENT PROPS, THEN
    // VALID STATE IS SET TO TRUE
    else {
      setValidInput(true);
    }
  };

  // RUNS THE INPUT VALIDATOR FUNCTION EVERY TIME THAT THE INPUT VALUE CHANGES
  useEffect(() => {
    InputValidator();
    props.GetState({ [props.label]: validInput });
  }, [props.value, validInput, props.label]);

  // FUNCTION THAT CHANGES THE VALUE FOR THE INPUT EVERY TIME THE USER HITS THE KEYBOARD
  const changeHandler = (e) => {
    if (props.setValue) {
      props.setValue(e.target.value);
    }
  };

  // COMPONENT JSX DEFINITION
  return (
    <div>
      <label>
        {props.label} {props.textAfter}
        <br />
        <select
          value={props.value}
          className={`SelectInput-select ${!validInput && !firstTry && "invalid"}`}
          onChange={changeHandler}
          onBlur={() => {
            setFirstTry(false);
          }}
        >
          <option value=""></option>
          {props.optionsList.map((e) => (
            <option key={e[props.optionKey]} value={e[props.optionValues]}>
              {e[props.optionContent]}
            </option>
          ))}
        </select>
        {props.actionButton && (
          <button
            className="SelectInput-button"
            type="button"
            onClick={props.actionButton}
          >
            {props.actionButtonText}
          </button>
        )}
        {!validInput && !firstTry && <p>{errorMessage}</p>}
      </label>
    </div>
  );
};

export default SelectInput;
