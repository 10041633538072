import { useState } from "react";
import {
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { UserLogin } from "./UserLogin";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

const StyledHeader = styled.header`
  color: var(--primary-blue);

  a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
  }

  html {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  }

  .box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.2);
  }

  h2 {
    color: var(--primary-blue);
    margin: 0;
    font-size: 28px;
    text-align: end;
  }

  a {
    color: var(--primary-blue);
  }

  .powered-by {
    font-size: 11px;
    font-weight: 400;
  }

  a {
    color: var(--primary-blue);
  }

  .nav-logo {
    min-width: 200px;
  }
`;

export const NavMenu = (props) => {
  const [isShowingDropdown, setIsShowingDropdown] = useState(false);
  const [collapsed, setColapsed] = useState(true);

  const toggleDropdown = () => setIsShowingDropdown((current) => !current);

  const toggleNavbar = () => {
    setColapsed(!collapsed);
  };

  return (
    <StyledHeader>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm bg-white border-bottom box-shadow mb-4"
        light
      >
        <Container>
          <NavbarBrand
            tag={Link}
            className="text-capitalize font-weight-bold nav-logo"
            to="/"
          >
            <h2>Zoom Request Portal</h2>
            <h2 className="powered-by">Powered by C&C Team</h2>
          </NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            isOpen={collapsed}
            navbar
          >
            <ul className="nav nav-pills flex-grow">
              <Dropdown nav isOpen={isShowingDropdown} toggle={toggleDropdown}>
                <DropdownToggle nav caret>
                  Pick a Service
                </DropdownToggle>
                <DropdownMenu style={{ minWidth: "100%" }}>
                  <DropdownItem tag={Link} to="/phone-request">
                    Phone Request
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/phone-move-form">
                    Phone Location Update
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/phone-release-form">
                    Phone Return
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/phone-request-logs">
                    Submitted Requests
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem tag={Link} to="/zoom-recording">
                    Call Recording Request
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/zoom-recording-data">
                    Submitted Requests
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <UserLogin {...props} />
            </ul>
          </Collapse>
        </Container>
      </Navbar>
    </StyledHeader>
  );
};
