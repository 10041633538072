import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  LeftAngle,
  PlaceholderArrow,
  RightAngle,
  DownAngle,
  TopAngle,
} from "../Assets/SVGArrows";
import { ExportCSVButton } from "./ExportCSVButton";

const Wrapper = styled.div`
  color: var(--black);
  max-width: 100%;

  .flex-row {
    align-items: center;
  }

  .table-actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 5px;
  }
  .table-actions input {
    border-radius: 8px;
    border: 2px var(--black) solid;
    border-right: 1px var(--black) solid;
    padding: 3px 8px;
    height: 35px;
    margin: 5px 15px 5px 0px;
  }

  .table-actions select {
    color: var(--black);
    border-radius: 8px;
    border: 2px var(--black) solid;
    padding: 5px;
    height: 35px;
    margin: 6px 0 6px 20px;
  }

  .btn-exp {
    background-color: var(--black);
    border: 2px solid var(--black);
    border-radius: 8px;
    color: var(--white);
    padding: 4px 15px;
    margin: 0 20px 0 0;
    height: 35px;

    &:hover {
      background-color: var(--white);
      color: var(--black);
    }
  }

  .btn-exp2 {
    background-color: var(--black);
    border: 2px solid var(--black);
    border-radius: 8px;
    color: var(--white);
    padding: 4px 15px;
    margin: 0 10px;
    height: 35px;

    &:hover {
      background-color: var(--white);
      color: var(--black);
    }
  } 

  .select-show {
    border-bottom-left-radius: 7px !important;
    border-left: 2px var(--black) solid !important;
  }

  .table-actions input:focus,
  .table-actions select:focus {
    background-color: var(--primary-blue-light);
  }

  .table-wrapper {
    overflow: auto;
    margin: 5px;
    border-radius: 7px;
    border: 2px var(--black) solid;
    width: 100%;
  }

  .table-wrapper::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  .table-wrapper::-webkit-scrollbar-track {
    background-color: var(--primary-blue-mid);
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.55);
  }

  .table-wrapper::-webkit-scrollbar-thumb {
    background-color: #0000009e;
  }

  .table-itself {
    width: 100%;
  }

  tbody tr:nth-child(odd) {
    background-color: var(--primary-blue-light);
  }

  thead tr {
    background-color: var(--primary-blue-mid);
  }

  th:first-letter {
    text-transform: capitalize;
  }

  th,
  td {
    padding: 13px;
    border: 1px var(--primary-blue-mid) solid;
  }

  th:hover {
    background-color: var(--primary-blue-dark);
    cursor: pointer;
  }

  tbody tr:hover {
    background-color: var(--primary-blue-dark) !important;
  }

  th > div,
  td > div {
    max-width: 400px;
    overflow-x: hidden;
    white-space: nowrap;
    font-size: 15px;
  }

  .next-page-controls {
    margin: 5px;
    justify-content: flex-end;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
  }

  .placeholder-svg {
    margin: 10px;
    width: 15px;
  }

  &.expanded-element {
    overflow: scroll;
    position: fixed;
    z-index: 10;
    top: 3vh;
    left: 3vw;
    height: 94vh;
    width: 94vw;
    background-color: var(--lightgrey);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 25px 10px var(--black);
    animation: grow 0.5s;

    @keyframes grow {
      from {
        top: 35vh;
        left: 35vw;
        height: 30vh;
        width: 30vw;
      }

      to {
        top: 3vh;
        left: 3vw;
        height: 94vh;
        width: 94vw;
      }
    }

    .table-wrapper {
      overflow: auto;
      max-height: 88%;
    }
  }

  .container-table{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .status-active{
    background: #d4f3e6;
    border: solid 1px #00b04f;
    text-align: center;

  }

  .status-scheduled{
    background: #d3f2ff;
    border: solid 1px #0192d0;
    text-align: center;

  }

  .status-expired{
    background: #fffecf;
    border: solid 1px #ffd700;
    text-align: center;
  }

  .status-error{
    background: #fdded8;
    border: solid 1px #b12a0b;
    text-align: center;
  }
`;

const Table = (props) => {
  // INITIALIZE DATA AND HEADERS VARIABLES
  let data = props.data;
  let tableKeys = Object.keys(props.data[0]);
  // INITIALIZING LOCAL STATES OF THE COMPONENT
  // 1. FILTER STRING ENTERED BY THE USER TO FILTER THE DISPLAYED DATA
  // 2. STATE THAT HOLDS THE VALUE OF THE PROPERTY TO FILTER BY
  // 3. STATE THAT HOLDS THE INTEGER OF HOW MANY ITEMS SHOULD BE DISPLAYED PER PAGE
  // 4. STATE THAT HOLDS THE NUMBER OF PAGE THAT THE USER CURRENTLY IS
  const [filter, setFilter] = useState("");
  const [filterBy, setFilterBy] = useState(tableKeys[0]);
  const [show, setShow] = useState(15);
  const [page, setPage] = useState(0);
  const [orderField, setOrderField] = useState(tableKeys[0]);
  const [orderAsc, setOrderAsc] = useState(false);
  const [expandTable, setExpandTable] = useState(false);

  // BLOCK CODE THAT FILTER THE DATA IF THE USER ENTERED A FILTER STRING
  if (filter.length > 0) {
    data = data.filter((e) =>
      e[`${filterBy}`].toString().toUpperCase().includes(filter.toUpperCase())
    );
  }

  // SORT DATA IMPLEMENTATION ON BASIS OF orderField AND orderField
  data.sort((a, b) => {
    if (orderAsc) {
      if(typeof(a[`${orderField}`]) === "number"){
        return a[`${orderField}`]-b[`${orderField}`];
      }
      else{
        if (
          a[`${orderField}`].toString().toUpperCase() <=
          b[`${orderField}`].toString().toUpperCase()
        ) {
          return -1;
        } else {
          return 1;
        }
      }
    } else {
      if(typeof(a[`${orderField}`]) === "number"){
        return b[`${orderField}`]-a[`${orderField}`];
      }
      else{
        if (
          a[`${orderField}`].toString().toUpperCase() <=
          b[`${orderField}`].toString().toUpperCase()
        ) {
          return 1;
        } else {
          return -1;
        }
      }
    }
  });

  // CALCULUS TO DEFINE THE MAX NUMBER OF PAGES
  const maxPage = Math.floor((data.length - 1) / show);

  // STATEMENT THAT DEFINE THE DATA THAT SHOULD BE DISPLAYED IN THIS PAGE
  data = data.slice(page * show).slice(0, show);

  // FUNCTION THAT HANDLE THE filter STATE
  const filterHandler = (e) => {
    setFilter(e.target.value);
    setPage(0);
  };

  // FUNCTION THAT HANDLE THE filterBy STATE
  const changeFilterHandler = (e) => {
    setFilterBy(e.target.value);
    setPage(0);
  };

  // FUNCTION THAT HANDLE THE show STATE
  const changeShow = (e) => {
    setShow(e.target.value);
    setPage(0);
  };

  // FUNCTION THAT HANDLE THE orderField STATE
  const changeOrder = (e) => {
    setOrderField(e.target.textContent);
    setOrderAsc((est) => !est);
  };

  // useEffect(()=>{
  //   console.log(data)
  // },[data])

  // FUNCTIONS THAT HANDLE THE page STATE
  const SetPageDown = () => {
    setPage((old) => old - 1);
  };
  const SetPageUp = () => {
    setPage((old) => old + 1);
  };

  // FUNCTION THAT HANDLE THE EXPANSION OF THE TABLE
  const ExpandHandler = () => {
    setExpandTable((old) => !old);
  };

  const StatusClass = (status) => {
    switch (status) {
      case "Expired":
        return "status-expired";
      case "Scheduled":
        return "status-scheduled";
      case "Active":
        return "status-active";
      case "Error":
        return "status-error"
      default:
        return "";
    }
  }

  // CLOSE EXPANDED TABLE ON ESC KEYSTROKE
  useEffect(() => {
    const CloseTable = (e) => {
      e.key === "Escape" && setExpandTable(false);
    };
    document.addEventListener("keydown", CloseTable);
    return () => document.removeEventListener("keydown", CloseTable);
  }, []);

  // SET THE PAGE TO 0 IF THE DATA CHANGES
  useEffect(() => {
    setPage(0);
  }, [props.data.length]);

  return (
    <Wrapper className={`${expandTable && "expanded-element"}`}>
      <div className="table-actions">
        <div>
          <input
            value={filter}
            onChange={filterHandler}
            placeholder={`Filter by ...`}
          />
          <select value={filterBy} onChange={changeFilterHandler}>
            {tableKeys.map((e) => (
              <option key={e} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-row">
          <ExportCSVButton
            data={data}
            filename={"data"}
          />
          <button className="btn btn-exp2" onClick={ExpandHandler}>
            {expandTable ? "Collapse Table" : "Expand Table"}
          </button>
          <select value={show} onChange={changeShow} className="select-show">
            <option key={15} value={15}>
              Show 15 rows
            </option>
            <option key={30} value={30}>
              Show 30 rows
            </option>
            <option key={60} value={60}>
              Show 60 rows
            </option>
          </select>
        </div>
      </div>
      <div className="container-table">
        <div className="table-wrapper">
          <table className="table-itself">
            <thead>
              <tr>
                {tableKeys.map((e, index) => 
                  index + 1 === tableKeys.length ? (
                    <th key={Math.random()}>
                      <div>
                        {orderField === e && orderAsc && <DownAngle />}
                        {orderField === e && !orderAsc && <TopAngle />}
                        {e}
                      </div>
                    </th>
                  ) : (
                    <th key={Math.random()} onClick={changeOrder}>
                      <div>
                        {orderField === e && orderAsc && <DownAngle />}
                        {orderField === e && !orderAsc && <TopAngle />}
                        {e}
                      </div>
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {data.map((e) => (
                <tr key={Math.random()}>
                  {Object.values(e).map((e2) => (
                    <td key={Math.random()}>
                      <div className={StatusClass(e2)}>{e2 ? e2.toString() : e2}</div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex-row next-page-controls">
        {page !== 0 && <LeftAngle onClick={SetPageDown} />}
        {page === 0 && <PlaceholderArrow />}
        <div>{`Page ${page + 1}`}</div>
        {page < maxPage && <RightAngle onClick={SetPageUp} />}
        {page >= maxPage && <PlaceholderArrow />}
      </div>
    </Wrapper>
  );
};

export default Table;
