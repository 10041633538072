import { useState, useEffect } from "react";
import { PhoneLocationCardWrapper } from "./PhoneUserCard.css.js";
import { SVGPencil } from "../../Assets/SVGTiles";
import { SVGXFilled } from "../../Assets/SVGTiles";
import { useHistory } from "react-router";

import { Link } from "react-router-dom";

//api tokens
import useAccessToken from "../../Hooks/useAccessToken";
import useWebAPIToken from "../../Hooks/useWebAPIToken";

//api calls
import GetPhoneAccountInfo from "../../Hooks/funcGetPhoneAccountInfo";
import PatchPhoneNumberRequest from "../../Hooks/funcPhoneNumberRequest";
import { useFetch } from "../../Hooks/useFetch";

//Components
import LoadingZoomPhoneRequest from "../../Components/LoadingZoomPhoneRequest";
import PhoneMoveCard from "../PhoneMoveCard/PhoneMoveCard";

import { ZoomCountriesAbbreviationListWithIAMfixes } from "../../Assets/Data/ZoomCountriesAbbreviationListWithIAMfixes";

const PhoneUserCard = ({
  isLoading,
  setIsLoading,
  
  zoomSitesEmergencyAddressLoading,
  zoomSitesEmergencyAddress,
  zoomSitesEmergencyAddressFail,  
  phoneSitesOnboarded,
  
  setIsShowingPopUp,
  isShowingPopUp,
  
  userEmail,
  
  callBackRemoveUserFromBulkGetNumber,
  callBackErrorPhoneAccountNotFound,
  callBackErrorZoomPhoneNotEnabled,
  callBackErrorUserAlreadyHaveAPhone,
  
  triggerSubmitPhoneRequest,
  callBackLoadingPhoneUserCard,
  getState,
  ...rest
}) => {
  //#region const state
  // State used to fetch zoom account info
  const [userPhoneAccountInfo, setUserPhoneAccountInfo] = useState(null);
  const [loadingUserPhoneAccountInfo, setLoadingUserPhoneAccountInfo] =
    useState(false);
  const [errorUserPhoneAccountInfo, setErrorUserPhoneAccountInfo] =
    useState(null);

  const [showChangeSiteButton, setShowChangeSiteButton] = useState(false);
  const [showChangeSite, setShowChangeSite] = useState(false);
  const [showWarningPhoneNumberOverwrite, setShowWarningPhoneNumberOverwrite] =
    useState(false);

  // zoom phone number request
  const [phoneNumberRequestError, setPhoneNumberRequestError] = useState(null);
  const [phoneNumberRequestLoading, setPhoneNumberRequestLoading] =
    useState(false);
  const [phoneNumberRequestSuccess, setPhoneNumberRequestSuccess] =
    useState(false);
  const [phoneNumberRequestFail, setPhoneNumberRequestFail] = useState(false);
  const [userSiteLocation, setUserSiteLocation] = useState(null);

  const [popUpMoveSuccess, setPopUpMoveSuccess] = useState(false);

  const [userCountryZoomFormat, setUserCountryZoomFormat] = useState(null);
  const [foundCountryInPhoneSites, setFoundCountryInPhoneSites] =
   useState(null);

  const {
    data: userCountry = null,
    error: errorUserCountry = null,
    isFetching: loadingUserCountry = null,
  } = useFetch(`/PhoneRequest/GetCurrentUserCountry?userEmail=${userEmail}`);

  // SETTING LOCAL CONSTANT FOR THE COMPONENT
  // 1. HISTORY TO REDIRECT PAGE
  const history = useHistory();
  // 2. GENERATES TOKEN FOR THE GRAPH API
  const accessToken = useAccessToken();
  // 3. TOKEN FOR CUSTOM WEB API
  const webApiToken = useWebAPIToken();

  //#endregion const state

  //#region useEffect
  //Fetch zoom account info for the userEmail
  useEffect(() => {
    if (userEmail.length > 0) {
      FetchZoomAccountInfo();
    }
  }, [webApiToken, userEmail]);

  useEffect(() => {
    if (userCountry) {
      setUserCountryZoomFormat(findZoomCountryCode(userCountry));
    }
  }, [userCountry]);

  useEffect(() => {
    if (userCountryZoomFormat && zoomSitesEmergencyAddress) {
      setFoundCountryInPhoneSites(isThereAnySite(userCountryZoomFormat.ID));
    }
  }, [userCountryZoomFormat, zoomSitesEmergencyAddress]);

  // CUSTOM FUNCTION TO UPDATE isLoading
  // isLoading IS A STATE THAT REPRESENTS IF THERE IS ANY REQUESTION LOADING
  useEffect(() => {
    setIsLoading(phoneNumberRequestLoading || loadingUserPhoneAccountInfo);
  }, [phoneNumberRequestLoading, loadingUserPhoneAccountInfo]);

  // CUSTOM FUNCTION TO CALL THE FUNCTIONS TO CHECK
  // IF THE USER IS IN WAVE 1 SITES WITH ZOOM PHONE
  // IF THERE IS AN ERROR TO GET PHONE ACCOUNT
  // IF THERE IS A NUMBER OVERWRITE WARNING
  useEffect(() => {
    if (
      !loadingUserPhoneAccountInfo &&
      userPhoneAccountInfo &&
      userPhoneAccountInfo.email
    ) {
      if (!popUpMoveSuccess) {
        CheckIfTheUserIsInWave1SiteList();
      }
    }

    if (!loadingUserPhoneAccountInfo) {
      CheckIfThereIsPhoneAccountError();
    }
  }, [loadingUserPhoneAccountInfo, userPhoneAccountInfo, popUpMoveSuccess]);

  useEffect(() => {
    if (!triggerSubmitPhoneRequest && !loadingUserPhoneAccountInfo) {
      CheckIFThereIsWarningOverwrite();
    }
  }, [userPhoneAccountInfo, loadingUserPhoneAccountInfo, zoomSitesEmergencyAddress]);

  // CHECK IF THE USER CLICK ON THE BUTTON GET NUMBER, IF IT IS CALL THE FUNCTION handlePhoneNumberRequest
  useEffect(() => {
    if (triggerSubmitPhoneRequest) {
      handlePhoneNumberRequest();
    }
  }, [triggerSubmitPhoneRequest]);

  // CHECK IF THE THERE IS AN ERROR WHEN GET THE SITE LOCATIONS, IF IT IS zoomSitesEmergencyAddressFail IS TRUE THEN NOT SHOW showChangeSite
  useEffect(() => {
    if (zoomSitesEmergencyAddressFail) {
      setShowChangeSite(false);
    }
  }, [zoomSitesEmergencyAddressFail]);

  useEffect(() => {
    if (
      zoomSitesEmergencyAddress.length > 0 &&
      userPhoneAccountInfo &&
      userPhoneAccountInfo.email
    ) {
      let userSite = zoomSitesEmergencyAddress.find(
        (location) => location.siteId.toLocaleLowerCase() === userPhoneAccountInfo.siteId.toLocaleLowerCase()
      );

      if (userSite && userSite.siteName) {
        setUserSiteLocation(userSite);
      }
    }
    // value={   }
  }, [zoomSitesEmergencyAddress, userPhoneAccountInfo]);

  useEffect(() => {
    if (userPhoneAccountInfo && userPhoneAccountInfo.email) {
      getState({
        userEmail: userPhoneAccountInfo.email,
        isShowingPopUpMoveSuccess: popUpMoveSuccess,
      });
    }
  }, [userPhoneAccountInfo, popUpMoveSuccess]);
  //#endregion useEffect

  //#region functions

  const FetchZoomAccountInfo = async () => {
    if (webApiToken) {
      try {
        setLoadingUserPhoneAccountInfo(true);
        const [phoneAccountInfoResponse] = await Promise.all([
          // GetPhoneAccountInfo(webApiToken, email),
          GetPhoneAccountInfo(webApiToken, userEmail),
        ]);
        setUserPhoneAccountInfo(phoneAccountInfoResponse.data);
      } catch (err) {
        console.log("err.message", err.message);
        setErrorUserPhoneAccountInfo(err);
      } finally {
        setLoadingUserPhoneAccountInfo(false);
      }
    }
  };

  // Phone Number Request
  const handlePhoneNumberRequest = async () => {
    if (webApiToken) {
      setPhoneNumberRequestLoading(true);
      try {
        let formData = new FormData();
        formData.append("siteId", userPhoneAccountInfo.siteId);
        formData.append("userId", userPhoneAccountInfo.email);
        
        const [phoneNumberRequestResponse] = await Promise.all([
          // GetPhoneAccountInfo(webApiToken, email),
          PatchPhoneNumberRequest(webApiToken, formData),
        ]);

        // setPhoneNumberRequestResultData( phoneNumberRequestResponse.data)
        setUserPhoneAccountInfo(phoneNumberRequestResponse.data);
        setPhoneNumberRequestSuccess(true);
      } catch (err) {
        setPhoneNumberRequestError(err.message);
        setPhoneNumberRequestFail(true);
      } finally {
        setPhoneNumberRequestLoading(false);
      }
    }
  };

  // CHECK IF THE USER IS IN WAVE 1 SITES WITH ZOOM PHONE,
  // IF NOT RETURN AN OBJECT WITH THE USER EMAIL AND showErrorZoomPhoneNotEnabled AS TRUE
  const CheckIfTheUserIsInWave1SiteList = () => {
    if (userPhoneAccountInfo?.siteId && !loadingUserPhoneAccountInfo && zoomSitesEmergencyAddress.length > 0) 
    {


      let isOnboardedSite = phoneSitesOnboarded.some(
        (site) => userPhoneAccountInfo.siteId.toLocaleLowerCase() === site.siteId.toLocaleLowerCase()
      );

      if (!isOnboardedSite)
      {
        let userSite = zoomSitesEmergencyAddress.find(
          (site) => userPhoneAccountInfo.siteId.toLocaleLowerCase() === site.siteId.toLocaleLowerCase()
        );
        
        callBackErrorZoomPhoneNotEnabled({
          showErrorZoomPhoneNotEnabled: true,
          userEmail: userEmail,
          siteName: userSite.siteName.substring(7),
        });
      }
    }
  };

  // CHECK IF THE THERE IS AN ERROR TO GET PHONE ACCOUNT,
  // IF IT IS RETURN AN OBJECT WITH THE USER EMAIL, showErrorUserPhoneAccountInfo AS TRUE AND THE errorUserPhoneAccountInfo
  const CheckIfThereIsPhoneAccountError = () => {
    if (errorUserPhoneAccountInfo) {
      callBackErrorPhoneAccountNotFound({
        showErrorUserPhoneAccountInfo: true,
        userEmail: userEmail,
        errorUserPhoneAccountInfo: errorUserPhoneAccountInfo,
      });
    }
  };

  // CHECK IF THE USER ALREADY HAVE A PHONE NUMBER THAT COULD BE OVERWRITE
  // IF IT IS RETURN AN OBJECT WITH showWarningPhoneNumberOverwrite AS TRUE
  const CheckIFThereIsWarningOverwrite = () => {
    if (userPhoneAccountInfo?.phoneNumbers.length > 0) {
      callBackErrorUserAlreadyHaveAPhone({
        userAlreadyHaveAPhone: true,
        userEmail: userEmail,
      });
    }
  };


  const findZoomCountryCode = (country) => {
    return ZoomCountriesAbbreviationListWithIAMfixes.find(
      (site) => site.Name.toLocaleLowerCase() === country.toLocaleLowerCase()
    );
  };

  const isThereAnySite = (countryCode) => {
    return zoomSitesEmergencyAddress.some(
      (site) =>
        site.country.toLocaleLowerCase() === countryCode.toLocaleLowerCase()
    );
  };

  //#endregion functions

  return (
    <PhoneLocationCardWrapper>
      {/* {phoneNumberRequestLoading && (<LoadingScreen_V2 loadingMessage="Phone Request..."></LoadingScreen_V2>)} */}

      {/* Show Error cannot found phone sites for that country */}
      {!foundCountryInPhoneSites &&
        !loadingUserCountry &&
        !errorUserCountry && (
          <div className="App-em-c-alert App-em-c-alert--error">
            <div className="App-em-c-alert__body">
              <p>
                Sorry the user's location is in a Country ({userCountry}) that
                has not yet implemented external calling. You cannot request a
                Zoom location update at this time.
              </p>
            </div>
          </div>
        )}

      {phoneNumberRequestSuccess && (
        <div className="PhoneUserCard-em-c-alert PhoneUserCard-em-c-alert--success">
          <div className="PhoneUserCard-em-c-alert__body">
            Congratulations! User has been assigned a Zoom phone number.&nbsp;{" "}
            <Link target="_blank" to="/phone-request-logs">
              Click here
            </Link>{" "}
            to verify your request was submitted.
          </div>
          <div className="PhoneUserCard-em-c-alert__actions">
            <button
              className="PhoneUserCard-em-c-text-btn"
              onClick={() => setPhoneNumberRequestSuccess(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {phoneNumberRequestFail && phoneNumberRequestError === 404 && (
        <div id="error-get-phone-msg">
          <div className="PhoneUserCard-em-c-alert PhoneUserCard-em-c-alert--error">
            <div className="PhoneUserCard-em-c-alert__body">
              Unfortunately we cannot assign a number at this time. <br></br>
              An email has been triggered to the Support team. Someone will handle
              your request soon.
            </div>
            <div className="PhoneUserCard-em-c-alert__actions">
              <button
                type="button"
                className="PhoneUserCard-em-c-text-btn PhoneUserCard-em-c-alert--error"
                onClick={() => setPhoneNumberRequestFail(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {phoneNumberRequestFail && phoneNumberRequestError !== 404 && (
        <div className="PhoneUserCard-em-c-alert PhoneUserCard-em-c-alert--error">
          <div className="PhoneUserCard-em-c-alert__body">
            There is an error. Please try again. If not resolved, please &nbsp;{" "}
            <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
              goto/GenericSR
            </a>{" "}
            to submit a ticket and assign to Zoom Support group or contact the
            &nbsp;{" "}
            <a target="_blank" href="https://goto/itservices" rel="noreferrer">
              Help Desk
            </a>
            . <br></br>
            {phoneNumberRequestError}
          </div>
          <div className="PhoneUserCard-em-c-alert__actions">
            <button
              type="button"
              className="PhoneUserCard-em-c-text-btn PhoneUserCard-em-c-alert--error"
              onClick={() => setPhoneNumberRequestFail(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {showWarningPhoneNumberOverwrite && !phoneNumberRequestSuccess && (
        <div className="PhoneUserCard-em-c-alert PhoneUserCard-em-c-alert--warning">
          <div className="PhoneUserCard-em-c-alert__body">
            The user below already has a Zoom phone number which will be
            overwritten if you continue with this request. If you are unsure of
            this action, please remove them from the list by clicking the X
            icon.
          </div>
          <div className="PhoneUserCard-em-c-alert__actions">
            <button
              type="button"
              className="PhoneUserCard-em-c-text-btn PhoneUserCard-em-c-alert--warning"
              onClick={() => setShowWarningPhoneNumberOverwrite(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <div className="em-c-card">
        <div className="em-c-card__body">
          <div className="em-c-media-block__body">
            <span className="em-c-media-block__headline fn">
              {userEmail}
              <br></br>
            </span>
              {userPhoneAccountInfo &&
              userPhoneAccountInfo.phoneNumbers &&
              userPhoneAccountInfo.phoneNumbers.length > 0 && (
                <span className="PhoneUserCard-PhoneNumbers">
                  <b>
                    Zoom Phone: {userPhoneAccountInfo.phoneNumbers[0].number}
                  </b>
                </span>
              )}
              {userPhoneAccountInfo &&
              userPhoneAccountInfo.phoneNumbers &&
              userPhoneAccountInfo.phoneNumbers.length > 1 && (
                <span className="PhoneUserCard-PhoneNumbers">
                  <b>
                    Second Zoom Phone: {userPhoneAccountInfo.phoneNumbers[1].number}
                  </b>
                </span>
              )}
          </div>

          <div
            className={`em-c-card__footer ${
              showChangeSiteButton ? "em-c-card__footer_hover" : ""
            }`}
            onMouseEnter={() => setShowChangeSiteButton(true)}
            onMouseLeave={() => setShowChangeSiteButton(false)}
          >
            {!loadingUserPhoneAccountInfo &&
            !phoneNumberRequestLoading &&
            !showChangeSite ? (
              <div>
                <span className="card_headline">Zoom Phone Location</span>

                {userSiteLocation && (
                  <ul className="em-c-link-list em-c-link-list--small em-c-link-list--has-icons">
                    <li
                      className="em-c-link-list__item"
                      key={userSiteLocation.country}
                    >
                      {/* <svg className="em-c-icon em-c-icon--small em-c-link-list__icon">
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../images/em-icons.svg#envelope-closed"></use>
                          </svg> */}
                      <span className="em-c-link-list__text">
                        Country: {userSiteLocation.country}
                      </span>
                    </li>
                    <li
                      className="em-c-link-list__item"
                      key={userSiteLocation.stateCode}
                    >
                      <span className="em-c-link-list__text">
                        State: {userSiteLocation.stateCode}
                      </span>
                    </li>
                    <li
                      className="em-c-link-list__item"
                      key={userSiteLocation.city}
                    >
                      <span className="em-c-link-list__text">
                        City: {userSiteLocation.city}
                      </span>
                    </li>
                    <li
                      className="em-c-link-list__item"
                      key={userSiteLocation.siteName}
                    >
                      <span className="em-c-link-list__text">
                        Zoom Phone Site Name: {userSiteLocation.siteName}
                      </span>
                    </li>
                  </ul>
                )}

                {/* {phoneNumberRequestSuccess && (               
                    <div className="em-c-link-list__item">
                      <span className="em-c-link-list__text">New Phone Number: { phoneNumberRequestResultData.phoneNumbers[0].number }</span>
                    </div>                   
                  )} */}
              </div>
            ) : (
              <div className="PhoneUser-circle-loading">
                {loadingUserPhoneAccountInfo && (
                  <LoadingZoomPhoneRequest
                    loadingMessage={"Loading Zoom user account info."}
                    size="40px"
                    color="#007bff"
                  />
                )}
                {phoneNumberRequestLoading && (
                  <LoadingZoomPhoneRequest
                    loadingMessage={"Loading Zoom phone number request."}
                    size="40px"
                    color="#007bff"
                  />
                )}
              </div>
            )}
          </div>

          <div
            className="PhoneRequestForm-change-site-btn"
            onMouseEnter={() => setShowChangeSiteButton(true)}
            onMouseLeave={() => setShowChangeSiteButton(false)}
          >
            {showChangeSiteButton &&
              !showChangeSite &&
              !loadingUserPhoneAccountInfo &&
              !phoneNumberRequestLoading &&
              !zoomSitesEmergencyAddressFail &&
              !triggerSubmitPhoneRequest && (
                <div>
                  <button
                    type="button"
                    className="PhoneUserCard-btn btn-large-change-site"
                    onClick={() => setShowChangeSite(true)}
                    disabled={isLoading || isShowingPopUp}
                  >
                    Update Zoom Location {"   "}
                    <SVGPencil specificSize="20" />
                  </button>
                  <button
                    type="button"
                    className="PhoneUserCard-remove-user-btn"
                    onClick={() =>
                      callBackRemoveUserFromBulkGetNumber(userEmail)
                    }
                  >
                    <SVGXFilled specificSize="20" />
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>

      {showChangeSite && !zoomSitesEmergencyAddressFail && (
        <PhoneMoveCard
          userCountry={userCountryZoomFormat}
          user={userPhoneAccountInfo}
          setUser={setUserPhoneAccountInfo}
          
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          
          setIsShowingPopUp={setIsShowingPopUp}
          isShowingPopUp={isShowingPopUp}
          
          zoomSitesEmergencyAddressLoading={zoomSitesEmergencyAddressLoading}
          zoomSitesEmergencyAddress={zoomSitesEmergencyAddress}
          phoneSitesOnboarded={phoneSitesOnboarded}
          
          showChangeSite={showChangeSite}
          setShowChangeSite={setShowChangeSite}
          
          popUpMoveSuccess={popUpMoveSuccess}
          setPopUpMoveSuccess={setPopUpMoveSuccess}
        />
      )}
    </PhoneLocationCardWrapper>
  );
};

export default PhoneUserCard;
