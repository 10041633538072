
import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import styled from "styled-components";

const RouteGuardWrapper = styled.div`
    .data-area-div{
        text-align: center;
    }
`
export const RouteGuard = ({ Component, ...props }) => {

    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);

    const onLoad = async () => {
        // const currentAccount = instance.getAllAccounts()[0];
        const currentAccount = instance.getAllAccounts()[0];
        const LoadedRoles = currentAccount.idTokenClaims.roles;
        const filteredArray = LoadedRoles.filter(value => props.role.includes(value));

        if (filteredArray.length > 0) {
            setIsAuthorized(true);
        }
    }

    useEffect(() => {
        onLoad();
    }, [instance]);

    return (
        <RouteGuardWrapper>
            {
                isAuthorized
                    ?
                    <Route {...props} render={routeProps => <Component {...routeProps} />} />
                    :
                    <div className="data-area-div">
                        <h3>You are not authorized to view this content. Please contact Administrator.</h3>
                    </div>
            }
        </RouteGuardWrapper>
    );
};