import CircleLoader from "react-spinners/CircleLoader";
import styled from "styled-components";

const Div = styled.div`
  .circle{
    position: absolute;
    width: 200px;
    height: 200px;
    margin: auto; /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
    transform: translateY(50px);
  }

  .loading-message{
    align-items: center;
    text-align: center;
    transform: translate(0px, 200px);
    padding-top: 10px;
  }
`;

const LoadingScreen_V2 = ({loadingMessage}) => {
  return (
    <Div>
      <div className="circle">
        <CircleLoader size="200px" color="#007bff" />
        <div className="loading-message">
          <p>{loadingMessage}</p>
        </div>
      </div>

    </Div>
  );  
};

export default LoadingScreen_V2;
