import CsvDownloader from "react-csv-downloader";
import styled from "styled-components";
import { SVGcsv } from "../Assets/SVGcsv";

const StyledButton = styled.button`
  .textClass {
    margin: 0 10px 0 10px;
  }
`;

export const ExportCSVButton = (props) => {
  return (
    <CsvDownloader
      datas={props.data}
      filename={props.filename}
      wrapColumnChar="'"
    >
      <StyledButton className="btn btn-exp flex-row">
        <SVGcsv specificSize="15" />
          <p className="textClass">Download CSV</p>
      </StyledButton>
    </CsvDownloader>
  );
};
