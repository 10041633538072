import { useState, useEffect} from "react";
import { useParams } from 'react-router-dom';

import PopUp from "../../Components/Popup";
import LoadingScreen_V2 from "../../Components/LoadingScreen_V2";
import Input from "../../Components/Input";
import RequestedBy from "../../Components/RequestedBy/RequestedBy";

//api calls
import useWebAPIToken from "../../Hooks/useWebAPIToken";
import { IDMConfirmRequest } from "../../Hooks/funcIDMConfirmRequest";
import useAccessToken from "../../Hooks/useAccessToken";

import { useFetch } from "../../Hooks/useFetch";

import "./IDMSync.css";
/////////////////////////MAIN COMPONENT/////////////////////////////////

const IDMSync = () => {
  //#region vars and setters

  const params = useParams();
    
    const [IDMSyncRequestConfirmation, setIDMSyncRequestConfirmation] = useState(false);

    const [successfullyIDMConfirmed, setSuccessfullyIDMConfirmed] = useState(false);
    const [isConfirmRequestLoading, setIsConfirmRequestLoading] = useState(false);
    const [isFailToConfirmRequest, setIsFailToConfirmRequest] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(false);

    
    // SETTING LOCAL STATE FOR THE COMPONENT
    // 1. requestedByEmail EMAIL
    const [requestedByEmail , setRequestedByEmail] = useState("");
    // 2. GENERATES TOKEN FOR THE GRAPH API
    const accessToken = useAccessToken();
    // 3. TOKEN FOR CUSTOM WEB API
    const webApiToken = useWebAPIToken();

    // const { data: harvestInfo = null, error: errorHarvestInfo = null, isFetching: loadingHarvestInfo = null } = useFetch(`/PhoneHarvest/IdmConfirm?id=${params.requestId}`);

    
  //#endregion vars and setters

  //#region Functions
    
    // keep request
    const handleConfirmIDM = async () => {
      if (webApiToken) {
        setIsConfirmRequestLoading(true);
        setIsFailToConfirmRequest(false);
        try {
          let formData = new FormData();
          formData.append("requestId", params.requestId)
          const [ConfirmIDMResponse] = await Promise.all([
            IDMConfirmRequest(webApiToken, formData),
          ]);

          setSuccessfullyIDMConfirmed(true);

        } catch (err) {
          setIsFailToConfirmRequest(true);
        }
        finally{
          setIsConfirmRequestLoading(false)
        }
      }
    }
    
  //#endregion Functions

  //#region useEffect
  

    useEffect(()=>{
      setIsLoading(isConfirmRequestLoading);
      setLoadingMessage("Processing confirm IDM request.");
    },[isConfirmRequestLoading])
    
  //#endregion useEffect

 
  // TODO CHECK WHAT HAPPEN WHEN CONFIRMED
  // const isUserAlreadyConfirm = errorHarvestInfo?.response.status === 404;
  const isUserAlreadyConfirm = false;
  
  // JSX CODE FOR MAIN COMPONENT
  return (
    <div className="IDMSync-container">
      <h1>Zoom IDM Sincronization</h1>
      <div className="IDMSync-horizontal_line" />
      <div className="IDMSync-description">
        <br />
          <p>
            Use this form to confirm if your IDM locations is correct. 
          </p>
        <ul>
          <li>
            If you do not submit this form by the date stated on the email, the Zoom team will assume your IDM location is correct then continue the sincronization process.
          </li>     
        </ul>
      </div>

      {isLoading && (<LoadingScreen_V2 loadingMessage={loadingMessage}></LoadingScreen_V2>)}
      
      <main className={ isLoading ? 'IDMSync-loading' : ''}>
         
        <div>
          <RequestedBy setRequestedByEmail={setRequestedByEmail}/>

          {/*Error return/keep request*/}
          {  isFailToConfirmRequest &&(
            //<div id="warning-get-phone-msg">
            <div className="App-em-c-alert App-em-c-alert--error ">
            <div className="App-em-c-alert__body">
                There is an error. Please try again. If not resolved, please <a target="_blank" 
                href={"https://goto/GenericSR"}>goto/GenericSR</a> to submit a ticket and assign to Zoom Support group or contact the <a target="_blank" 
                href="https://goto/itservices">Help Desk</a>. <br></br>
              </div>
            </div>
          )} 

          { successfullyIDMConfirmed && (
            <div className="App-em-c-alert App-em-c-alert--success">
              <div className="App-em-c-alert__body">
                confirmation successful, no further action required.
              </div>
            </div>
          )}

          { !successfullyIDMConfirmed &&
            (
              <div>
                <button
                  id="btn-confirm"
                  className="App-btn App-btn-large"
                  type="button"
                  onClick={() => { setIDMSyncRequestConfirmation(true) }}
                  disabled={ isLoading }
                >
                  Confirm
                </button>
              </div>
            )
          }

          {IDMSyncRequestConfirmation && (
            <PopUp
              title="IDM location confirmation"
              message= "Are you sure you want to confirm the IDM location information is correct?"
              showPopUp={IDMSyncRequestConfirmation}
              setShowPopUp={setIDMSyncRequestConfirmation}
              cancelCallback={() => setIDMSyncRequestConfirmation(false)}
              submitForm={() => handleConfirmIDM()}
            >
            </PopUp>
          )}      
        </div>
          
      </main>


    
    
    </div>
  );

};

export default IDMSync;