import { useEffect, useState }  from "react";
import { Link }                 from "react-router-dom";

// import "@exxonmobil/react-unity/dist/unity.css";
// import { Button } from "@exxonmobil/react-unity";

//Components
import LoadingScreen_V2         from "../../Components/LoadingScreen_V2";
import AutoComplete             from "../../Components/AutoComplete/AutoComplete";
import Input                    from "../../Components/Input";
import PopUp                    from "../../Components/Popup";
import PhoneUserForeignDIDCard     from "../../Components/PhoneUserForeignDIDCard/PhoneUserForeignDIDCard";

//API calls 
import FetchGraphData           from "../../Hooks/funcFetchGraphData";
import useAccessToken           from "../../Hooks/useAccessToken";
import { useFetch }             from "../../Hooks/useFetch";

// CSS
import PhoneRequestForeignFormWrapper from "./PhoneRequestForeignForm.css";

/////////////////////////MAIN COMPONENT/////////////////////////////////
const PhoneRequestForeignForm = () => {
  // #region vars and setters
    // SETTING LOCAL STATE FOR THE COMPONENT
    // 1. requestedByEmail EMAIL, user who is submitting the request.
    const [requestedByEmail                   , setRequestedByEmail]                  = useState("");
    // 2. inputSearchRequestedFor is the value for the input "Requested for".
    const [inputSearchRequestedFor            , setInputSearchRequestedFor]           = useState("");
    // 3. searchResultRequestedFor is list of objects (users) result from the graph API call passing the inputSearchRequestedFor.
    const [searchResultRequestedFor           , setSearchResultRequestedFor]          = useState([]);
    // 4. requestedForSelected is the object (user) selected from the input "Requested for".
    const [requestedForSelected               , setRequestedForSelected]              = useState({});
    // 5. showCouldNotFoundOnAzureAD is a boolean; a true value means the graph API didn't return any users, then show warning to user.
    const [showCouldNotFoundOnAzureAD         , setShowCouldNotFoundOnAzureAD]        = useState(false);
    // 6. phoneNumberRequestConfirmation is a boolean; a true value means show to user a confirmation popup for "Get Number".
    const [phoneNumberRequestConfirmation     , setPhoneNumberRequestConfirmation]    = useState(false);
    // 7. triggerSubmitPhoneRequest is a boolean; a true value means user confirms the popup then submit the get number request.
    const [triggerSubmitPhoneRequest          , setTriggerSubmitPhoneRequest]         = useState(false);
    // 8. isLoading is a boolean; a true value means that something is currently being processed, such as an API call that hhas not yet completed.
    const [isLoading                          , setIsLoading]                         = useState(false);
    // 9. isShowingPopUp is a boolean; a true value means there is a pop up beeing showed to user.
    const [isShowingPopUp                     , setIsShowingPopUp]                    = useState(false);
    // 10. isError is a boolean; a true value means there is something wrong.
    const [isError                            , setIsError]                           = useState(false);
    // 12. foreignSiteDID is a string; a value for the input "Foreign Site".
    const [foreignSiteDID                     , setForeignSiteDID]                    = useState("NA_EMC_Houston_Campus");
    // 13. foreignSiteSelected is the object selected from the input "Foreign Site".
    const [foreignSiteSelected                , setForeignSiteSelected]               = useState({"siteId": process.env.REACT_APP_HOUSTON_ID, "siteName": "NA_EMC_Houston_Campus"});
    // 14. userPhonesCount is a number; a value represents the number of Zoom phone number assined to the user.
    const [userPhonesCount                    , setUserPhonesCount]                   = useState(0);
    // 15. urlGetUserSupervisor is a string; a value represents the endpoint to call the API to return the user's supervisor.
    const [urlGetUserSupervisor               , setUrlGetUserSupervisor]              = useState(null);
    // 16. urlGetUserPhoneAccount is a string; a value represents the endpoint to call the API to return the user's Zoom phone account.
    const [urlGetUserPhoneAccount             , setUrlGetUserPhoneAccount]            = useState(null);
    // 17. businessJustificationInput is the value for the input "Business Justification".
    const [businessJustificationInput         , setBusinessJustificationInput]        = useState("");
    // 18. businessJustificationInput is not empty.
    // 19. show popup to indicate that user already has 2 DIDs
    const [showErrorHasDualDID, setShowErrorHasDualDID] = useState(false);
    // 20. OBJECT THAT CONTAINS THE VALIDITY STATES FOR ALL THE INPUTS
    const [inputStates, setInputStates] = useState({});
    // 21. OBJECT THAT TURNS ALL FIELDS TO TOUCHED WHEN SUBMIT BUTTON IS CLICKED
    const [touchAll, setTouchAll] = useState(false);
   
    const [phoneNumberRequestLoading, setPhoneNumberRequestLoading]             = useState(false);

    const [phoneNumberRequestSuccess, setPhoneNumberRequestSuccess]             = useState(false);

    const [showCondragulationsMessage, setShowCondragulationsMessage]           = useState(true);

    
    // API CALLS
    // 1. Return the user supervisor passing the user email as parameter
    const { data: userSupervisor            = null, error: userSupervisorError            = null, isFetching: userSupervisorLoading             = null, setData: setUserSupervisor } = useFetch(urlGetUserSupervisor);
    // 2. Return Zoom Sites Emergency Addresses
    const { data: zoomSitesEmergencyAddress = null, error: zoomSitesEmergencyAddressError = null, isFetching: zoomSitesEmergencyAddressLoading  = null } = useFetch("/PhoneRequest/GetEmergencyAddressList");   
    // 4 . Return graph AccessToken, isued to call Microsoft Graph APIs
    const { data: graphAccessToken          = null, error: graphAccessTokenError          = null, isFetching: graphAccessTokenLoading           = null } = useFetch("/PhoneRequest/GetGraphToken");   
    // 5 . Return user's Zoom phone account
    const { data: userPhoneAccount          = null, error: userPhoneAccountError          = null, isFetching: userPhoneAccountLoading           = null, setData: setUserPhoneAccount } = useFetch(urlGetUserPhoneAccount);   

    // SETTING LOCAL CONSTANT FOR THE COMPONENT
    // 1. GENERATES TOKEN FOR THE GRAPH API
    const accessToken = useAccessToken();

    // List of possible sites for the second DID feature
    const foreignSiteOptions = [
      {"siteId": process.env.REACT_APP_HOUSTON_ID,             "siteName": "NA_EMC_Houston_Campus"},
      {"siteId": process.env.REACT_APP_CALGARY_QUARRY_PARK_ID, "siteName": "NA_CQP_Calgary_Quarry_Park"}
    ]

  //#endregion vars and setters

  //#region Functions

    const handleNewRequest = () => {
      setTriggerSubmitPhoneRequest(false);
      setBusinessJustificationInput("");
      
      setInputSearchRequestedFor("");
      setSearchResultRequestedFor([]);
      
      setRequestedForSelected({});
      
      setInputStates({});
      setTouchAll(false);
      setUrlGetUserSupervisor(null);
      setUrlGetUserPhoneAccount(null);
    };

    //TODO verificar se precisa mesmo
    
    // const handleClearRequestedForField = () => {
    //   setRequestedForSelected({});
    //   setSearchResultRequestedFor([]);
    // };

      // Handle the click on the icon "X" from a user into the bulk list get number. Remove a specific
    // user from bulkUsersGetNumber by userEmail
    const removeUserGetNumber = () => {
      setUserPhoneAccount(null);
      setUserSupervisor(null);
      setRequestedForSelected({});
      setUrlGetUserSupervisor(null);
      setUrlGetUserPhoneAccount(null);

    };

    // const removeUserFromReqFor = () => {
    //   let aux = searchResultRequestedFor;
    //   aux.forEach((user, index) => {
    //     if (user === requestedForSelected) {
    //       aux.splice(index, 1);
    //     }
    //   });
    //   setSearchResultRequestedFor(aux);
    // };

    // FUNCTION TO UPDATE THE INPUT STATES
    // USED TO DEFINE IF THE GLOBAL FORM STATE IS VALID OR INVALID
    const GetState = (state) => {
      setInputStates((oldState) => {
        return { ...oldState, ...state };
      });
    };

    const checkForm = () => {
      setTouchAll("trigger");
      if (!Object.values(inputStates).includes(false)) {
        setPhoneNumberRequestConfirmation(true);
      } else {
        console.log("Form not filled properly");
      }
    }

  //#endregion Functions

  //#region useEffect

    // Update the urlGetUserSupervisor and  based on the selected user from search field, to call API to get 
    // the user supervisor and the phone account info from the users who is getting a phone
    useEffect(() => {
      if(requestedForSelected?.mail?.length){
        setUrlGetUserSupervisor(`/PhoneRequest/GetUserSupervisor?userEmail=${requestedForSelected.mail}`)
        setUrlGetUserPhoneAccount(`/PhoneRequest/GetPhoneAccountInfo?userEmail=${requestedForSelected.mail}`)
        setInputSearchRequestedFor("");
        setUserSupervisor("");
        setUserPhoneAccount(null);
      }
    }, [requestedForSelected])

    useEffect(() => {
      if(userPhoneAccount){
        setUserPhonesCount(userPhoneAccount.phoneNumbers.length)
        if (userPhoneAccount.phoneNumbers.length > 1) {
          setShowErrorHasDualDID(true)
        }
      }
    }, [userPhoneAccount]);

    // CUSTOM FUNCTION TO FETCH DATA FROM /me ENDPOINT FROM GRAPH API
    // WHEN THE HTTP REQUEST IS DONE, the state requestedByEmail IS UPDATED WITH
    // USER DATA RECEIVED FROM GRAPH
    useEffect(() => {
      if (accessToken) {
        FetchGraphData({
          endpoint: "/me",
          accessToken: accessToken,
        }).then((data) => {
          setRequestedByEmail(data.mail);
        });
      }
    }, [accessToken]);

    // CUSTOM FUNCTION TO FETCH DATA FOR inputSearchRequestedFor FROM GRAPH API
    // WHEN THE HTTP REQUEST IS DONE, the state RequestedForSearchBulkResult IS UPDATED WITH
    // USER DATA RECEIVED FROM GRAPH
    useEffect(() => {
      let timeout;
      if (graphAccessToken && inputSearchRequestedFor.length > 0) {
        timeout = setTimeout(async () => {
          let data = await FetchGraphData({
            endpoint:
              `/users?` +
              `$search="displayName:${inputSearchRequestedFor}" OR "mail:${inputSearchRequestedFor}"&` +
              `$filter=mail ne null&` +
              `$select=displayName,mail&` +
              `$orderby=displayName asc`,
            accessToken: graphAccessToken,
          });
          if (data.value && data.value.length > 0) {
            setShowCouldNotFoundOnAzureAD(false);
            setSearchResultRequestedFor(
              data.value.map((entry) => {
                return { mail: entry.mail, displayName: entry.displayName };
              })
            );
          } else {
            console.log("could not find this user please ask for an exception");
            setShowCouldNotFoundOnAzureAD(true);
          }
        }, 300);
      }
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }, [inputSearchRequestedFor, graphAccessToken]);


    //TODO verificar se precisa mesmo
    // useEffect(() => {
    //   if (inputSearchRequestedFor.length === 0) {
    //     handleClearRequestedForField();
    //   }
    // }, [inputSearchRequestedFor]);

    // se adicioanr alguem zera o campo
    // se cliclar fora zera tb

    // CUSTOM FUNCTION TO UPDATE isLoading
    // isLoading IS A STATE THAT REPRESENTS IF THERE IS ANY REQUESTION LOADING
    useEffect(() => {
      setIsLoading(
        zoomSitesEmergencyAddressLoading 
        || graphAccessTokenLoading 
        || userSupervisorLoading 
        || userPhoneAccountLoading
        || phoneNumberRequestLoading); 
      },
      [
        zoomSitesEmergencyAddressLoading, 
        graphAccessTokenLoading, 
        userSupervisorLoading, 
        userPhoneAccountLoading,
        phoneNumberRequestLoading]
    );

    // CUSTOM FUNCTION TO UPDATE isError
    // isError IS A STATE THAT REPRESENTS IF THERE IS ANY REQUESTION RESPONSE IS AN ERROR
    useEffect(() => {
      setIsError(
        userSupervisorError 
        || zoomSitesEmergencyAddressError 
        || graphAccessTokenError);
      }, 
      [
        userSupervisorError, 
        zoomSitesEmergencyAddressError, 
        graphAccessTokenError]
    );

    // CUSTOM FUNCTION TO UPDATE isShowingPopUp
    // isShowingPopUp IS A STATE THAT REPRESENTS IF THERE IS SHOWING ANY POPUP
    useEffect(() => {
      setIsShowingPopUp(phoneNumberRequestConfirmation);
    }, [phoneNumberRequestConfirmation]);

  //#endregion useEffect

  // JSX CODE FOR MAIN COMPONENT
  return (
    <PhoneRequestForeignFormWrapper>

      {/* <Button>Example Button</Button> */}

      <h1>Zoom Phone Foreign Request Form</h1>
      <div className="App-horizontal-line" />
      <br />
      <p>
        This form is used to request <b>Zoom Phone number</b> from  <b>external Zoom site</b>, in other words, request a number from a Zoom site that 
        you are not based on.
        Only users from those at{" "}
        <a
          href="https://ishareteam2.na.xom.com/sites/ModWkplc/moc/Shared%20Documents/Zoom%20External%20Calling%20deployed%20and%20approved%20Sites.pdf"
          target="__blank"
        >
          approved locations
        </a>{" "}
        may request Zoom Phone number from external Zoom site at this time.
      </p>
      <p>
        Only request a Zoom Phone from external Zoom site if you have a business case for
        making/receiving external calls. You do not need to request external
        dialing from external Zoom site in the following cases:
      </p>
      <ul>
        <li>
          Occasional calls with external parties. You can use email, Zoom
          meetings, or a mobile device to occasionally talk with external
          parties.
        </li>
        <li>
          Emergency dialing is available with internal Zoom calling; an external
          dialing plan is not required.
        </li>
      </ul>
      <p>
        <b>Note:</b> This is an exception request that requires your supervisor/manager approval.
      </p>
      <p>
        You can verify the status of your request that was submitted by viewing the{" "}
        <Link target="_blank" to="/phone-foreign-request-logs">
          Submitted Zoom Phone Requests from external Zoom site.
        </Link>
      </p>

      <div className="App-horizontal-line" />

      {isLoading && (
        <LoadingScreen_V2 loadingMessage="Loading Zoom information."></LoadingScreen_V2>
      )}

      {phoneNumberRequestSuccess && showCondragulationsMessage &&(
        <div className="App-em-c-alert App-em-c-alert--success">
          <div className="PhoneUserDualDIDCard-em-c-alert__body">
            Congratulations! Your Zoom phone foreign request has been submitted and now is waiting for approval. {` `}
            <Link target="_blank" to="/phone-request-foreign-logs">
              Click here
            </Link>{" "}
            to verify the status of your request.
          </div>
          <div className="App-em-c-alert__actions">
            <button
              className="App-em-c-text-btn"
              onClick={() => setShowCondragulationsMessage(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

        {showCouldNotFoundOnAzureAD && (
          <div id="error-notfounduser">
            <div className="em-c-alert em-c-alert--warning">
              <div className="em-c-alert__body">
                User not found. Check if the user is in Identity
                Manager,&nbsp;
                <a href="https://goto/idm" target="__blank">
                  goto/IDM
                </a>
                . If yes, please try again in 24 hours. If they are not in
                Identity Manager,&nbsp;
                <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
                  goto/GenericSR
                </a>{" "}
                to submit a ticket and assign to Zoom Support group or contact
                the&nbsp;
                <a target="_blank" href="https://goto/itservices" rel="noreferrer">
                  Help Desk
                </a>
                .
              </div>
              <div className="em-c-alert__actions">
                <button
                  type="button"
                  className="em-c-text-btn em-c-alert--warning"
                  onClick={() => setShowCouldNotFoundOnAzureAD(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        <div>
          {/* Show Error Zoom User already has 2 DIDs */}
          {showErrorHasDualDID && (
            <div id="error-get-phone-msg">
              <div className="em-c-alert em-c-alert--warning">
                <div className="em-c-alert__body">
                  This user already has 2 phone numbers, you cannot
                  request a new one. If you still need another number please use the
                  &nbsp;
                  <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
                    Zoom Phone Return Form
                  </a>
                  &nbsp; to unassign one or more phone number from this user.
                </div>
                <div className="em-c-alert__actions">
                  <button
                    type="button"
                    className="em-c-text-btn em-c-alert--warning"
                    onClick={() => {
                      handleNewRequest()
                      removeUserGetNumber()
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Show Error Phone Account Not Found */}
          {userPhoneAccountError && userPhoneAccountError.message === "Not Found" 
            && (
              <div id="warning-get-phone-msg">
                <div className="em-c-alert em-c-alert--error ">
                  <div className="em-c-alert__body">
                    This user is not yet set up in Zoom. Please try again
                    tomorrow.
                  </div>
                  {/* <div className="em-c-alert__actions">
                    <button
                      type="button"
                      className="em-c-text-btn em-c-alert--error "
                      onClick={() => setShowErrorPhoneAccountNotFound(false)}
                    >
                      Close
                    </button>
                  </div> */}
                </div>
              </div>
            )}

          {/* Fail get site locations */}
          {isError && (
            <div className="em-c-alert em-c-alert--error">
              <div className="em-c-alert__body">
                There is an error. Please try again. If not resolved, please
                &nbsp;
                <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
                  goto/GenericSR
                </a>{" "}
                to submit a ticket and assign to Zoom Support group or contact
                the Help Desk.<br></br>
              </div>
            </div>
          )}
        </div>

      {!phoneNumberRequestSuccess && !showErrorHasDualDID
        && (
        <div className={ isLoading ? "loading" : "" } >
          <h3>Get Zoom Phone Foreign Number</h3>
          <div>
          {!isError &&(<div>
            <Input
              note="Requester email address."
              label="Requested by"
              type="text"
              placeholder="user name"
              value={requestedByEmail}
              disabled
              toolTipMessage="Email of person submitting the request."
            />

            {/*  display new DID options */}
            <AutoComplete
              label="Foreign Site"
              inputValue={foreignSiteDID}
              setInputValue={setForeignSiteDID}
              note=
                  "External Zoom site." 
              
              listOfObjects={foreignSiteOptions}
              optionKeyValues={["siteName"]}
              returnSelectedObj={setForeignSiteSelected}
              toolTipMessage="External Zoom phone site for DID."
            >
            </AutoComplete>

            <AutoComplete
              label="Requested for"
              inputValue={inputSearchRequestedFor}
              setInputValue={setInputSearchRequestedFor}
              note={
                <p>
                  You can search by name or ExxonMobil email. <br></br>You may
                  add more than one user to the <b>requested for</b> list.
                </p>
              }
              listOfObjects={searchResultRequestedFor}
              optionKeyValues={["mail"]}
              returnSelectedObj={setRequestedForSelected}
              toolTipMessage="Email of person receiving the Zoom Phone."
              disableValidation
              userType
            >
            </AutoComplete>

            <div className="PhoneRequestForeignForm-business-justification">
              <Input
                label="Business Justification"
                type="textarea"
                placeholder="enter text here"
                value={businessJustificationInput}
                setValue={setBusinessJustificationInput}
                validationRules={{ minLength: 4 }}
                GetState={GetState}
                touchAll={touchAll}
              />
            </div>

            {userSupervisor && (
              <Input
                note="Supervisor approver."
                label="Supervisor"
                type="text"
                placeholder="user name"
                value={userSupervisor}
                disabled
                toolTipMessage="Requested for's Supervisor."
              />
            )}

              {userPhoneAccount && !userPhoneAccountLoading && !userPhoneAccountError && !showErrorHasDualDID 
                && (
                  <div className="PhoneRequestForeignForm-getnumber-list">
                    <div className="">
                      <label>Requested for</label>
                        <PhoneUserForeignDIDCard
                          isDualDID={userPhonesCount > 0}
                          setPhoneNumberRequestLoading={setPhoneNumberRequestLoading}
                          phoneNumberRequestLoading={phoneNumberRequestLoading}
                          userPhoneAccount={userPhoneAccount}
                          setUserPhoneAccount={setUserPhoneAccount}
                          zoomSitesEmergencyAddress={zoomSitesEmergencyAddress}                
                          callBackRemoveUserGetNumber={removeUserGetNumber}
                          triggerSubmitPhoneRequest={triggerSubmitPhoneRequest}
                          siteIdForeignDID={foreignSiteSelected.siteId}
                          businessJustificationInput={businessJustificationInput}
                          userSupervisor={userSupervisor}
                          phoneNumberRequestSuccess={phoneNumberRequestSuccess}
                          setPhoneNumberRequestSuccess={setPhoneNumberRequestSuccess}
                        />
                    </div>
                    
                  {!triggerSubmitPhoneRequest ? (
                    <div className="btn-row-section">
                      <div id="btn-get-new-number2">
                        <button
                          className="btn btn-medium"
                          type="button"
                          onClick={() => {
                            checkForm()
                          }}
                          disabled={
                            isLoading ||
                            isShowingPopUp ||
                            foreignSiteDID === "" ||
                            showErrorHasDualDID
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="btn-row-section">
                      <div id="btn-get-new-number3">
                        <button
                          className="btn btn-medium"
                          type="button"
                          onClick={() => {
                            handleNewRequest()
                            removeUserGetNumber()
                          }}
                          disabled={isLoading || isShowingPopUp}
                        >
                          New Request
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}

          </div>)}

          </div>

          <div id="technical-issues">
            <p>
              For any other items or technical issues{" "}
              <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
                goto/GenericSR
              </a>{" "}
              to submit a ticket and assign to Zoom Support group or contact the{" "}
              <a target="_blank" href="https://goto/itservices" rel="noreferrer">
                Help Desk
              </a>
              .
            </p>
          </div>
        </div>
        )
      }

      {phoneNumberRequestConfirmation && (
        <PopUp
          title="Get New Zoom Phone Number"
          message={`Are you sure you want a new Zoom phone number"?`}
          showPopUp={phoneNumberRequestConfirmation}
          setShowPopUp={setPhoneNumberRequestConfirmation}
          cancelCallback={() => setPhoneNumberRequestConfirmation(false)}
          submitForm={() => setTriggerSubmitPhoneRequest(true)}
        ></PopUp>
      )}
    </PhoneRequestForeignFormWrapper>
  );
};

export default PhoneRequestForeignForm;