import API, { httpStatus, getError } from "./api";
import InternalServerError from "./Errors/InternalServer";

const ReturnRequest = async (accessToken, formObj) => {

  try {
    const api = await API(accessToken);
    return await api.post(`/PhoneHarvest/Return`, formObj);
  } catch (err) {
    if (err.response) {
      if (err.response.status === httpStatus.InternalServerError) {
        throw new InternalServerError();
      }
    }
    throw getError(err);
  }
};

const KeepRequest = async (accessToken, formObj) => {

  try {
    const api = await API(accessToken);
    return await api.post(`/PhoneHarvest/Keep`, formObj);
  } catch (err) {
    if (err.response) {
      if (err.response.status === httpStatus.InternalServerError) {
        throw new InternalServerError();
      }
    }
    throw getError(err);
  }
};

export { ReturnRequest, KeepRequest } ;
