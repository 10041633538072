import { Container } from "reactstrap";
import Footer from "./Footer";
import { NavMenu } from "./NavMenu";
import withAppInsights from '../AppInsights.js';

const Layout = (props) => {
  return (
    <div>
      <NavMenu {...props} />
      <Container style={{ minHeight: "70vh", minWidth: "90%" }}>{props.children}</Container>
      <Footer />
    </div>
  );
};

export default withAppInsights(Layout)
