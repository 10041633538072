import styled from "styled-components";

const SVGStyle = styled.svg`
  width: 15px;
  cursor: pointer;
  margin: 0 10px;

  &.placeholder {
    cursor: unset;
  }

  &.mini {
    width: 13px;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const LeftAngle = (props) => {
  return (
    <SVGStyle
      aria-hidden="true"
      data-prefix="fas"
      data-icon="angle-left"
      viewBox="0 0 256 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
      />
    </SVGStyle>
  );
};

export const RightAngle = (props) => {
  return (
    <SVGStyle
      aria-hidden="true"
      data-prefix="fas"
      data-icon="angle-right"
      viewBox="0 0 256 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
      />
    </SVGStyle>
  );
};

export const DownAngle = () => {
  return (
    <SVGStyle
      className="mini"
      aria-hidden="true"
      data-prefix="fas"
      data-icon="angle-down"
      viewBox="0 0 320 512"
    >
      <path
        fill="currentColor"
        d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
      />
    </SVGStyle>
  );
};

export const TopAngle = () => {
  return (
    <SVGStyle
      className="mini"
      aria-hidden="true"
      data-prefix="fas"
      data-icon="angle-down"
      viewBox="0 0 320 512"
    >
      <path
        fill="currentColor"
        d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
      ></path>
    </SVGStyle>
  );
};

export const PlaceholderArrow = () => {
  return (
    <SVGStyle
      className="placeholder"
      aria-hidden="true"
      data-prefix="fas"
      data-icon="angle-right"
      viewBox="0 0 256 512"
      color="rgba(255, 255, 255, 0)"
    ></SVGStyle>
  );
};

export const CaretDown = (props) => {
  return (
    <SVGStyle
      className="em-c-icon--medium"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
    <title>caret-down</title>
    <path d="M3.621,5h8.758a.66.66,0,0,1,.545,1.033l-4.379,6.4a.66.66,0,0,1-1.089,0l-4.379-6.4A.66.66,0,0,1,3.621,5Z" fill="currentColor"/>
    </SVGStyle>
  );
};

export const CaretLeft = (props) => {
  return (
    <SVGStyle
      className="em-c-icon--medium"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
    <title>caret-left</title>
    <path d="M11,3.269v8.758a.66.66,0,0,1-1.033.545l-6.4-4.379a.66.66,0,0,1,0-1.089l6.4-4.379A.66.66,0,0,1,11,3.269Z" fill="currentColor"/>
    </SVGStyle>
  );
};


export const CaretRight = (props) => {
  return (
    <SVGStyle
      className="em-c-icon--medium"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
		<title>caret-right</title>
		<path d="M5,12.027V3.269a.66.66,0,0,1,1.033-.545l6.4,4.379a.66.66,0,0,1,0,1.089l-6.4,4.379A.66.66,0,0,1,5,12.027Z" fill="currentColor"/>
    </SVGStyle>
  );
};

