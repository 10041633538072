import ToolTipWrapper from "./ToolTip.css.js";
import { SVGCircleQuestionMark } from '../../Assets/SVGTiles';
import ReactTooltip from 'react-tooltip';


const ToolTip = ({tipMessage, svgSize, id, ...rest}) =>{
  return(
    <ToolTipWrapper specificSize={svgSize}>
      <div className="ToolTip-icon" data-tip data-for={id}>
        <SVGCircleQuestionMark specificSize={svgSize}/>
      </div>
      <ReactTooltip type="info" id={id}>{tipMessage}</ReactTooltip>        
    </ToolTipWrapper>
  )
}

export default ToolTip