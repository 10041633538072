import { graphConfig } from "../AppConfig";

const FetchGraphData = async ({ endpoint, accessToken, method = "GET" }) => {
  const options = {
    method: method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ConsistencyLevel: "eventual",
    },
  };
  const resp = await fetch(
    `${graphConfig.graphBaseEndpoint}${endpoint}`,
    options
  );
  const respData = await resp.json();
  return respData;
};

export default FetchGraphData;
