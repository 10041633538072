// import registerServiceWorker from "./registerServiceWorker";
// registerServiceWorker();
import "bootstrap/dist/css/bootstrap.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import { msalConfig } from "./AppConfig";
import ReactDOM from "react-dom";
import { App } from "./App";

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <BrowserRouter basename={process.env.BASE_URL}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
