import styled from "styled-components";

/////////////////////////COMPONENT STYLES/////////////////////////////////
const PhoneRequestLogs = styled.div`
  color: var(--black);
  max-width: 100%;
  background-color: var(--primary-white-light);
  padding: var(--content-padding);
  border-radius: var(--content-border-radius);
  margin: var(--content-margin);
  box-shadow: var(--box-shadow-main);

  .upper-text {
    margin: 0 0 20px 0;
  }

  .dual-did-title {
    font-size: 26px;
    font-weight: 600;
  }

  .loader {
    margin: 40px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }

  .loader > p {
    margin-top: 10px;
    font-size: 16px;
  }

  Table {
    background-color: var(--white);
  }

  .justify-flex {
    justify-content: flex-start;
  }
  
  .outer-container {
    margin: 0 5px;
    border-radius: 10px;
    overflow: hidden;
    border: var(--black) solid 2px;
    height: 35px;
  }

  .last-days-picker {
    height: 35px;
    background-color: var(--black);
    padding: 5px 8px;
    font-size: 16px;
    font-weight: bold;
    border-right: solid 1px var(--black);
    color: var(--white);
  }

  .picker {
    border: none;
    border-left: none;
    font-size: 16px;
    padding: 6px;
    color: var(--black);
  }

  .picker:focus {
    background-color: var(--primary-blue-light);
  }

  .PhoneRequestLogs-component-title {
    font-size: 17px;
    font-weight: 600;
    margin: 5px 0px;
  }

  .PhoneRequestLogs-metrics-body {
    margin: 8px 15px;
  }
`;

export default PhoneRequestLogs;
