import API, { httpStatus, getError } from "./api";
import InternalServerError from "./Errors/InternalServer";
import NotFound from "./Errors/NotFound";
import BadRequest from "./Errors/BadRequest";
import Unauthorized from "./Errors/Unauthorized";

const PostDualDIDRequest = async (accessToken, formObj) => {
  try {
    const api = await API(accessToken);
    return await api.post(`/PhoneRequest/RequestingSupervisorApprovalDualDID`, formObj);
  } catch (err) {
    if (err.response) {
      if (err.response.status === httpStatus.InternalServerError) {
        throw new InternalServerError();
      }
      if (err.response.status === httpStatus.NotFound) {
        throw new NotFound(err.response.status);
      }
      if (err.response.status === httpStatus.BadRequest) {
        throw new BadRequest();
      }
      if (err.response.status === httpStatus.Unauthorized) {
        throw new Unauthorized();
      }
    }
    throw getError(err);



  }
};

export default PostDualDIDRequest;
