import { useEffect, useState } from "react";
import styled from "styled-components";
import Table from "../../Components/Table";
import { GetRecordingException } from "../../Hooks/funcRecordException";
import useWebAPIToken from "../../Hooks/useWebAPIToken";
import CircleLoader from "react-spinners/CircleLoader";

/////////////////////////COMPONENT STYLES/////////////////////////////////
const Wrapper = styled.div`
  color: var(--black);
  max-width: 100%;
  background-color: var(--primary-white-light);
  padding: var(--content-padding);
  border-radius: var(--content-border-radius);
  margin: var(--content-margin);
  box-shadow: var(--box-shadow-main);

  .loader {
    margin: 40px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }

  .loader > p {
    margin-top: 10px;
    font-size: 16px;
  }

  Table {
    background-color: var(--white);
  }

  .justify-flex {
    justify-content: flex-start;
  }
  .outer-container {
    margin: 0 5px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
    border: var(--black) solid 2px;
  }
  .last-days-picker {
    margin: 0;
    background-color: var(--black);
    font-size: 16px;
    padding: 6px 12px;
    border-right: solid 1px var(--black);
    color: var(--white);
  }

  .picker {
    border: none;
    border-left: solid 1px var(--black);
    font-size: 16px;
    padding: 6px;
    color: var(--black);
  }
  .picker:focus {
    background-color: var(--primary-blue-light);
  }

  .RecordingExceptionDisplay-description-text{
    text-align: justify;
  }
`;

/////////////////////////MAIN COMPONENT/////////////////////////////////
const RecordExceptionDisplay = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showLast, setShowLast] = useState(30);
  const webApiToken = useWebAPIToken();

  const changeHandler = (e) => {
    setShowLast(e.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    let timeout;
    if (webApiToken) {
      timeout = setTimeout(() => {
        GetRecordingException(webApiToken, showLast).then((resp) => {
          setData(resp);
          setIsLoading(false);
        });
      }, 100);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [webApiToken, showLast]);

  return (
    <Wrapper>
      <h1>Submitted Zoom meeting Recording Requests</h1>
      <div className="App-horizontal-line" />
      <br />

      <div className="RecordingExceptionDisplay-description-text">
        <div>
          This page is to review the status of your submitted Zoom
          recording request for temporary recording functionality in Zoom .
        </div>
        <ul>
          <li>
            The <b>Recording Start Date</b> is the date when the requestor would
            like to have the recording functionality in Zoom enabled. 
            
            <ul>
              <li>
                For Requests submitted with the Recording Start Date for the current day 
                or a day after, the access is <b>instantly enabled</b> on the user's 
                account. 
              </li>
              <li>
                For Requests submitted for later dates, the access will be enabled a <b>day 
                before </b> (CST timezone).
              </li>
            </ul>
          </li>
          <li>
            The <b>Recording End Date</b> is the date when the requestor no longer 
            needs the zoom meetings record functionality access. The access will be
            removed a <b>day after</b> (CST timezone) the Recording End Date.
          </li>
        </ul>

          The request status could be:
        <ul>
          <li>
            <b>Active</b>: You are able to recording zoom meeting until the end date.
          </li>
          <li>
            <b>Scheduled</b>: Your call recording access has been scheduled 24h before your start date.
          </li>
          <li>
            <b>Expired</b>: Your call recording access has been expired, if you need new access in the future please submit a new request through <a 
            target="_self" href={"https://goto/zoomrecordingrequest"}> goto/ZoomRecordingRequest </a>.
          </li>
          <li>
            <b>Error</b>: There is an error to process your request, please <a target="_blank" href={"https://goto/GenericSR"}>goto/GenericSR</a> to submit a ticket and assign to Zoom Support group.
          </li>
        </ul>

      </div>

      <div className="flex-row justify-flex">
        <div className="flex-row outer-container">
          <label className="last-days-picker">Loading data for last:</label>
          <select className="picker" value={showLast} onChange={changeHandler}>
            <option value={1}>1 day</option>
            <option value={15}>15 days</option>
            <option value={30}>30 days</option>
            <option value={60}>60 days</option>
            <option value={180}>180 days</option>
            <option value={365}>365 days</option>
          </select>
        </div>
      </div>
      {isLoading && (
        <div className="flex-column loader">
          <CircleLoader size="100px" color="#4c4c4c" />
          <p>Loading data...</p>
        </div>
      )}
      {!isLoading && data.length > 0 && <Table data={data} />}
      {!isLoading && data.length === 0 && (
        <div className="flex-column loader">
          <p>No data returned for this user!</p>
        </div>
      )}
    </Wrapper>
  );
};

export default RecordExceptionDisplay;
