import { useEffect, useState } from "react";
import "./ApproveRejectPhoneRequest.css";

//API calls 
import PatchForeignRequestRejectedBySupervisor           from "../../Hooks/funcForeignRequestRejectedBySupervisor";
import PatchForeignRequestRejectedByZoomPO           from "../../Hooks/funcForeignRequestRejectedByZoomPO";
import PostAssignForeignPhoneNumber           from "../../Hooks/funcAssignForeignPhoneNumber";
import PatchRequestingZoomPOApprovalForeignDID           from "../../Hooks/funcRequestingZoomPOApprovalForeignDID";

import LoadingScreen_V2         from "../../Components/LoadingScreen_V2";
import PopUp from "../../Components/Popup";
import PopupWithInput from "../../Components/PopupWithInput";

const ApproveRejectPhoneRequestButton = ({ 
        request,
        webApiToken, 
        callBackApproveError,
        callBackRejectError,
        callbackApproveSuccess,
        callbackRejectSuccess,
        isDual,
    }) => {


    const [requestLoading, setRequestLoading] = useState(false);

    const [approveRequestSuccess, setApproveRequestSuccess] = useState(false);
    const [approveRequestFail, setApproveRequestFail] = useState(false);
    const [approveRequestError, setApproveRequestError] = useState(null);   
    const [approveRequestConfirmation, setApproveRequestConfirmation] = useState(false);   

    const [rejectRequestSuccess, setRejectRequestSuccess] = useState(false);
    const [rejectRequestFail, setRejectRequestFail] = useState(false);
    const [rejectRequestError, setRejectRequestError] = useState(null);
    const [rejectRequestConfirmation, setRejectRequestConfirmation] = useState(false);   
    const [rejectInputJustification, setRejectInputJustification] = useState("");   

    // Phone Number Request
    const handleApproveRequest = async () => {
        if (webApiToken) {
            setRequestLoading(true);
        try {
            let formData = new FormData();
            formData.append("id", request.Id);

            const [approveRequestResponse] = await Promise.all([
                isDual ?
                (
                    request.Status === "Pending supervisor approval"
                    ? PatchRequestingZoomPOApprovalForeignDID(webApiToken, formData)
                    : PostAssignForeignPhoneNumber(webApiToken, formData)
                )
                :
                (
                    request.Status === "Pending supervisor approval"
                    ? PatchRequestingZoomPOApprovalForeignDID(webApiToken, formData)
                    : PostAssignForeignPhoneNumber(webApiToken, formData) 
                )
            ]);


            setApproveRequestSuccess(true);

        } catch (err) {
            setApproveRequestError(err.message);
            setApproveRequestFail(true);

        } finally {
            setRequestLoading(false);

        }
        }
    };

    const handleRejectRequest = async () => {
        if (webApiToken) {
            setRequestLoading(true);
        try {
            let formData = new FormData();
            formData.append("id", request.Id);
            formData.append("rejectJustification", rejectInputJustification);

            const [rejectRequestResponse] = await Promise.all([
                request.Status === "Pending supervisor approval" 
                    ? PatchForeignRequestRejectedBySupervisor(webApiToken, formData)
                    : PatchForeignRequestRejectedByZoomPO(webApiToken, formData)
            ]);

            setRejectRequestSuccess(true);


        } catch (err) {
            setRejectRequestError(err.message);
            setRejectRequestFail(true);

        } finally {
            setRequestLoading(false);

        }
        }
    };

    useEffect(()=> {
        if(approveRequestError){
            callBackApproveError({
                request: request,
                error: approveRequestError
            })
        }
    },[approveRequestError])

    useEffect(()=> {
        if(rejectRequestError){
            callBackRejectError({
                request: request,
                error: rejectRequestError
            })
        }
    },[rejectRequestError])

    useEffect(()=> {
        if(approveRequestSuccess){
            callbackApproveSuccess({
                request: request,
                approveRequestSuccess: approveRequestSuccess
            })
        }
    },[approveRequestSuccess])

    useEffect(()=> {
        if(rejectRequestSuccess){
            callbackRejectSuccess({
                request: request,
                rejectRequestSuccess: rejectRequestSuccess,
                rejectInputJustification: rejectInputJustification
            })
        }
    },[rejectRequestSuccess])

    
    

    return (
        <div>    
            {requestLoading && <LoadingScreen_V2 loadingMessage=""></LoadingScreen_V2>}
            <div className="table-buttons-container">
                <button
                    className="buttons approve-btn"
                    type="button"
                    onClick={() => setApproveRequestConfirmation(true)}
                >
                    Approve
                </button>
                <button
                    className="buttons reject-btn"
                    type="button"
                    onClick={() => setRejectRequestConfirmation(true)}
                >
                    Reject
                </button>
            </div>

            {approveRequestConfirmation && (
                <PopUp
                    title="Approve Request"
                    message={`Are you sure you want a to approve this request?`}
                    showPopUp={approveRequestConfirmation}
                    setShowPopUp={setApproveRequestConfirmation}
                    cancelCallback={() => setApproveRequestConfirmation(false)}
                    submitForm={() => handleApproveRequest()}
                    >
                </PopUp>
            )}

            {rejectRequestConfirmation && (
                <PopupWithInput
                    title="Reject Request"
                    message={`Are you sure you want a to reject this request?
                    Please provide a justification that will be sent to user.`}
                    showPopUp={rejectRequestConfirmation}
                    setShowPopUp={setRejectRequestConfirmation}
                    cancelCallback={() => setRejectRequestConfirmation(false)}
                    submitForm={() => handleRejectRequest()}
                    value={rejectInputJustification}
                    setValue={setRejectInputJustification}
                    placeholder={""}
                    >
                </PopupWithInput>
            )}

        </div>
            
    )
}

export default ApproveRejectPhoneRequestButton;