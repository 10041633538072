import { useEffect, useState } from "react"
import { AutoCompleteWrapper } from "./AutoComplete.css.js"
import ToolTip from "../../Components/ToolTip/ToolTip"

const AutoComplete = (
  {
    inputValue, 
    setInputValue, 
    specificSize, 
    placeholder, 
    note, 
    listOfObjects, 
    optionKeyValues, 
    returnSelectedObj, 
    label, 
    toolTipMessage, 
    GetState,
    touchAll,
    disableValidation,
    userType,
    ...rest
  }) =>{

  const [foundList, setFoundList] = useState([]);
  const [showFoundList, setShowFoundList] = useState(false);
  const [validInput, setValidInput] = useState(true);
  const [inputErrorMessage, setInputErrorMessage] = useState("Validation error");
  const [firstTry, setFirstTry] = useState(true);

  const [selectedObj, setSelectedObj] = useState({});

  // BLOCK THAT MONITORS THE TOUCH ALL VARIABLE OF PARENT COMPONENT, IF IT IS trigger
  // SET firstTry TO FALSE IF IT IS submitted SET firstTry TO TRUE
  if (touchAll === "trigger" && firstTry) {
    setFirstTry(false);
  } else if (touchAll === "submitted" && !firstTry) {
    setFirstTry(true);
  }

  useEffect(() => {
    (function searchInTheList(){
      let list = listOfObjects;
      let inputLowerCase = inputValue.toLowerCase();

      if(userType){
        setFoundList(list);           
      }
      else{
        let foundListResult = (
          inputLowerCase.length > 0
          ? (list.filter(item => (`${item[optionKeyValues[0]]} ${optionKeyValues.length > 1 && item[optionKeyValues[1]]}`).toLowerCase().includes(inputLowerCase)))
          :(list)
        )
        setFoundList(foundListResult);           
      }
    })();

  }, [inputValue, listOfObjects])

  function handleOnChange(e){
    let value = e.target.value;
    setInputValue(value);
    returnSelectedObj({});
  }
  function handleOnClickListItem(item){
    setInputValue(item[optionKeyValues[0]]);
    returnSelectedObj(item);
    setSelectedObj(item)
    setShowFoundList(false);
  }

  function handleOnClickInput(){
    setShowFoundList(true);
  }

  function handleOnBluerInput(){
    if(inputValue !== selectedObj[optionKeyValues[0]]){
      setInputValue("");
    }
    setShowFoundList(false);
    setFirstTry(false); 
  }

  useEffect(() => {
    InputValidator();
    if (GetState) {
      GetState({ [label]: validInput });
    }
  }, [inputValue, validInput]);


  // FUNCTION THAT VALIDATED THE USER INPUT
  function InputValidator() {
    if(!disableValidation){
      // 1. TESTING IF THE INPUT IS EMPTY
      if (inputValue.trim() === "") {
        setInputErrorMessage("Field cannot be empty.");
        setValidInput(false);
      }else{
        // IF ALL TESTS ABOVE SUCCEED TWEAK THE VALID STATE TO TRUE
        setValidInput(true);
      }
    }
    else{
      setValidInput(true);
    }
  }





  return(
    <AutoCompleteWrapper specificSize={specificSize}>
      <div className="Input-input-component" {...rest} onBlur={()=> handleOnBluerInput()} >
        <div className="AutoComplete-label">
          {label}
          <br />
        </div>
        <div className="AutoComplete-input-component">
          <input 
            onClick={() => { setInputValue(() => ""); handleOnClickInput()}} 
            placeholder={placeholder} 
            type="text" 
            value={inputValue} 
            onChange={handleOnChange}
            className={`${!validInput && !firstTry && "AutoComplete-invalid"}`}
          >
          </input>
          {
            toolTipMessage 
            && (
            <div className="AutoComplete-tool-tip">
              <ToolTip id={label} tipMessage={toolTipMessage} svgSize="20"/>        
            </div>
            )
          }  
          {!validInput && !firstTry && <p className="AutoComplete-warning-message">{inputErrorMessage}</p>}

        </div>
        { foundList.length > 0 && showFoundList && (
            <ul className="AutoComplete-box-list">    
              {foundList.map((item,index) => (<li onMouseDown={() => handleOnClickListItem(item)} key={index}>{`${item[optionKeyValues[0]]} ${optionKeyValues.length > 1 ? ("(" + item[optionKeyValues[1]] + ")") : ("")}`}</li>))}
            </ul> 
          )
        }
        <div 
          className={`AutoComplete-note ${!validInput && !firstTry && "AutoComplete-warning-message"}`}> {note} </div>
      </div>
    </AutoCompleteWrapper>
  )
}

export default AutoComplete