import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route } from "react-router";
import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
} from "@azure/msal-react";
import { Switch } from "react-router-dom";

import { Home } from "./Pages/Home";

// Phone request
import PhoneRequestForm from "./Pages/PhoneRequest/PhoneRequestForm";
import PhoneRequestForeignForm from "./Pages/PhoneRequestForeignForm/PhoneRequestForeignForm";
import PhoneRequestForeignLogs from "./Pages/PhoneRequestForeignForm/PhoneRequestForeignLogs";
import PhoneRequestLogs from "./Pages/PhoneRequest/PhoneRequestLogs";
import PhoneNumberMetrics from "./Pages/PhoneRequest/PhoneNumberMetrics";
// Phone release request
import PhoneReleaseForm from "./Pages/PhoneRelease/PhoneReleaseForm";
// Phone move request
import PhoneMoveForm from "./Pages/PhoneMove/PhoneMoveForm";
// Zoom room
import ZoomRoomForm from "./Pages/ZoomRoomForm";
import RecordExceptionForm from "./Pages/RecordingException/RecordingExceptionForm";
import RecordExceptionDisplay from "./Pages/RecordingException/RecordingExceptionDisplay";
// Harvesting
import PhoneHarvest from "./Pages/PhoneHarvest/PhoneHarvest";
import IDMSync from "./Pages/IDMSync/IDMSync";
import NotFound from "./Pages/NotFound/NotFound";
//Phone onboard site
import ZoomOnboardSiteForm from "./Pages/OnboardSiteForm/ZoomOnboardSiteForm"
import Layout from "./Components/Layout";
import LoadingScreen from "./Components/LoadingScreen";
import { RouteGuard } from "./Components/RouteGuard";

import { appRoles } from "./AppConfig";

export const App = (props) => {
  return (
    <Layout {...props}>
      <AuthenticatedTemplate>
        <Switch>

          <Route 
            path="/" 
            component={Home} 
            exact
          />

          <Route 
            path="/phone-request" 
            component={PhoneRequestForm} 
            exact
          />

          <Route 
            path="/phone-request-foreign" 
            component={PhoneRequestForeignForm} 
            exact
          />

          <Route 
            path="/phone-request-foreign-logs" 
            component={PhoneRequestForeignLogs} 
            exact
          />

          <Route
            path="/phone-request-logs"
            component={PhoneRequestLogs}
            exact
          />
          <RouteGuard
            path="/phone-number-metrics"
            role={appRoles.AdminPhoneMetrics}
            Component={PhoneNumberMetrics}
            exact
          />

          <Route
            path="/phone-release-form"
            component={PhoneReleaseForm}
            exact
          />

          <Route 
            path="/phone-move-form" 
            component={PhoneMoveForm} 
            exact
          />

          <Route 
            path="/zoom-recording" 
            component={RecordExceptionForm} 
          />

          <Route
            path="/zoom-recording-data"
            component={RecordExceptionDisplay}
          />

          <RouteGuard
            path="/zoom-room-form"
            role={appRoles.AdminRoomsCode}
            Component={ZoomRoomForm}
            exact
          />

          <Route
            path="/phone-harvest/:requestId"
            component={PhoneHarvest}
            exact
          />

          <Route
            path="/phone-idm-sync/:requestId"
            component={IDMSync}
            exact
          />
n
          <RouteGuard
            path="/zoom-onboard-site-form"
            role={appRoles.AdminPhoneOnboard}
            Component={ZoomOnboardSiteForm}
            exact
          />

          <Route 
            path="*" 
            component={NotFound}
          />

        </Switch>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoadingScreen />
      </UnauthenticatedTemplate>
    </Layout>
  );
};
