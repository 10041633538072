
import styled from "styled-components"

/* COMPONENT STYLES */
export const PhoneMoveCardWrapper = styled.div`

  .PhoneMoveCard-row-section{
    display: flex;
    align-items: center;
  }

  .PhoneMoveCard-btn-row-section{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  #PhoneMoveCard-btn-save-changes{
    margin: 0px;
  }

  .PhoneMoveCard-btn {
    background-color: var(--primary-blue);
    color: var(--white);
    border: var(--primary-blue) solid 2px;
    padding: 10px 50px;
    margin: 15px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    box-shadow: 2px 2px 5px #0000008c;
    border-radius: 4px;
    width: 163px;
    height: 51px;
  }

  .PhoneMoveCard-btn:hover {
    color: var(--primary-blue);
    background-color: var(--white);
    box-shadow: 2px 2px 5px var(--primary-blue);
  }

  
  .PhoneMoveCard-em-c-alert {
    display: -ms-flexbox;
    display: flex;
    padding: 1em;
    margin-bottom: 1rem;
    background: #d3f2ff;
    border: 1px solid #0192d0;
  }

  .PhoneMoveCard-zoom-account-info{
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    margin: 8px 5px;
    h5 {
    margin: 10px;
   }
   height: 680px;
   width: 470px;
  }

  .PhoneMoveCard-btn-medium {
    background-color: var(--primary-blue);
    color: var(--white);
    border: var(--primary-blue) solid 2px;
    font-size: 16px;
    width: 130px;
    height: 51px;
    padding: 10px 10px;
    text-align: center;
    margin: 8px 15px;
    font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-style: normal;
  }

  #PhoneMoveCard-btn-save-changes{
    margin: 0px;
  }

  #PhoneMoveCard-btn-cancel-changes{
    margin: 0px;
  }

  #PhoneMoveCard-btn-new-site{
    margin: 0px;
  }


  .PhoneMoveCard-btn-large {
    background-color: var(--primary-blue);
    color: var(--white);
    border: var(--primary-blue) solid 2px;
    font-size: 16px;
    width: 180px;
    height: 51px;
    padding: 10px 10px;
    text-align: center;
    margin: 8px 15px;
  }


  .PhoneMoveCard-em-c-alert {
    display: -ms-flexbox;
    display: flex;
    padding: 1em;
    margin-bottom: 1rem;
    background: #d3f2ff;
    border: 1px solid #0192d0;
  }


  .PhoneMoveCard-em-c-alert--warning {
    background: #fffecf;
    border-color: #ffd700; 
  }

  .PhoneMoveCard-em-c-alert--error {
    background: #fdded8;
    border-color: #b12a0b;
    color: #b12a0b; 
  }

  .PhoneMoveCard-em-c-alert__actions {
    margin-left: 1rem; 
  }

  .PhoneMoveCard-em-c-alert__body {
    -ms-flex: 1;
    flex: 1;
    font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 0.875rem;
  }

  .PhoneMoveCard-em-c-alert--success {
    background: #d4f3e6;
    border-color: #00b04f;
    color: #00b04f; 
  }

  .PhoneMoveCard-em-c-text-btn {
    color: var(--primary-blue);
    background: none;
    border: 0;
    padding: 0;
    text-decoration: underline;
    font-size: 0.875rem;
    transition: all 0.15s ease;
    font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    font-style: normal; 
  }

  .loading-sites-container {
    position: relative;
  }

  .loading-sites{
    position: absolute;
    top: 20%;
    left: 40%;
    transform: translate(-50%, -50%);
  
   }

   .loading{
      opacity: 0.2;
   }

   #notfound-location{
    margin: 8px 15px;

   }


`