import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

import CircleLoader from "react-spinners/CircleLoader";
import PhoneRequestForeignLogsWrapper from "./PhoneRequestForeignLogs.css";
import TableUnity from "../../Components/TableUnity/TableUnity";

import { useFetch } from "../../Hooks/useFetch";

//api tokens
import useWebAPIToken from "../../Hooks/useWebAPIToken.js";

/////////////////////////MAIN COMPONENT/////////////////////////////////
const PhoneRequestForeignLogs = () => {
  const { data: foreignDIDLogs       = null, error: foreignDIDLogsError       = null, isFetching: foreignDIDLogsLoading        = null, setData: setForeignDIDLogs } = useFetch("/PhoneRequest/GetForeignPhoneRequestLogs");
  const { data: dualDIDLogs       = null, error: dualDIDLogsError       = null, isFetching: dualDIDLogsLoading        = null, setData: setDualDIDLogs } = useFetch("/PhoneRequest/GetDualDIDRequestLogs");

  const { instance } = useMsal();
  const [ currUser, setCurrUser ] = useState(null)

  const [approveRequestError, setApproveRequestError] = useState(null);
  const [approveRequestWithError, setApproveRequestWithError] = useState(null);

  const [showApproveRequestSuccess, setShowApproveRequestSuccess] = useState(false);
  const [requestApproveWithSucess, setRequestApproveWithSucess] = useState(null);

  const [showRejectRequestSuccess, setShowRejectRequestSuccess] = useState(false);
  const [requestRejectWithSucess, setRequestRejectWithSucess] = useState(null);

  const [rejectRequestError, setRejectRequestError] = useState(null);
  const [rejectRequestWithError, setRejectRequestWithError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [rejectInputJustification, setRejectInputJustification] = useState(null);

  

  // 1. TOKEN FOR CUSTOM WEB API
  const webApiToken = useWebAPIToken();



  const onLoad = async () => {
    setCurrUser(instance.getAllAccounts()[0]);
  }

  

  const handleCallBackApproveError = (e) => {
    setApproveRequestError(e.error);
    setApproveRequestWithError(e.request)
  };

  const handleCallBackRejectError = (e) => {
    setRejectRequestError(e.error);
    setRejectRequestWithError(e.request)
  };

  const handleCallBackApproveSucess = (e) => {
    setShowApproveRequestSuccess(e.approveRequestSuccess);
    setRequestApproveWithSucess(e.request);
  };

  const handleCallBackRejectSucess = (e) => {
    setShowRejectRequestSuccess(e.rejectRequestSuccess);
    setRequestRejectWithSucess(e.request);
    setRejectInputJustification(e.rejectInputJustification);    
  };

  

  useEffect(() => {
    setIsLoading(
      foreignDIDLogsLoading 
      || dualDIDLogsLoading )
  }, [dualDIDLogsLoading, foreignDIDLogsLoading]); 

  useEffect(() => {
    onLoad();
  }, [instance]);

  const refreshPage = () =>{
    window.location.reload();
  }
  

  return (
    <PhoneRequestForeignLogsWrapper>
      <h1>Submitted Zoom Phone Foreign Requests</h1>
      <div className="App-horizontal-line" />
      <br />
      <p>
        This page shows all of the Zoom Phone requests made through the Zoom Phone 
        Foreign Request Form. This page is open to all users, but you can only view requests
        where you are the <b>Requested by</b> or <b>Requested for</b> user.
      </p>
      <br />

      {approveRequestError && (
        <div className="em-c-alert em-c-alert--error">
            <div className="em-c-alert__body">
            There is an error to approve/reject request for the user "{approveRequestWithError["Requested For"]}" (request id: {approveRequestWithError.Id}). 
            Please try again. If not resolved, please
            &nbsp;
            <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
                goto/GenericSR
            </a>{" "}
            to submit a ticket and assign to Zoom Support group or contact
            the Help Desk.<br></br>
            </div>
            <div className="App-em-c-alert__actions">
              <button
              className="App-em-c-text-btn"
              onClick={() => refreshPage()}
              >
              Close
              </button>
          </div>
        </div>
      )}

      {/* Fail */}
      {rejectRequestError && (
        <div className="em-c-alert em-c-alert--error">
            <div className="em-c-alert__body">
            There is an error to approve/reject request for the user "{rejectRequestWithError["Requested For"]}" (request id: {rejectRequestWithError.Id}).
            Please try again. If not resolved, please
            &nbsp;
            <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
                goto/GenericSR
            </a>{" "}
            to submit a ticket and assign to Zoom Support group or contact
            the Help Desk.<br></br>
            </div>
            <div className="App-em-c-alert__actions">
              <button
              className="App-em-c-text-btn"
              onClick={() => refreshPage()}
              >
              Close
              </button>
          </div>
        </div>
      )}

      {/* Fail get site locations */}
      {(foreignDIDLogsError || dualDIDLogsError) && (
        <div className="em-c-alert em-c-alert--error">
            <div className="em-c-alert__body">
            There is an error. Please try again. If not resolved, please
            &nbsp;
            <a target="_blank" href={"https://goto/GenericSR"} rel="noreferrer">
                goto/GenericSR
            </a>{" "}
            to submit a ticket and assign to Zoom Support group or contact
            the Help Desk.<br></br>
            </div>
            <div className="App-em-c-alert__actions">
              <button
              className="App-em-c-text-btn"
              onClick={() => refreshPage()}
              >
              Close
              </button>
          </div>
        </div>
      )}

      {showApproveRequestSuccess && !isLoading && (
        <div className="App-em-c-alert App-em-c-alert--success">
          <div className="App-em-c-alert__body">
              The request for the user "{requestApproveWithSucess["Requested For"]}" (request id: {requestApproveWithSucess.Id})  has been approved
              {requestApproveWithSucess.Status === "Pending supervisor approval" ? " and now is waiting for Zoom PO approval.": `.`}   
          </div>
          <div className="App-em-c-alert__actions">
              <button
              className="App-em-c-text-btn"
              onClick={() => refreshPage()}
              >
              Close
              </button>
          </div>
        </div>
      )}

      {showRejectRequestSuccess && !isLoading && (
        <div className="App-em-c-alert App-em-c-alert--success">
          <div className="App-em-c-alert__body">
              The request for the user "{requestRejectWithSucess["Requested For"]}" (request id: {requestRejectWithSucess.Id}) has been rejected.
              Reason: {rejectInputJustification}.  
          </div>
          <div className="App-em-c-alert__actions">
              <button
              className="App-em-c-text-btn"
              onClick={() => refreshPage()}
              >
              Close
              </button>
          </div>
        </div>
      )}

      

      {isLoading && (
        <div className="flex-column loader">
          <CircleLoader size="100px" color="#007bff" />
            <p>Loading...</p>
        </div>
      )}

      {/* Fail */}

      {!isLoading && !showApproveRequestSuccess && !showRejectRequestSuccess &&
        <div className="upper-text">
        <p className="titles">Foreign DID Phone Requests</p>
        </div>
      }

      {!isLoading && foreignDIDLogs?.length > 0 && webApiToken && !showApproveRequestSuccess && !showRejectRequestSuccess &&
        (
          <div>
            <TableUnity
              currentUser={currUser} 
              webApiToken={webApiToken}
              data={foreignDIDLogs.map(log => {
                return {
                  "Id": log.id,
                  "Status": log.status,
                  "Create Date": log.createDate,
                  "Requested By": log.requestedBy,
                  "Requested For": log.requestedFor,
                  "Approved by Supervisor": log.approvedBySupervisor,
                  "Approved by Zoom PO": log.approvedByZoomPO,
                  "Business Justification": log.businessJustification,
                  "Foreign Site": log.foreignPhoneSiteName,
                  "Foreign Number": log.foreignPhoneNumber,
                  "Reject Justification": log.rejectJustification,                
                }
              })}
              callBackApproveError={handleCallBackApproveError} 
              callBackRejectError={handleCallBackRejectError} 
              
              callbackApproveSuccess={handleCallBackApproveSucess} 
              callbackRejectSuccess={handleCallBackRejectSucess}
            />
          </div>
        )
      }
      {
        foreignDIDLogs?.length === 0 &&
        (
          <div className="flex-column loader">
            <p>No data returned for this user!</p>
          </div>
        )
      }
      
      {!isLoading && !showApproveRequestSuccess && !showRejectRequestSuccess &&
        <div className="upper-text">
          <p className="titles">DUAL DID Phone Requests</p>
        </div>
      }
      {!isLoading && dualDIDLogs?.length > 0 && webApiToken && !showApproveRequestSuccess && !showRejectRequestSuccess &&
        (          
        <div>
          <TableUnity
            isDual
            key={1}
            currentUser={currUser} 
            webApiToken={webApiToken}
            data={dualDIDLogs.map(log => {
              return {
                "Id": log.id,
                "Status": log.status,
                "Create Date": log.createDate,
                "Requested By": log.requestedBy,
                "Requested For": log.requestedFor,
                "Approved by Supervisor": log.approvedBySupervisor,
                "Approved by Zoom PO": log.approvedByZoomPO,
                "Business Justification": log.businessJustification,
                "Local Site": log.localPhoneSite,
                "Local Number": log.localPhoneNumber,
                "Foreign Site": log.foreignPhoneSiteName,
                "Foreign Number": log.foreignPhoneNumber,
                "Reject Justification": log.rejectJustification,                
              }
            })}
            callBackApproveError={handleCallBackApproveError} 
            callBackRejectError={handleCallBackRejectError} 
            
            callbackApproveSuccess={handleCallBackApproveSucess} 
            callbackRejectSuccess={handleCallBackRejectSucess}
          />
        </div>

        )
      }
      {
        dualDIDLogs?.length === 0 &&
        (
          <div className="flex-column loader">
            <p>No data returned for this user!</p>
          </div>
        )
      }
    </PhoneRequestForeignLogsWrapper>
  );
};

export default PhoneRequestForeignLogs;
