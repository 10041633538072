import { Fragment } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../AppConfig";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { MSLogin } from "../Hooks/funcLogin";

export const UserLogin = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const userData = accounts;

  if (inProgress === "none" && !isAuthenticated) {
    MSLogin(instance);
  }

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  const handleLogout = () => {
    sessionStorage.clear();
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  return (
    <Fragment>
      {isAuthenticated ? (
        <UncontrolledDropdown>
          <DropdownToggle nav caret>
            {userData.length > 0 ? userData[0].name : ""}
          </DropdownToggle>
          <DropdownMenu right>
            <h5 className="dropdown-item-text mb-0">
              {userData.length > 0 ? userData[0].name : ""}
            </h5>
            <p className="dropdown-item-text text-muted mb-0">
              {userData.length > 0 ? userData[0].username : ""}
            </p>
            <DropdownItem divider />
            <DropdownItem onClick={handleLogout}>Sign Out</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <NavItem>
          <NavLink onClick={handleLogin}>Sign in</NavLink>
        </NavItem>
      )}
    </Fragment>
  );
};
