import styled from "styled-components";

/* COMPONENT STYLES */
export const PhoneLocationCardWrapper = styled.div`
  ${
    "" /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
  }

  .PhoneUserForeignDIDCard-btn {
    background-color: var(--primary-blue);
    color: var(--white);
    border: var(--primary-blue) solid 2px;
    padding: 10px 50px;
    margin: 15px;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.5s;
    box-shadow: 2px 2px 5px #0000008c;
    border-radius: 4px;
    width: 163px;
    height: 51px;
  }

  .btn-large-change-site {
    width: auto;
    height: 51px;
    padding: 10px 10px;
    text-align: center;
    margin: 8px 15px;
  }

  .em-c-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    position: relative;
    display: flex;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    max-width: 25rem;
    border: 1px solid #cccccd;
    margin: 8px 15px;
  }

  .em-c-card__body {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    max-width: 25rem;
  }

  .em-c-media-block__body {
    display: -ms-flexbox;
    display: flex;

    -ms-flex-direction: column;
    flex-direction: column;

    -ms-flex-pack: center;
    justify-content: center;

    -ms-flex: 1;
    flex: 1;

    align-items: center;
  }

  .em-c-media-block__headline {
    font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial",
      sans-serif;
    font-weight: 550;
    font-style: normal;
    margin-bottom: 0.2em;
  }

  .em-c-media-block__desc {
    font-size: 0.875rem;
  }

  .em-c-card__footer {
    background-color: inherit !important;
    background: #f5f5f5;
    margin: 0.8rem -0.5rem -0.5rem -0.5rem;
    padding: 0.5rem 0rem 0.5rem 0rem;
    border-top: 1px solid #cccccd;
    display: flex;
    justify-content: start;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .PhoneRequestForm-change-site-btn {
    position: absolute;
    background-color: inherit !important;
    background: #f5f5f5;
    display: flex;
    justify-content: start;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    color: #3190d9;
    justify-content: center;
    align-items: center;
  }

  .PhoneUserForeignDIDCard-remove-user-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    background-color: inherit;
    width: 2rem;
    height: 2rem;
    color: #3190d9;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .PhoneUserForeignDIDCard-remove-user-btn:hover {
    color: red;
    border: black solid 1px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card_headline {
    margin: 0rem 0rem 0rem 2rem;
    padding: 0rem 0rem 0.5rem 0rem;
    font-weight: 550;
  }

  .card_phone {
    margin: 0rem 0rem 0rem 2rem;
    padding: 0rem 0rem 0.5rem 0rem;
  }

  .em-c-link-list {
  }

  .em-c-link-list--bulleted {
    list-style: disc;
    margin-left: 1.1rem;
  }

  .em-c-link-list--small {
    font-size: 0.9rem;
  }
  .em-c-solid-card .em-c-link-list {
    text-decoration: underline;
  }

  .em-c-link-list--has-icons .em-c-link-list__link {
    display: -ms-flexbox;
    display: flex;
  }

  .em-c-link-list__item {
    margin-bottom: 0.5rem;
  }

  .em-c-link-list__link {
    color: #0c69b0;
    transition: color 0.15s ease;
  }

  .PhoneUser-circle-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .PhoneUserForeignDIDCard-PhoneNumbers {
    margin: 0.1rem;
    color: #00b04f;
  }

  .PhoneUserForeignDIDCard-em-c-alert {
    display: -ms-flexbox;
    display: flex;
    padding: 1em;
    margin-bottom: 1rem;
    background: #d3f2ff;
    border: 1px solid #0192d0;
  }

  .PhoneUserForeignDIDCard-em-c-alert--success {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    background: #d4f3e6;
    border-color: #00b04f;
    color: #00b04f;
  }

  .PhoneUserForeignDIDCard-em-c-alert__body {
    -ms-flex: 1;
    flex: 1;
    font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial",
      sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 0.875rem;
  }

  .PhoneUserForeignDIDCard-em-c-alert__actions {
    margin-left: 1rem;
  }

  .PhoneUserForeignDIDCard-em-c-text-btn {
    color: var(--primary-blue);
    background: none;
    border: 0;
    padding: 0;
    text-decoration: underline;
    font-size: 0.875rem;
    transition: all 0.15s ease;
    font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial",
      sans-serif;
    font-weight: 600;
    font-style: normal;
  }

  .PhoneUserForeignDIDCard-em-c-alert--error {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    background: #fdded8;
    border-color: #b12a0b;
    color: #b12a0b;
  }

  .PhoneUserForeignDIDCard-em-c-alert--warning {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    background: #fffecf;
    border-color: #ffd700;
  }
`;
