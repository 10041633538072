import CircleLoader from "react-spinners/CircleLoader";
import styled from "styled-components";

const Div = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 22px;
  color: #007bff;
  width: 100%;

  .flex-row {
    display: flex;
  }

  p {
    font-size: 25px;
  }

  .p1,
  .p2,
  .p3,
  .p4 {
    display: inline;
    opacity: 1;
  }

  .p1 {
    animation: odelay 0.5s linear;
  }
  .p2 {
    animation: odelay 1.1s linear;
  }
  .p3 {
    animation: odelay 1.7s linear;
  }
  .p4 {
    animation: odelay 2.3s linear;
  }

  @keyframes odelay {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const LoadingScreen = (props) => {
  return (
    <Div className="flex-column">
      <br /> <br />
      <CircleLoader size="250px" color="#007bff" />
      <br />
      <div className="flex-row">
        <p>{props.loading_text}</p>
        <p className="p1">.</p>
        <p className="p2">.</p>
        <p className="p3">.</p>
        <p className="p4">.</p>
      </div>
    </Div>
  );
};

export default LoadingScreen;
